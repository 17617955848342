/***********************************************************************
* DESCRIPTION :

*  Ability to rollback PO from cancelled and shipped status to last state by internal admin/support

* NOTES :

* AUTHOR :    Hitesh Ahire        CREATE DATE :    09 FEB 2023

* CHANGES :

* REF NO         VERSION   DATE         WHO     DETAIL

* PMB-6555       2.6.0     09/02/2023    HA      Ability to rollback PO from cancelled and shipped status to last state by internal admin/support

************************************************************************/
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { STATUS, PO_ACTION, PO_MODFLAG, PO_STATUS, SharedUtilService, TranslateService, DialogData, PRICE_PER, WEIGHT_UOM, UNIT_CONVERSION, LOAD_STATUS } from 'common-lib';
import { OrderRollbackService } from '../../service/order-rollback.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';



@Component({
  selector: 'order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {


  PO_MODFLAGS = PO_MODFLAG;
  POSTATUS = PO_STATUS;
  isMultipleSoNumber : boolean = false;

  // invoiceStatus: STATUS;
  orderDetailForm: OrderDetailForm = {
    id: null,
    poNumber: null,
    buyerCompanyId: null,
    buyerCompanyName: null,
    sellerCompanyId: null,
    sellerCompanyName: null,
    supplierCode: null,
    deliverToLocId: null,
    deliverToLocName: null,
    shipFromLocId: null,
    deliveryDate: null,
    shipDate: null,
    flag: null,
    freightTerm: null,
    totalQty: null,
    uom: null,
    totalAmount: null,
    totalLines: null,
    currency: null,
    status: "",
    subStatus: null,
    subStatusIcon: null,
    subStatusTooltip: null,
    orderNo: null,
    saleOrderNumber: null,
    action: null,
    previousShipDate: null,
    previousDeliveryDate: null,
    previousRouting: null,
    previousShipFrom: null,
    previousDeliveryTo: null,
    shipDateModFlag: null,
    deliveryDateModFlag: null,
    routingModFlag: null,
    loadNumber: null,
    loadId: null,
    carrierName: null,
    isLoadAvailable: null,
    shipFromModFlag: null,
    deliveryToModFlag: null,
    sellerModification: null,
    netWeightInKgs: null,
    netWeightInLbs: null,
    grossWeightInKgs: null,
    grossWeightInLbs: null,
    pallets: null,
    volume: null,
    buyerUser: null,
    shipFrom: null,
    supplierCompany: null,
    buyerCompany: null,
    deliveredTo: null,
    buyerTotHeaderChg: null,
    buyerTotLineChg: null,
    buyerTotVariance: null,
    totLoadFreight: 0,
    createdTs: null,
    versioning: null,
    invoiceNumber: null,
    paymentTermId: null,
    paymentTermName: null,
    invoiceId: null,
    invoiceStatus: null,
    poLines: [],
    offline: null,
    approved: null,
    approvedBy: null,
    approvedTs: null,
    approvedByName: null,
    poAcknowledged: null,
    buyerOwner: null, //used for PO-Owner
    buyerOwnerUser: null,
    crossDockLocName: null,
    shipFromLocName : null

  }

  printData: PrintData = {
    id: null,
    isRecievedQtyHidden: false,
    orderDetailForm: this.orderDetailForm,
    appModuleAccess: null,
    orderCurrentStatus: null,
    totalQuantity: null,
    totalColumnFinalPrice: null,
    poCharges: null,
    totLoadFreight: 0,
    varianceTotal: null,
    finalCostTotal: null,
    grandTotal: 0,
    originalHtmlContents: null,
    isExpandedView: null,
    isHideCancelLines: null,
    draftPO: null,
    totalColumnPrice: null,
  };

  statusShipped: any = 'Shipped';
  statusShippedModified: any = 'Shipped Modified';
  statusCancelled = this.getStatus(this.POSTATUS.Cancelled.toString());
  orderCurrentStatus: string;
  buyerTotLineChg: any;
  buyerTotHeaderChg: any;
  poCharges: any;
  grandTotal: number;
  statusName: string = "";
  numberOfLinePages: any;
  sortColumn: any;
  lineData: any[];
  totalQuantity: number;
  totalReceivedQty: number = 0;
  totalColumnPrice: number;
  isLoadingSubmit: boolean;
  totalColumnFinalPrice: any;
  cloneForm: any;
  // private poId = '880';
  balanceItemTooltip: string;
  inspectList: any;
  PO_ACTION = PO_ACTION;
  varianceTotal: number;
  columnTotal: number;
  totLoadFreight: number = 0;
  otherPrice: number = 0;
  finalCostTotal: any;
  isMultipleShipFrom : boolean = false;
  constructor(private dialogRef: MatDialogRef<OrderDetailsComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, private sharedUtilService: SharedUtilService, private router: Router, private orderRollbackService: OrderRollbackService, private translate: TranslateService) {

  }

  ngOnInit() {
    // this.orderDetailForm = this.printData.orderDetailForm

    this.setHeaderData();
    this.callLineData();
    
  }

  setHeaderData() {
    this.orderRollbackService.getHeaderDetails(this.data.poId).subscribe(r => {
      if (r) {
        Object.keys(r).forEach(name => {
          if (this.printData[name] == null || this.printData[name]) {
            this.printData[name] = r[name];
          }
          if (this.printData.orderDetailForm[name] == null || this.printData.orderDetailForm[name]) {
            this.printData.orderDetailForm[name] = r[name];
          }
        });

        this.setLineSummary().then(r => {})    
        this.orderCurrentStatus = this.getStatus(r.status);
        // this.statusName = this.getStatusName(r.status);
        this.buyerTotHeaderChg = r.buyerTotHeaderChg;
        this.buyerTotLineChg = r.buyerTotLineChg;
        this.poCharges = this.buyerTotHeaderChg + this.buyerTotLineChg;
        this.grandTotal = r.totalAmount + this.poCharges;

        this.printData.poCharges = this.poCharges;

        // this.headerForm.getFormData(this.orderDetailForm, this.isFormControlDisable, this.headerData, this.appModuleAccess);
        //this.headerForm.maxLineShipDate = this.orderDetailForm.get('deliveryDate').value;
        if (r["loadNumber"] && r["loadNumber"] != 'NA')
          this.printData.orderDetailForm.isLoadAvailable = true;
        if (r['loadStatusCode'] != PO_ACTION.DRAFT && this.sharedUtilService.getStatus(r['status']) <= this.POSTATUS.Received.toString()) {
          this.getLogisticsDetails().then(res => {
            this.grandTotal = this.grandTotal + this.totLoadFreight;
          });
        } else {
          r["loadNumber"] = 'NA'
          r["carrierName"] = 'NA'
          this.totLoadFreight = 0;
          r["totLoadFreight"] = this.totLoadFreight
        }
      }
    }, err => {
      // this.notify.show(err, 'error');
    });
  }
  getLogisticsDetails() {
    return new Promise<void>((resolve, reject) => {
      let routing = '';
      routing = (this.printData.orderDetailForm.routingModFlag === 0) ? this.printData.orderDetailForm.freightTerm : this.printData.orderDetailForm.previousRouting;
      if (routing == LOAD_STATUS.DELIVERED) {
        if (this.printData.orderDetailForm.loadNumber == null || this.printData.orderDetailForm.loadNumber == '') {
          this.printData.orderDetailForm.loadNumber  = 'NA';
        }
        if (this.printData.orderDetailForm.carrierName == null || this.printData.orderDetailForm.carrierName == '') {
          this.printData.orderDetailForm.carrierName  = 'NA';
        }
      } else {
        this.printData.orderDetailForm.loadNumber  = 'NA';
        this.printData.orderDetailForm.carrierName  = 'NA';
        this.orderRollbackService.getLoadData(this.printData.id).subscribe(resp => {
          if (resp.status === 204) {
            this.totLoadFreight = 0;
            this.printData.orderDetailForm.loadNumber  = 'NA';
            this.printData.orderDetailForm.carrierName  = 'NA';
            resolve();
          } else {
            this.printData.orderDetailForm.loadNumber  = resp.body.loadNumber;
            this.printData.orderDetailForm.carrierName  = resp.body.carrierName;
            this.printData.orderDetailForm.loadId = resp.body.id;
          
            this.totLoadFreight = this.printData.orderDetailForm.totLoadFreight ? this.printData.orderDetailForm.totLoadFreight : 0;
            resolve();
          }
          resolve();
        }, err => {
          // this.notify.show(err, 'error');
        });
      }
    });
  }
  getStatusName(status) {
    return this.sharedUtilService.getStatusName(status)
  }

  getStatus(status) {
    return this.sharedUtilService.getStatus(status)
  }
  callLineData() {
    let p = new Promise((resolve, reject) => {
      let c = {
        "poId": this.data.poId,
        "pageNumber": this.numberOfLinePages,
        "pageSize": 20,
        "sortColumn": this.sortColumn
      }
      this.orderRollbackService.getLineDetailsPaging(c).subscribe(r => {
        if (r) {
          this.setLineData(r);
          resolve(true)
        } else {
          const control = <FormArray>this.orderDetailForm.poLines;
          if (this.numberOfLinePages == 0) {
            this.lineData = [];
            control.controls = [];
          }
          this.grandTotal = 0;
          this.totalQuantity = 0;
          this.totalReceivedQty = 0;
          this.totalColumnPrice = 0;
          this.totalColumnFinalPrice = 0;
          this.isLoadingSubmit = false;
        }
      }, err => {
        // this.notify.show(err, 'error');
      });
    });
    p.then(() => {
      this.orderDetailForm.poLines.forEach(line => {
        if (this.sharedUtilService.getStatus(this.printData.orderDetailForm.status) < this.POSTATUS.Cancelled.toString()
          && this.sharedUtilService.getStatus(this.printData.orderDetailForm.status) > this.POSTATUS.Shipped.toString()) {
          this.totalReceivedQty += line.receivedQty * 1;
        }
        if (line.status != STATUS.CANCELLED) {
          this.printData.totalColumnFinalPrice += line.totalAmount;
        }
      });
      this.getInvoiceInfo();
      this.updateGrandTotalPrice(this.printData.orderDetailForm.poLines);
    });
    return p;
  }

  setLineData(r) {
    // const control = this.printData.orderDetailForm.poLines;
    let soNumbers = new Set<string>();
    if (this.numberOfLinePages == 0) {
      this.lineData = [];
      this.printData.orderDetailForm.poLines = [];
    }
    // let len = this.printData.orderDetailForm.poLines.length;
    this.numberOfLinePages++;
    let currentLineIndex = 0;
    r.content.forEach((element, index) => {
      soNumbers.add(element.soNumber);
      this.printData.orderDetailForm.poLines.push(this.setPoline(element, index));
      currentLineIndex++;
      if (r.totalPages == this.numberOfLinePages && r.content.length == currentLineIndex) {
        this.isLoadingSubmit = false;
      }
    });
    if (this.numberOfLinePages < r.totalPages) {
      this.callLineData();
    }
    
    if(soNumbers.size > 1){
      this.isMultipleSoNumber = true;
    }
  }

  setLineSummary() {
    let p = new Promise((resolve, reject) => {
      this.orderRollbackService.getLineSummary(this.orderDetailForm.id).subscribe(r => {
        if (r) {
          this.printData.orderDetailForm.grossWeightInLbs = r.grossWeightInLbs;
          this.printData.orderDetailForm.grossWeightInKgs = r.grossWeightInKgs;
          this.printData.orderDetailForm.netWeightInLbs = r.netWeightInLbs;
          this.printData.orderDetailForm.netWeightInKgs = r.netWeightInKgs;
          this.printData.orderDetailForm.volume = r.volume;
          this.printData.orderDetailForm.pallets = r.pallets;
          this.printData.orderDetailForm.buyerTotVariance = r.buyerTotVariance;
          // this.orderDetailForm.get('grossWeightInLbs').setValue(r.grossWeightInLbs);
          // this.orderDetailForm.get('grossWeightInKgs').setValue(r.grossWeightInKgs);
          // this.orderDetailForm.get('netWeightInLbs').setValue(r.netWeightInLbs);
          // this.orderDetailForm.get('netWeightInKgs').setValue(r.netWeightInKgs);
          // this.orderDetailForm.get('volume').setValue(r.volume);
          // this.orderDetailForm.get('pallets').setValue(r.pallets);
          // this.orderDetailForm.get('buyerTotVariance').setValue(r.buyerTotVariance);
          this.varianceTotal = r.buyerTotVariance;
        }
      })
    })
    return p;
  }

  public updateGrandTotalPrice(poLines: any) {
    this.grandTotal = 0;
    this.totalQuantity = 0;
    this.totalReceivedQty = 0;
    this.totalColumnPrice = 0;
    this.totalColumnFinalPrice = 0;
    this.varianceTotal = 0;
    // before recount total price need to be reset.
    this.columnTotal = 0;
    var format = '/[!@#$%^&*()_+\-=\[\]{};\':"\\|,<>\/?]+/';
    for (let i in poLines) {
      //Quantity column wise...!
      //Changed
      if (this.sharedUtilService.getStatus(this.orderDetailForm.status) < this.POSTATUS.Cancelled.toString() && this.sharedUtilService.getStatus(this.orderDetailForm.status) > this.POSTATUS.Shipped.toString()) {
        this.totalReceivedQty += poLines[i].receivedQty * 1
      }
      //code added for netweight Calculation in grandtotal
      let weight = 1.0;
      if (poLines[i].priceByUom != null && (poLines[i].priceByUom.toUpperCase() == PRICE_PER.WEIGHT.toUpperCase())) {
        if (poLines[i].poLineProduct.weightUom.toUpperCase() == WEIGHT_UOM.KG.toUpperCase()) {
          weight = UNIT_CONVERSION.KG_TO_LB * poLines[i].poLineProduct.netWeight;
        } else {
          weight = poLines[i].poLineProduct.netWeight;
        }
      }
      let grandTotalPrice = 0;

      let total = (poLines[i].qty * poLines[i].unitPrice * weight);

      if (this.sharedUtilService.getStatusName(poLines[i].status) != this.sharedUtilService.getStatus(this.POSTATUS.Cancelled.toString())) {


        this.totalQuantity += poLines[i].qty * 1;
        grandTotalPrice = (poLines[i].qty * poLines[i].unitPrice * weight);  //Grand total cal...!
        this.totalColumnFinalPrice += total; //column wise total of cost...!
      }
      // update total price for all poLine
      this.grandTotal += grandTotalPrice; //Price column wise...!
      this.totalColumnPrice += poLines[i].unitPrice * 1;//Final Price column wise...!
      this.columnTotal += grandTotalPrice;
      // this.checkSKU_QTY();
      this.finalCostTotal = this.finalCostTotal + i;
    }

    this.grandTotal += this.poCharges;
    if (this.sharedUtilService.getStatus(this.orderDetailForm.status) <= this.POSTATUS.Received.toString()) {
      this.grandTotal += this.totLoadFreight;
    }
    //Changed
    if (this.sharedUtilService.getStatusName(this.orderDetailForm.status) == this.sharedUtilService.getStatus(this.POSTATUS.Cancelled.toString())) {
      this.grandTotal = 0;
      this.totalQuantity = 0;
      this.totalReceivedQty = 0;
      this.totalColumnPrice = 0;
      this.totalColumnFinalPrice = 0;

    }
  }
  private setPoline(val?, index?) {
    let lotNumberValues = (val.poLineProduct.lotNumber != null && val.poLineProduct.lotNumber != '') ? val.poLineProduct.lotNumber.split(',') : [];
    lotNumberValues = lotNumberValues.filter(function (el) { return el; });
    let lotNumberMultiple = lotNumberValues.length > 1 ? true : false;
    val.poLineProduct.lotNumber = !lotNumberMultiple ? lotNumberValues[0] : lotNumberValues.map(x => x).join(", ");

    let growingRegionValues = (val.poLineProduct.growingRegion != null && val.poLineProduct.growingRegion != '') ? val.poLineProduct.growingRegion.split(',') : [];
    growingRegionValues = growingRegionValues.filter(function (el) { return el; });
    let growingRegionMultiple = growingRegionValues.length > 1 ? true : false;
    val.poLineProduct.growingRegion = !growingRegionMultiple ? growingRegionValues[0] : growingRegionValues.map(x => x).join(", ");

    let ssccValues = (val.poLineProduct.sscc != null && val.poLineProduct.sscc != '') ? val.poLineProduct.sscc.split(',') : [];
    ssccValues = ssccValues.filter(function (el) { return el; });
    let ssccMultiple = ssccValues.length > 1 ? true : false;
    val.poLineProduct.sscc = !ssccMultiple ? ssccValues[0] : ssccValues.map(x => x).join(", ");

    const numberPatern = '^[0-9,]+$';
    const decimalPatern = '^[0-9]+(\.[0-9]{1,4})?$';
    const specialcharPatern = '^[A-Za-z0-9\u4e00-\u9eff- ]+$';
    this.balanceItemTooltip = this.getStatus(val.status) >= this.POSTATUS.Received.toString() ? "LABEL.BALANCE_ITEM" : "DETAIL_PAGE.LABEL.BALANCEITEM_TOOLTIP"
    let frm = {
      poId: this.data.poId,
      id: val.id,
      buyerCompanyId: val.buyerCompanyId,
      lineNumber: val.lineNumber ? val.lineNumber : Number(index) + 1,
      sequence: val.sequence,
      productId: val.productId,

      sku: val.productCode,
      productDesc: val.productDesc,
      tpProductCode: val.tpProductCode,
      tpProductDesc: val.tpProductDesc,
      originalQty: val.originalQty,
      totalLineCharges: val.totalLineCharge,
      savedQty: val.qty,
      qty: val.qty,
      mappingText: val.tpProductMapped === 2 ? '(Multiple)' : val.tpProductMapped === 1 ? '(Mapped)' : '(Not Mapped)',
      //changed
      receivedQty: (this.sharedUtilService.getStatus(this.POSTATUS.Shipped.toString()) == this.sharedUtilService.getStatusName(this.printData.orderDetailForm.status) && val.receivedQty == 0) ? val.qty : val.receivedQty,
      previousQty: val.previousQty,
      quantityModFlag: val.quantityModFlag,
      purchaseByUom: val.purchaseByUom,
      sellByUom: val.sellByUom,
      unitPrice: val.unitPrice,
      errorPriceZero: false,
      savedUnitPrice: val.unitPrice,
      originalPrice: val.originalPrice,
      previousUnitPrice: val.previousUnitPrice,
      priceModFlag: val.priceModFlag,
      finalUnitCost: val.finalUnitCost,
      priceByUom: val.priceByUom,
      totalAmount: val.totalAmount,
      tFormated: val.tFormated,
      currency: val.currency,
      status: val.status,
      statusCheck: this.getStatusName(val.status),
      statusNumber: this.getStatus(val.status),
      subStatus: val.subStatus,
      soNumber: val.soNumber,
      shipDate: val.shipDate,
      shipFromLocId: val.shipFromLocId == null ? '' : val.shipFromLocId,
      action: '',
      gtin: val.gtin ? val.gtin : '',
      addLine: false,
      addItemModFlag: val.addItemModFlag,
      buyerLC: val.buyerLC,
      landedCost: val.landedCost,
      buyerLCPerUnit: val.buyerLCPerUnit,
      varience: val.buyerVariance,
      cancelItemModFlag: val.cancelItemModFlag,
      tpProductMapped: val.tpProductMapped,
      substituted: val.substituted,
      poLineProduct: (val.poLineProduct != null) ? {
        coo: val.poLineProduct.coo,
        cooValue: '',
        cooMultiple: false,
        lotNumberMultiple: lotNumberMultiple,
        // isMultileHarvestDate: this.getHarvestFlag(val.poLineProduct.id),
        grossWeight: val.poLineProduct.grossWeight,
        harvestDate: val.poLineProduct.harvestDate,
        id: val.poLineProduct.id,
        lotNumber: val.poLineProduct.lotNumber,
        netWeight: val.poLineProduct.netWeight,
        poId: val.poLineProduct.poId,
        productId: val.poLineProduct.productId,
        tpProductId: val.poLineProduct.tpProductId,
        weightUom: val.poLineProduct.weightUom,
        priceByUom: val.priceByUom,
        gtin: '',
        hi: val.poLineProduct.hi,
        ti: val.poLineProduct.ti,
        length: val.poLineProduct.length,
        width: val.poLineProduct.width,
        height: val.poLineProduct.height,
        dimensionUom: val.poLineProduct.dimensionUom,
        // AppIsUse: SUBSCRIBED_APPS.BUYER,
        bioengineered: val.poLineProduct.bioengineered ? val.poLineProduct.bioengineered : "NO",
        growingRegion: val.poLineProduct.growingRegion,
        growingRegionMultiple: growingRegionMultiple,
        sscc: val.poLineProduct.sscc,
        ssccMultiple: ssccMultiple,
        expiryDate: val.poLineProduct.expiryDate
      } : { productId: val.productId },
      supplierTotAmt: val.supplierTotAmt,
      buyerFreight: val.buyerFreight,
      supplierFreight: val.supplierFreight,
      buyerTotAmt: val.buyerTotAmt,
      freight: val.freight,
      loadFreight: val.loadFreight,
      buyerVariance: val.buyerVariance,
      upc: val.upc,
      previousShipDate: val.previousShipDate,
      previousShipFrom: val.previousShipFrom,
      shipDateModFlag: val.shipDateModFlag,
      shipFromModFlag: val.shipFromModFlag,
      balanceItem: val.balanceItem,
      commentCount: val.unReadComment == false ? 0 : val.unReadComment,
      hasPreviousCommentsAvailable: val.commentCount > 0 ? true : false,
      attachmentIndicator: val.unReadAttachmentIndicator,
      isLineAttachmentAvailable: val.attachmentIndicatorCount > 0 ? true : false,
      chargeIndicator: val.unReadPoLineCharge,
      isLineChargesAvailable: val.poLineChargeCount > 0 ? true : false,
      warehouseAssociation: false,
      demandBalanceIndicator: val.demandBalanceIndicator ? val.demandBalanceIndicator : null,
      demandLineNo: val.demandLineNo,
      shipFromLocName : val.shipFromLocName,
      isShow: true,
      costChargeTotAmt: val.costChargeTotAmt,
      costChargePerUnit: val.costChargePerUnit,
      actualUnitPrice: val.actualUnitPrice,
      inspectionStatusCode: '',
      inspectionStatusName: '',
      inspectAvgScore: 0,
      balanceItemTooltip: this.getStatus(val.status) >= this.POSTATUS.Received.toString() ? "LABEL.BALANCE_ITEM" : "DETAIL_PAGE.LABEL.BALANCEITEM_TOOLTIP",
    };
    return frm;
  }
  getInvoiceInfo() {
    if (this.printData.orderDetailForm.paymentTermName == '' || this.printData.orderDetailForm.paymentTermName == null) {
      this.orderRollbackService.getInvoiceDetails(this.data.poId).subscribe(res => {
        if (res) {
          // if (res.status === this.PO_ACTION.INVOICED || res.status == STATUS.INVOICE_REJECTED || res.status == STATUS.APPROVED) {
            this.orderDetailForm.invoiceNumber = res.invoiceNumber;
            this.orderDetailForm.invoiceId = res.id;
            this.orderDetailForm.invoiceStatus = res.status;
            if (res.paymentTermId !== undefined) {
              this.orderRollbackService.paymenttermDetailsById(res.paymentTermId).subscribe(resp => {
                if (resp.discount != null && resp.discount != 0 && resp.discountDays != null) {
                  resp.name = resp.name + " " + this.translate.instant('DETAIL_PAGE.LABEL.DISCOUNT') + " " +
                    resp.discount + "%" + " " + "(" + resp.discountDays + " " + this.translate.instant('DETAIL_PAGE.LABEL.DAYS') + ")";
                }
                this.printData.orderDetailForm.paymentTermName = resp.name;

              }, err => {
                // this.notify.show(err, 'error');
              })
            }
          }
        // }
      })
    }
  }
  public close() {
    this.dialogRef.close();
  }
}

export interface PrintData {
  id: any;
  isRecievedQtyHidden: boolean;
  orderDetailForm: OrderDetailForm;
  appModuleAccess: any;
  orderCurrentStatus: any
  totalQuantity: any
  totalColumnFinalPrice: any
  poCharges: any
  totLoadFreight: number
  varianceTotal: any
  finalCostTotal: any
  grandTotal: any;
  originalHtmlContents: any;
  isExpandedView: boolean;
  isHideCancelLines: boolean;
  draftPO: boolean;
  totalColumnPrice: any;
}
export interface OrderDetailForm {
  id: any;
  poNumber: any;
  buyerCompanyId: any;
  buyerCompanyName: any;
  sellerCompanyId: any;
  sellerCompanyName: any;
  supplierCode: any;
  deliverToLocId: any;
  deliverToLocName: any;
  shipFromLocId: any;
  deliveryDate: any;
  shipDate: any;
  flag: any;
  freightTerm: any;
  totalQty: any;
  uom: any;
  totalAmount: any;
  totalLines: any;
  currency: any;
  status: any;
  subStatus: any;
  subStatusIcon: any;
  subStatusTooltip: any;
  orderNo: any;
  saleOrderNumber: any;
  action: any;
  previousShipDate: any;
  previousDeliveryDate: any;
  previousRouting: any;
  previousShipFrom: any;
  previousDeliveryTo: any;
  shipDateModFlag: any;
  deliveryDateModFlag: any;
  routingModFlag: any;
  loadNumber: any;
  loadId: any;
  carrierName: any;
  isLoadAvailable: any;
  shipFromModFlag: any;
  deliveryToModFlag: any;
  sellerModification: any;
  netWeightInKgs: any;
  netWeightInLbs: any;
  grossWeightInKgs: any;
  grossWeightInLbs: any;
  pallets: any;
  volume: any;
  buyerUser: any;
  shipFrom: any;
  supplierCompany: any;
  buyerCompany: any;
  deliveredTo: any;
  buyerTotHeaderChg: any;
  buyerTotLineChg: any;
  buyerTotVariance: any;
  totLoadFreight: number;
  createdTs: any;
  versioning: any;
  invoiceNumber: any;
  paymentTermId: any;
  paymentTermName: any;
  invoiceId: any;
  invoiceStatus: any;
  poLines: any;
  offline: any;
  approved: any;
  approvedBy: any;
  approvedTs: any;
  approvedByName: any;
  poAcknowledged: any;
  buyerOwner: any; //used for PO-Owner
  buyerOwnerUser: any;
  crossDockLocName: any;
  shipFromLocName: any;
}


