<div class="order-rollback-overlay">
    <div class="heading">{{'LABEL.ORD_ROLLBACK' | translate }}</div>
    <div class="message">
        <p *ngIf="!data['isOkButton']">{{'INTEGRATION_MONITORING.LABEL.ORDER' | translate }}
            <strong>{{data.poNumber}}</strong>{{'LABEL.WILL_LAST_ACCEPTED_STATUS' | translate }}
            <br>{{'LABEL.DO_YOU_WANT_TO_CONTINUE' | translate }}
        </p>
        <p *ngIf="data['isOkButton']">{{'INTEGRATION_MONITORING.LABEL.ORDER' | translate }}
            <strong>{{data.poNumber}}</strong>{{'LABEL.REVERT_ORDER_INTO_LAST_ACCEPTED_STATUS' | translate }}</p>

        <mat-form-field appearance="outline" *ngIf="!data['isOkButton']">
            <!-- <mat-label>{{ 'PRICING.LABEL.COMMENT' | translate }}</mat-label> -->
            <mat-label>{{'LABEL.PLEASE_ENTER_POSNO_CUS' | translate }}</mat-label>
            <textarea matInput rows="5" cols="1000" [formControl]="posNoComment"></textarea>
        </mat-form-field>
        <span *ngIf="!data['isOkButton']" class="invoice-warning">{{'LABEL.ANY_INVOICE_DRAFT_STATUS' | translate }}</span>
    </div>

    <div class="button">
        <button *ngIf="!data['isOkButton']" mat-button (click)="close()">{{'BUTTON.NO' | translate }}</button>
        <button *ngIf="!data['isOkButton']" [disabled]="posNoComment.value.length <= 0"
            [ngClass]=" posNoComment.value.length <= 0 ? 'yes-button-disabled' : 'yes-button'" mat-stroked-button
            (click)="close('yes')">{{'BUTTON.YES' | translate }}</button>
        <button *ngIf="data['isOkButton']" class="yes-button" mat-button (click)="close('no')">{{'BUTTON.OK' | translate
            }}</button>
    </div>
</div>