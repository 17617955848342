<div class="main-content">
  <div fxLayout="row" class="custome-readonly-block">
    <div fxFlex="100%">
      <div fxLayout="row">
        <div fxLayout="column" fxFlex="50%;">
          <div fxLayout="row" class="mr-4">
            <mat-card fxFlex="100%">
              <div fxLayout="row">
                <div fxLayout="column" fxFlex="50%">
                  <div fxLayout="row" class="mb-2">
                    {{'COMPANY.LABEL.BUSINESS_PROFILE_TITLE'| translate}} &nbsp;
                    <button *ngIf="!isFromInternalAdmin" (click)="profilePreview()"
                      class="icon2-info fs-10 preview-button"
                      matTooltip="{{ 'COMPANY.LABEL.PROFILE_PREVIEW' | translate }}" style="cursor: pointer;"></button>
                  </div>
                </div>
              </div>
              <mat-card-content>
                <div [formGroup]="accountSettingForm">
                  <div fxLayout="row">
                    <mat-form-field fxFlex="100%">
                      <input matInput formControlName="name"
                        (blur)="checkCompanyNameIsExist(accountSettingForm.controls['name'].value)"
                        placeholder="{{ 'COMPANY.LABEL.NAME' | translate }}*">
                      <mat-error
                        *ngIf="accountSettingForm.controls['name'].touched && accountSettingForm.controls['name'].hasError('required')">
                        {{ 'COMPANY.MESSAGE.NAME_ERROR' | translate }}
                      </mat-error>
                      <mat-error
                        *ngIf="accountSettingForm.controls['name'].touched && accountSettingForm.controls['name'].hasError('pattern')">
                        {{ 'COMPANY.MESSAGE.NAME_VALID_ERROR' | translate }}
                      </mat-error>
                      <mat-error
                        *ngIf="accountSettingForm.controls['name'].touched && accountSettingForm.controls['name'].hasError('isNameExist')">
                        {{ 'COMPANY.MESSAGE.NAME_EXIST_ERROR' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxLayout="row">
                    <mat-form-field fxFlex="100%">
                      <input matInput formControlName="url" placeholder="{{ 'COMPANY.LABEL.WEBSITE' | translate }}">
                      <mat-error
                        *ngIf="accountSettingForm.controls['url'].touched && accountSettingForm.controls['url'].hasError('pattern')">
                        {{ 'BUSINESS_UNIT.MESSAGE.URL_VALID_ERROR' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxLayout="row">
                    <div fxLayout="column" fxFlex="50%;">
                      <div fxLayout="row">
                        <mat-form-field fxFlex="95%">
                          <mat-select formControlName="bizType"
                            placeholder="{{ 'COMPANY.LABEL.BUSINESS_TYPE' | translate }}*" (selectionChange)="getServiceProvider()" disableOptionCentering>
                            <mat-option *ngFor="let type of orgBussinessTypes  | sort:'name'" [value]="type.code">
                              {{type.name}}</mat-option>
                          </mat-select>
                          <mat-error
                            *ngIf="accountSettingForm.controls['bizType'].touched && accountSettingForm.controls['bizType'].hasError('required')">
                            {{ 'COMPANY.MESSAGE.TYPE_ERROR' | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div fxLayout="column" fxFlex="50%;">
                      <div fxLayout="row">
                        <mat-form-field fxFlex="100%">
                          <input matInput formControlName="statusCode"
                            placeholder="{{ 'COMPANY.LABEL.STATUS' | translate }}" [readonly]="true">
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div fxLayout="row">
                    <div fxLayout="column" fxFlex="50%;">
                      <div fxLayout="row">
                        <mat-form-field fxFlex="95%"
                          *ngIf="accountSettingForm.controls['bizType'].value == 'SERVICE_PROVIDER'">
                          <mat-select formControlName="serviceTypeCode" placeholder="Service Provider Type"
                            disableOptionCentering>
                            <mat-option *ngFor="let type of serviceTypeList" [value]="type.code">{{type.name}}
                            </mat-option>
                          </mat-select>
                          <mat-error
                            *ngIf="accountSettingForm.controls.serviceTypeCode.touched && accountSettingForm.controls.serviceTypeCode.hasError('required')">
                            {{ 'COMPANY.MESSAGE.SERVICE_TYPE_ERROR' | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div fxLayout="row">
                    <div fxLayout="column" fxFlex="50%;">
                      <div fxLayout="row">
                        <mat-form-field fxFlex="95%;">
                          <input matInput value="{{accountSettingForm.controls['createdTs'].value |pipes:'date'}}"
                            [readonly]="true" [disabled]="isFromInternalAdmin"
                            placeholder="{{ 'COMPANY.LABEL.REGISTRATION_DATE' | translate }}">
                        </mat-form-field>
                      </div>
                    </div>
                    <div fxLayout="column" fxFlex="50%;">
                      <div fxLayout="row">
                        <mat-form-field fxFlex="100%;">
                          <input matInput value="{{accountSettingForm.controls['activeDate'].value |pipes:'date'}}"
                            [readonly]="true" [disabled]="isFromInternalAdmin"
                            placeholder="{{ 'COMPANY.LABEL.ACTIVATION_DATE' | translate }}">
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div fxLayout="row">
                    <mat-form-field fxFlex="100%">
                      <textarea maxlength="2110" matInput formControlName="description"
                        placeholder="{{ 'COMPANY.LABEL.COMPANY_OVERVIEW' | translate }}">
                          </textarea>
                    </mat-form-field>
                  </div>
                  <div fxLayout="row">
                    <mat-form-field fxFlex="47.5%">
                      <input matInput formControlName="accountNumber" value="{{accountSettingForm.controls['accountNumber'].value"
                        placeholder="{{'COMPANY.LABEL.ACCOUNT_NUMBER'|translate}}"
                        [readonly]="true" [disabled]="isFromInternalAdmin">
                    </mat-form-field>
                  </div>
                  <div fxLayout="row">
                    <div fxLayout="column" fxFlex="50%;">
                      <div fxLayout="row">
                        <mat-form-field fxFlex="95%">
                          <input matInput formControlName="email"
                            (blur)="checkBusinessEmailIsExist(accountSettingForm.controls['email'].value)"
                            placeholder="{{ 'COMPANY.LABEL.BUSINNES_EMAIL' | translate }}">
                          <mat-error
                            *ngIf="accountSettingForm.controls['email'].touched && accountSettingForm.controls['email'].hasError('pattern')">
                            {{ 'COMPANY.MESSAGE.EMAIL_VALID_ERROR' | translate }}
                          </mat-error>
                          <mat-error
                            *ngIf="accountSettingForm.controls['email'].touched && accountSettingForm.controls['email'].hasError('isBizEmailExist')">
                            {{ 'COMPANY.MESSAGE.EMAIL_EXIST_ERROR' | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div fxLayout="column" fxFlex="50%;">
                      <div fxLayout="row">
                        <mat-form-field fxFlex="100%">
                          <input matInput formControlName="duns"
                            (blur)="checkDunsNumberExist(accountSettingForm.controls['duns'].value)"
                            placeholder="{{ 'COMPANY.LABEL.DUNS' | translate }}" minlength="6" maxlength="13">
                          <mat-error
                            *ngIf="accountSettingForm.controls['duns'].touched && accountSettingForm.controls['duns'].hasError('pattern')">
                            {{ 'COMPANY.MESSAGE.DUNS_VALID_ERROR' | translate }}
                          </mat-error>
                          <mat-error *ngIf="accountSettingForm.controls['duns'].touched && accountSettingForm.controls['duns'].hasError('minlength')">
                            {{ 'BUSINESS_UNIT.MESSAGE.MIN_6_DIGIT_DUNS_REQUIRED' | translate }}
                          </mat-error>
                          <mat-error
                            *ngIf="accountSettingForm.controls['duns'].touched && accountSettingForm.controls['duns'].hasError('isDunsExist')">
                            {{ 'COMPANY.MESSAGE.DUNS_EXIST_ERROR' | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div [formGroup]="defaultAddress">
                    <div fxLayout="row">
                      <mat-form-field fxFlex="100%">
                        <input matInput formControlName="address" maxlength="155"
                          placeholder="{{ 'COMPANY.LABEL.ADDRESS_LINE_1' | translate }}*">
                        <mat-error
                          *ngIf="defaultAddress.controls['address'].touched && defaultAddress.controls['address'].hasError('required')">
                          {{ 'BUSINESS_UNIT.MESSAGE.ADDRESS1_ERROR' | translate }}
                        </mat-error>
                        <mat-error
                          *ngIf="defaultAddress.controls['address'].touched && defaultAddress.controls['address'].hasError('pattern')">
                          {{ 'BUSINESS_UNIT.MESSAGE.ADDRESS1_VALID_ERROR' | translate }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div fxLayout="row">
                      <mat-form-field fxFlex="100%">
                        <input matInput formControlName="address2" maxlength="155"
                          placeholder="{{ 'COMPANY.LABEL.ADDRESS_LINE_2' | translate }}">
                        <mat-error
                          *ngIf="defaultAddress.controls['address2'].touched && defaultAddress.controls['address2'].hasError('pattern')">
                          {{ 'BUSINESS_UNIT.MESSAGE.ADDRESS1_VALID_ERROR' | translate }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div fxLayout="row">
                      <div fxLayout="column" fxFlex="50%;">
                        <div fxLayout="row">
                          <mat-form-field fxFlex="95%">
                            <input matInput formControlName="city" placeholder="{{ 'COMPANY.LABEL.CITY' | translate }}">
                            <mat-error
                              *ngIf="defaultAddress.controls['city'].touched && defaultAddress.controls['city'].hasError('pattern')">
                              {{ 'BUSINESS_UNIT.MESSAGE.CITY_VALID_ERROR' | translate }}
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                      <div fxLayout="column" fxFlex="50%;">
                        <div fxLayout="row">
                          <mat-form-field fxFlex="100%">
                            <input matInput formControlName="zipCode" maxlength="10"
                              placeholder="{{ 'COMPANY.LABEL.ZIP' | translate }}">
                            <mat-error
                              *ngIf="defaultAddress.controls['zipCode'].touched && defaultAddress.controls['zipCode'].hasError('pattern')">
                              {{ 'BUSINESS_UNIT.MESSAGE.ZIP_VALID_ERROR' | translate }}
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div fxLayout="row">
                      <div fxLayout="column" fxFlex="50%;">
                        <div fxLayout="row">
                          <mat-form-field fxFlex="95%">
                            <mat-select formControlName="countryCode"
                              placeholder="{{ 'COMPANY.LABEL.COUNTRY' | translate }}" disableOptionCentering>
                              <mat-option *ngFor="let country of countryList" [value]="country.countryCode">
                                {{country.countryName}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                      <div fxLayout="column" fxFlex="50%;">
                        <div fxLayout="row">
                          <mat-form-field fxFlex="100%">
                            <mat-select formControlName="stateCode"
                              placeholder="{{ 'COMPANY.LABEL.STATE_REGION' | translate }}" disableOptionCentering>
                              <mat-option *ngFor="let state of statesList" [value]="state.stateKey.stateCode">
                                {{state.name}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div fxLayout="column" fxFlex="50%;">

          <div fxLayout="row">
            <mat-card fxFlex="100%" class="mb-4">
              <div fxLayout="row" class="mb-2">
                {{ 'COMPANY.LABEL.PREFERENCES' | translate }}
              </div>
              <mat-card-content>
                <div [formGroup]="clientPreferences">
                  <div fxLayout="row">
                    <div fxLayout="column" fxFlex="50%;">
                      <div fxLayout="row">
                        <mat-form-field fxFlex="95%;">
                          <mat-select formControlName="Language"
                            (selectionChange)="onClientPreferenceChange($event, 'Language')"
                            placeholder="{{ 'COMPANY.LABEL.LANGUAGE' | translate }}*" disableOptionCentering>
                            <mat-option *ngFor="let lang of languageList" [value]="lang.locale">{{lang.name}}
                            </mat-option>
                          </mat-select>
                          <mat-error
                            *ngIf="clientPreferences.controls['Language'].touched && clientPreferences.controls['Language'].hasError('required')">
                            {{ 'COMPANY.MESSAGE.LANGUAGE_ERROR' | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div fxLayout="column" fxFlex="50%;">
                      <div fxLayout="row">
                        <mat-form-field fxFlex="100%;">
                          <mat-select formControlName="Currency"
                            (selectionChange)="onClientPreferenceChange($event, 'Currency')"
                            placeholder="{{ 'COMPANY.LABEL.CURRENCY' | translate }}*" disableOptionCentering>
                            <mat-option *ngFor="let curr of currencyList  | sort:'name'" [value]="curr.code">
                              {{curr.name}}
                            </mat-option>
                          </mat-select>
                          <mat-error
                            *ngIf="clientPreferences.controls['Currency'].touched && clientPreferences.controls['Currency'].hasError('required')">
                            {{ 'COMPANY.MESSAGE.CURRENCY_ERROR' | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div fxLayout="row">
                    <div fxLayout="column" fxFlex="50%;">
                      <div fxLayout="row">
                        <mat-form-field fxFlex="95%">
                          <mat-select formControlName="TimeZone"
                            (selectionChange)="onClientPreferenceChange($event, 'TimeZone')"
                            placeholder="{{ 'COMPANY.LABEL.TIME_ZONE' | translate }}*" disableOptionCentering>
                            <mat-option>
                              <ngx-mat-select-search [placeholderLabel]="searchLabel" [formControl]="timeZoneFiltere">
                              </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let time of timeZoneFilteredOptions | async" [value]="time.code">{{time.value}}
                              {{time.code}}
                            </mat-option>
                          </mat-select>
                          <mat-error
                            *ngIf="clientPreferences.controls['TimeZone'].touched && clientPreferences.controls['TimeZone'].hasError('required')">
                            {{ 'COMPANY.MESSAGE.TIME_ZONE_ERROR' | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div fxLayout="column" fxFlex="50%;">
                      <div fxLayout="row">
                        <mat-form-field fxFlex="100%">
                          <mat-select formControlName="DateFormat"
                            (selectionChange)="onClientPreferenceChange($event, 'DateFormat')"
                            placeholder="{{ 'COMPANY.LABEL.DATE_FORMATE' | translate }}*" disableOptionCentering>
                            <mat-option *ngFor="let date of dateFormateList" [value]="date">{{date}}</mat-option>
                          </mat-select>
                          <mat-error
                            *ngIf="clientPreferences.controls['DateFormat'].touched && clientPreferences.controls['DateFormat'].hasError('required')">
                            {{ 'COMPANY.MESSAGE.DATE_FORMATE_ERROR' | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

          <div fxLayout="row" class="mb-4">
            <mat-card fxFlex="100%">
              <div fxLayout="row" class="mb-2">
                {{ 'COMPANY.LABEL.BUSSINESS_CONTACT' | translate }}
              </div>
              <mat-card-content>
                <div [formGroup]="user">
                  <div fxLayout="row">
                    <div fxLayout="column" fxFlex="50%;">
                      <div fxLayout="row">
                        <mat-form-field fxFlex="95%;">
                          <input matInput formControlName="fname" maxlength="64"
                            placeholder="{{ 'COMPANY.LABEL.FIRST_NAME' | translate }}">
                          <mat-error
                            *ngIf="user.controls['fname'].touched && user.controls['fname'].hasError('required')">
                            {{ 'COMPANY.MESSAGE.FIRST_NAME_ERROR' | translate }}
                          </mat-error>
                          <mat-error
                            *ngIf="user.controls['fname'].touched && user.controls['fname'].hasError('pattern')">
                            {{ 'COMPANY.MESSAGE.FIRST_NAME_VALID_ERROR' | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div fxLayout="column" fxFlex="50%;">
                      <div fxLayout="row">
                        <mat-form-field fxFlex="100%;">
                          <input matInput formControlName="lname" maxlength="64"
                            placeholder="{{ 'COMPANY.LABEL.LAST_NAME' | translate }}">
                          <mat-error
                            *ngIf="user.controls['lname'].touched && user.controls['lname'].hasError('required')">
                            {{ 'COMPANY.MESSAGE.LAST_NAME_ERROR' | translate }}
                          </mat-error>
                          <mat-error
                            *ngIf="user.controls['lname'].touched && user.controls['lname'].hasError('pattern')">
                            {{ 'COMPANY.MESSAGE.LAST_NAME_VALID_ERROR' | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div fxLayout="row">
                    <div fxLayout="column" fxFlex="50%;">
                      <div fxLayout="row">
                        <mat-form-field fxFlex="95%;">
                          <mat-select formControlName="countryCode"
                            placeholder="{{'COMPANY.LABEL.COUNTRY_CODE'|translate}}*" disableOptionCentering>
                            <mat-option *ngFor="let code of countryList" [value]="code.countryCode">{{code.shortName}}
                              : {{code.callingCode}}</mat-option>
                          </mat-select>
                          <mat-error
                            *ngIf="user.controls['countryCode'].touched && user.controls['countryCode'].hasError('required')">
                            {{ 'COMPANY.MESSAGE.COUNTRY_CODE_ERROR' | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div fxLayout="column" fxFlex="50%;">
                      <div fxLayout="row">
                        <mat-form-field fxFlex="100%;">
                          <input matInput formControlName="phone" maxlength="14"
                            placeholder="{{ 'COMPANY.LABEL.PHONE_NUMBER' | translate }}*">
                          <mat-error
                            *ngIf="user.controls['phone'].touched && user.controls['phone'].hasError('required')">
                            {{ 'COMPANY.MESSAGE.PHONE_NUMBER_REQUIRED_ERROR' | translate }}
                          </mat-error>
                          <mat-error
                            *ngIf="user.controls['phone'].touched && user.controls['phone'].hasError('pattern')">
                            {{ 'COMPANY.MESSAGE.PHONE_NUMBER_VALID_ERROR' | translate }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

          <div fxLayout="row" *ngIf="!isFromInternalAdmin">
            <div mat-dialog-actions>
              <button mat-button class="btn btnBasic"
                (click)="cancel()">{{ 'COMPANY.LABEL.CANCEL' | translate }}</button>
              <button mat-button class="btn btnPrimary" *ngIf="!auth?.user?.info?.internalAdmin"
                [disabled]="(accountSettingForm.invalid || user.invalid || clientPreferences.invalid)"
                (click)="updateOrg()">{{'COMPANY.LABEL.UPDATE' |translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <app-push-notification-error></app-push-notification-error>
    <app-push-notification-success></app-push-notification-success> -->
  </div>
</div>