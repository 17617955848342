import { Component, OnInit, ViewChild, ViewContainerRef, ComponentRef, ComponentFactoryResolver, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'data-modal',
  template: `
  <div fxLayout="row">
    <div fxLayout="column">
      <h2 mat-dialog-title>{{data.title | translate}}</h2>
    </div>
    <span style="flex: 1 1 auto;"> </span>
    <div fxLayout="column">
      <a href="javascript:void(0);" style="min-width: unset;" (click)="onNoClick()">
        <mat-icon>close</mat-icon>
      </a>
    </div>
  </div>
  <mat-dialog-content>
    <ng-template #target></ng-template>
  </mat-dialog-content>
    `,
  styleUrls: ['./data-modal.component.scss']
})
export class DataModalComponent implements OnInit {

  @ViewChild('target', { read: ViewContainerRef, static: true }) vcRef: ViewContainerRef;
  componentRef: ComponentRef<any>;

  constructor(public dialogRef: MatDialogRef<DataModalComponent>,
    private resolver: ComponentFactoryResolver,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    const factory = this.resolver.resolveComponentFactory(this.data.component);
    this.componentRef = this.vcRef.createComponent(factory);
    let instance = this.componentRef.instance;
    instance.modalData=this.data.dataDetails;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
}
