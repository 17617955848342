/***********************************************************************
* DESCRIPTION :

*  Ability to rollback PO from cancelled and shipped status to last state by internal admin/support

* NOTES :

* AUTHOR :    Hitesh Ahire        CREATE DATE :    09 FEB 2023

* CHANGES :

* REF NO         VERSION   DATE         WHO     DETAIL

* PMB-6555       2.6.0     09/02/2023    HA      Ability to rollback PO from cancelled and shipped status to last state by internal admin/support

************************************************************************/
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'common-lib';

@Component({
  selector: 'order-rollback-overlay',
  templateUrl: './order-rollback-overlay.component.html',
  styleUrls: ['./order-rollback-overlay.component.scss']
})
export class OrderRollbackOverlayComponent implements OnInit {

  public posNoComment = new FormControl('');
  constructor(private dialogRef: MatDialogRef<OrderRollbackOverlayComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
  }

  public close(action) {
    this.dialogRef.close({ action: action, posNoComment: this.posNoComment.value });
  }
}
