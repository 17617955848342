import { Component, OnInit } from '@angular/core';
import { IMAGES } from '../../../../../environments/config.all';
import { OneAuthService, OneNotifyService } from 'one-auth';
import { environment } from '@procurant/env/environment';
import * as moment_ from 'moment';
import { TranslateService, SharedUtilService } from 'common-lib';
import { AppToolbarService } from '../../services/app-toolbar.service';
import { Router } from '@angular/router';
const moment = moment_["default"];

@Component({
  selector: 'header-toolbar',
  templateUrl: './header-toolbar.component.html',
  styleUrls: ['./header-toolbar.component.scss']
})
export class HeaderToolbarComponent implements OnInit {
  public IMAGES = IMAGES;
  public user;
  public subApps;
  localUrl: any;
  editIcon: any;

  headerFlag: boolean = false;
  dismissed: boolean;
  dismissed_count: number = 0;
  remindersList: any;
  remindersListData: any;
  noDataMessage: any;
  minDelDate = new Date();
  reminderData: any;
  count: number = 0;
  CurrentTime: any;
  displayEditMode: boolean;
  displayItem: boolean;
  interval: any;
  avtarName: any;

  constructor(public auth: OneAuthService, public notify: OneNotifyService, private router: Router, private transalate: TranslateService, private sharedUtilService: SharedUtilService, private appToolbarService: AppToolbarService) { }

  ngOnInit() {
    this.user = this.auth.user;
    this.subApps = this.auth.user.subApps;
    this.setUserImage();
    // (RD)PMB-6554 New header and navigation menu bar for Procurant Commerce
    let name = this.user.info.firstName.split(" ")[0] +' '+this.user.info.lastName.split(" ")[0];
    this.avtarName = name.split(" ").map((n)=>n[0]).join("").toUpperCase();
    //this.getRemindersList();
    //this.startInterval();
  }

  setUserImage() {
    if (this.auth.user.info && this.auth.user.info.fileName != null && this.auth.user.info.objectId != null) {
      this.appToolbarService.getProfileImage(this.auth.user.info.objectId).subscribe(r => {
        if (r) {
          this.auth.thumbnil = null;
          this.auth.thumbnil = "data:image/" + this.auth.user.info.fileName.split('.').pop() + ";base64," + r
        }
      });
    }
  }

  openAdmin() {
    //window.location.href = environment.BASE_API_URL + "common-app/#/app/company/account-setting"
  }

  startInterval() {
    this.interval = setInterval(() => {
      this.getRemindersList();
    }, (300000));
  }

  getRemindersList() {
    this.headerFlag = false;
    this.dismissed = false;
    this.dismissed_count = 0;
    let date = new Date();
    let fromDate = moment(date).format("YYYY-MM-DD");
    fromDate = fromDate + "T00:00:00.000+0530";
    let halfHr = date.setTime(date.getTime() + (60000 * 30))
    let toDate = moment(halfHr).format("YYYY-MM-DDTHH:mm:ss.SSSZZ");

    this.appToolbarService.getRemindersList(fromDate, toDate).subscribe(
      (res) => {
        this.remindersListData = res;
        this.remindersList = [];
        if (this.remindersListData) {
          this.count = this.remindersListData.activeCount;
          if (this.count != 0) {
            this.dismissed = true;
          }
          this.noDataMessage = null;
          this.headerFlag = false;
          this.CurrentTime = moment(new Date()).format('ddd, D MMM YYYY hh:mm A');
          Object.keys(this.remindersListData.userReminders).forEach(name => {
            let date = moment(new Date(this.remindersListData.userReminders[name].reminderTs)).format('ddd, D MMM YYYY hh:mm A');
            let prior = moment(new Date(this.remindersListData.userReminders[name].prior30Min)).format('ddd, D MMM YYYY hh:mm A');
            let past = moment(new Date(this.remindersListData.userReminders[name].past30Min)).format('ddd, D MMM YYYY hh:mm A');
            let flag = 0;
            if ((prior < this.CurrentTime) && (date > this.CurrentTime) && (!this.remindersListData.userReminders[name].dismissed)) {
              flag = 1;
              this.headerFlag = true;
            } else if ((past > this.CurrentTime) && (date < this.CurrentTime) && (!this.remindersListData.userReminders[name].dismissed)) {
              flag = 3;
              this.headerFlag = true;
            } else if ((date == this.CurrentTime) && (!this.remindersListData.userReminders[name].dismissed)) {
              flag = 2;
              this.headerFlag = true;
            }
            let row = {
              "id": this.remindersListData.userReminders[name].id,
              "description": this.remindersListData.userReminders[name].description,
              "date": moment(new Date(this.remindersListData.userReminders[name].reminderTs)).format('ddd, D MMM YYYY hh:mm A'),
              "past30Min": moment(new Date(this.remindersListData.userReminders[name].past30Min)).format('ddd, D MMM YYYY hh:mm A'),
              "prior30Min": moment(new Date(this.remindersListData.userReminders[name].prior30Min)).format('ddd, D MMM YYYY hh:mm A'),
              "flag": flag,
              "dismissed": this.remindersListData.userReminders[name].dismissed
            }
            this.remindersList.push(row);
          });
        } else {
          this.noDataMessage = this.transalate.instant("REMINDER.MESSAGE.NO_REMINDERS_CREATED");
          this.remindersList = [];
          this.remindersListData = [];
          this.count = 0;
          this.dismissed = false;
        }
      })
  }


  onChange(event, id, all?) {
    let data = {}

    if (!event.checked) {
      let date = new Date();
      let fromDate = moment(date).format("YYYY-MM-DD");
      fromDate = fromDate + "T00:00:00.000+0530";
      let halfHr = date.setTime(date.getTime() + (60000 * 30))
      let toDate = moment(halfHr).format("YYYY-MM-DDTHH:mm:ss.SSSZZ");
      if (all) {
        data = {
          "all": true,
          "dismiss": !event.checked,
          "fromDate": fromDate,
          "toDate": toDate
        }
      } else {
        data = {
          "id": id,
          "delete": false,
          "dismiss": !event.checked
        }
      }
    } else {
      if (all) {
        data = {
          "all": true,
          "enable": event.checked
        }
      } else {
        data = {
          "id": id,
          "enable": event.checked
        }
      }
    }

    if (!event.checked) {
      this.appToolbarService.deleteDismissReminder(data).subscribe(
        (res) => { },
        error => {
          if (error.status == 200) {
            if (all) {
              this.dismissed = false;
            }
            this.appToolbarService.flagReminder = true;
            this.notify.show(this.transalate.instant("REMINDER.MESSAGE.DISMISS_REMINDER_SUCCESS"));
            this.getRemindersList();
          }
        });
    } else {
      this.appToolbarService.enableReminder(data).subscribe(
        (res) => {

        },
        error => {
          if (error.status == 200) {
            if (all) {
              this.dismissed = true;
            }
            this.appToolbarService.flagReminder = true;
            this.notify.show(this.transalate.instant("REMINDER.MESSAGE.ENABLE_REMINDER_SUCCESS"));
            this.getRemindersList();
          }
        });
    }

  }

  logout() {
    this.auth.logoutUser().subscribe(res => {
      // sessionStorage.clear();
      sessionStorage.clear();
      sessionStorage.setItem('lang', 'en_US');
      if (res.status === 200) {
        clearInterval(this.interval);
        this.auth.clearSession();
        this.router.navigate(['logout']);
      }

    });
  }

  returnToInternalAdmin() {
    this.appToolbarService.getReturnAdmin().subscribe(s => {
      if (s.status == 200) {
        this.auth.updateUserInfo().subscribe(r => {
          this.auth.user.info.internalAdmin = true;
          this.auth.updateUserPermissions().subscribe(p => {
            this.auth.updateUserSubApps().subscribe(s => {
              this.auth.user.info.internalAdmin = false;
              this.router.navigate(['internal-admin'])
            })
          })
        })

      }
    })
  }

}
