export interface ICompany {
    organization: IOrganization;
    user: IUser;
}

export interface IOrganization {
    id: number;
    duns: string;
    name: string;
    statusCode: string;
    activeDate: number,
    bizType: string;
    url: string;
    email: string;
    defaultAddress: IAddress;
    clientPreferences: IClientPreferences[];
    clientApplications: IClientApplications[];
}

export interface IAddress {
    address: string;
    address2: string;
    city: string;
    state: string;
    countryCode: string;
    zipCode: string;
}

export interface IUser {
    id: number;
    fname: string;
    lname: string;
    phone: number;
    email: string;
    password: string;
}

export interface IClientPreferences {
    application: string;
    organizationId: number;
    attributeCode: string;
    attributeName: string;
    valueCode: string;
    valueValue: string;
    active: boolean;
    byDefault: boolean;
    id: string;
}

export interface IClientApplications {
    applicationCode: string;
    organizationId: number;
    id: number;
}

export class CompanyModel implements ICompany {
    organization: IOrganization;
    user: IUser;
}

export class OrganizationModel implements IOrganization {
    id: number;
    duns: string;
    name: string;
    statusCode: string;
    activeDate: number;
    bizType: string;
    url: string;
    email: string;
    defaultAddress: IAddress;
    clientPreferences: IClientPreferences[];
    clientApplications: IClientApplications[];
}

export class AddressModel implements IAddress {
    address: string;
    address2: string;
    city: string;
    state: string;
    countryCode: string;
    zipCode: string;
}

export class OrgUserModel implements IUser {
    id: number;
    fname: string;
    lname: string;
    phone: number;
    email: string;
    password: string;
}

export class ClientPreferencesModel implements IClientPreferences {
    application: string;
    organizationId: number;
    attributeCode: string;
    attributeName: string;
    valueCode: string;
    valueValue: string;
    active: boolean;
    byDefault: boolean;
    id: string;
}

export class ClientApplicationsModel implements IClientApplications {
    applicationCode: string;
    organizationId: number;
    id: number;
}