import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLS } from '@procurant/env/config.url';
import { OneNotifyService } from 'one-auth';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})

export class CompanyService {

    private headers = new HttpHeaders();

    constructor(public notify: OneNotifyService, private http: HttpClient) {
        this.headers.set('Content-Type', 'application/json');
    }

    getOrgBussinessType(): Observable<any> {
        // var listOfOrgBussinessType = this.$http.baseUrlContext.url('/admin/api/org/public/business-types').get()
        return this.http.get<any>(URLS.GetBusinessTypeAPI).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }));
        // return listOfOrgBussinessType;
    }

    getCountryList(): Observable<any> {
        // var listOfCountry = this.$http.baseUrlContext.url('/admin/api/address/public/countries').get()
        return this.http.get<any>(URLS.GetCountryListAPI).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }));
        // return listOfCountry;
    }

    getStatesListByCountryCode(countryCode): Observable<any> {
        // var listOfStates = this.$http.baseUrlContext.url('/admin/api/address/public/state-list/' + countryCode).get()
        let url = URLS.GetStateByCountryCodeAPI + countryCode;
        return this.http.get<any>(url).pipe(
            map((res) => {
                return res;
            }));
        // return listOfStates;
    }

    isOrgnizationNameExist(orgName): Observable<any> {
        // var isExist = this.$http.baseUrlContext.url('/admin/api/org/public/exists-by-name/' + orgName).get()
        let url = URLS.OrgNameExistsAPI + orgName;
        return this.http.get<any>(url).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }));
        // return isExist;
    }

    checkOrgnizationNameExist(orgName): Observable<any> {
        let url = URLS.OrgNameExistsAtEditAPI + orgName;
        return this.http.get<any>(url).pipe(
            map((res) => {
                return res;
            }));
    }

    isBusinessEmailExist(orgName): Observable<any> {
        // var isExist = this.$http.baseUrlContext.url('/admin/api/org/public/exists-by-email/' + orgName).get()
        let url = URLS.BusinessEmailExistAPI + orgName;
        return this.http.get<any>(url).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }));
        // return isExist;
    }

    isDunsNumberExist(duns): Observable<any> {
        // var isExist = this.$http.baseUrlContext.url('/admin/api/org/duns-exists-with-otherOrg/' + duns).get()
        let url = URLS.DUNSNumberExistsAPI + duns;
        return this.http.get<any>(url).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }));
        // return isExist;
    }

    postCompany(company): Observable<any> {
        // return this.$http.baseUrlContext.url("/admin/api/org/public/create").post(company)
        return this.http.post(URLS.AddCompanyAPI, company, { headers: this.headers })
            .pipe(map((res) => {
                return res;
                // return this.extractData(res);
            }));
    }

    getCompanyDetails(orgId): Observable<any> {
        // var listOfStates = this.$http.baseUrlContext.url('/admin/api/org/fetchOrg').get()
        return this.http.get<any>(URLS.GetCompanyDetailsAPI + "/" + orgId).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }));
        // return listOfStates;
    }

    getServiceType(): Observable<any> {
        return this.http.get<any>(URLS.GetServiceTypeAPI).pipe(
            map((res) => {
                return res;
            }));
    }

    getLanguages(): Observable<any> {
        // var languages = this.$http.baseUrlContext.url('/admin/api/org/languages').get()
        return this.http.get<any>(URLS.GetLanguagesAPI).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }));
        // return languages;
    }

    getCurrency(): Observable<any> {
        // var currency = this.$http.baseUrlContext.url('/admin/api/org/currencies').get()
        return this.http.get<any>(URLS.GetCurrencyAPI).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }));
        // return currency;
    }

    getTimeZones(): Observable<any> {
        // var timeZones = this.$http.baseUrlContext.url('/admin/api/org/available-timezones').get()
        return this.http.get<any>(URLS.GetTimeZonesAPI).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }));
        // return timeZones;
    }

    getDateFormates(): Observable<any> {
        // var dateFormates = this.$http.baseUrlContext.url('/admin/api/org/available-dateFormats').get()
        return this.http.get<any>(URLS.GetDateFormatAPI).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }));
        // return dateFormates;
    }

    updateCompany(company): Observable<any> {
        // return this.$http.baseUrlContext.url("/admin/api/org/update").put(company)
        return this.http.put(URLS.UpdateCompanyDetailsAPI, company, { headers: this.headers })
            .pipe(map((res) => {
                return res;
                // return this.extractData(res);
            }));
    }

    getAllModules(): Observable<any> {
        // return this.$http.baseUrlContext.url('/admin/api/access/fetch-ApplicationModules').get()
        return this.http.get<any>(URLS.GetAllModulesAPI).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);return this.extractData(res);
            }));
    }

    updateModulesList(moduleList): Observable<any> {
        // return this.$http.baseUrlContext.url("/admin/api/access/update-ApplicationModules").put(moduleList)
        return this.http.put(URLS.UpdateModuleAPI, moduleList, { headers: this.headers, observe: 'response', responseType: 'text' }).pipe(
            map((res: any) => {
                return res;
                // return this.extractData(res);
            }));
    }

    getDepartmentList(pageNumber?, pageSize?, sortColumn?, sortOrder?): Observable<any> {
        let userlistRequest: any;
        userlistRequest = {
            "pageNumber": pageNumber,
            "pageSize": pageSize,
            "attribute": "DEPARTMENT",
            "sortColumn": sortColumn,
            "sortOrder": sortOrder
        }
        // return this.$http.baseUrlContext.url("/admin/api/user/attribute-list").post(userlistRequest)
        return this.http.post(URLS.GetDepartmentListAPI, userlistRequest, { headers: this.headers }).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }
            ));
    }

    getDepartment(deptId): Observable<any> {
        // return this.$http.baseUrlContext.url("/admin/api/user/attribute-details/" + deptId).get()
        let url = URLS.GetDepartmentAPI + deptId;
        return this.http.get<any>(url).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }
            ));
    }

    getCatlogDepartment(): Observable<any> {
        // return this.$http.baseUrlContext.url("/admin/api/user/attribute-details/" + deptId).get()
        let url = URLS.GetCatalogCategoryAPI;
        return this.http.get<any>(url).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }
            ));
    }

    updateDepartment(dept): Observable<any> {
        // return this.$http.baseUrlContext.url("/admin/api/user/update-attribute").put(dept)
        return this.http.put(URLS.UpdateDepartmentAPI, dept, { headers: this.headers }).pipe(
            map((res: any) => {
                return res;
            }));
    }

    checkAssociationByProduct(dept): Observable<any> {
        let url = URLS.CheckAssociationByProductAPI + dept;
        return this.http.get<any>(url).pipe(
            map((res) => {
                return res;
            }
            ));
    }

    createDepartment(dept): Observable<any> {
        // return this.$http.baseUrlContext.url("/admin/api/user/create-attribute").post(dept)
        return this.http.post(URLS.AddDepartmentAPI, dept, { headers: this.headers, observe: 'response' }).pipe(
            map((res: any) => {
                return res;
            }));
    }

    isDepartmentNameExist(deptName): Observable<any> {
        // var isExist = this.$http.baseUrlContext.url('/admin/api/user/exists-by-attribute-name/' + deptName).get()
        let url = URLS.DepartmentExistsAPI + deptName;
        return this.http.get<any>(url).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }));
        // return isExist;
    }

    isCommodityExist(commodityname, deptName): Observable<any> {
        return this.http.get(URLS.GetCommodityExistOrNotAPI, {
            params: {
                commodityName: commodityname,
                deptId: deptName
            }
        })
    }

    getDocumentList(pageNumber?, pageSize?, sortColumn?, sortOrder?): Observable<any> {
        let doclistRequest: any;
        doclistRequest = {
            "pageNumber": pageNumber,
            "pageSize": pageSize,
            "sortColumn": sortColumn,
            "sortOrder": sortOrder
        }

        // return this.$http.baseUrlContext.url("/admin/api/document/all").post(userlistRequest)
        return this.http.post(URLS.GetDocumentListAPI, doclistRequest, { headers: this.headers }).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }
            ));
    }

    //TODO: needs test
    deleteDocument(dept): Observable<any> {
        let objIds: any;
        objIds = {
            "ids": [
                dept
            ]
        }
        // return this.$http.baseUrlContext.url("/admin/api/document/delete").delete(objIds)
        return this.http.request('DELETE', URLS.DeteltDocumentAPI, { headers: this.headers, body: objIds, observe: 'response', responseType: 'text' }).pipe(
            map((res: any) => {
                return res;
            }));
    }

    getDocTypes(): Observable<any> {
        // var listOfDocType = this.$http.baseUrlContext.url('/admin/api/document/document-types').get()
        return this.http.get<any>(URLS.GetDocumentTypeAPI).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }));
        // return listOfDocType;
    }

    getDocumentThumbnailList(pageNumber, orgId, pageSize, sortColumn): Observable<any> {
        let reqData: any;
        reqData = {
            "pageNumber": pageNumber,
            "id": orgId,
            "pageSize": pageSize,
            "sortColumn": sortColumn,
            "sortOrder": "ASC"
        }
        // return this.$http.baseUrlContext.url('/admin/api/trading-partners/all').post(reqData)
        // return this.$http.baseUrlContext.url('/admin/api/document/all/certificates-licenses').post(reqData)
        return this.http.post(URLS.GetDocThumbnailList, reqData, { headers: this.headers }).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }));
    }

    // getProfilePreviewDetails() {
    //     var orgData = this.$http.baseUrlContext.url('/admin/api/org/fetchOrg').get()
    getProfilePreviewDetails(orgId): Observable<any> {
        // var orgData = this.$http.baseUrlContext.url('/admin/api/org/overview/' + orgId).get()
        let url = URLS.GetProfileOverviewAPI + orgId;
        return this.http.get<any>(url).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }));
        // return orgData;
    }
    getFileObject(objId): Observable<any> {
        // var orgData = this.$http.baseUrlContext.url('/admin/api/object-store/view/' + objId).get()
        let url = URLS.GetFileObjectViewAPI + objId;
        return this.http.get<any>(url).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }));
        // return orgData;
    }

    getFileObjectDocument(objId): Observable<any> {
        // var orgData = this.$http.baseUrlContext.url('/admin/api/object-store/view/' + objId).get()
        let url = URLS.GetFileObjectDocument + objId;
        return this.http.get(url, { headers: this.headers, observe: 'response', responseType: 'blob' }).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }));
        // return orgData;
    }

    getCommodityList(pageNumber?, pageSize?, sortColumn?, sortOrder?): Observable<any> {
        let commoditylistRequest: any;
        commoditylistRequest = {
            "pageNumber": pageNumber,
            "pageSize": pageSize,
            "sortColumn": sortColumn,
            "sortOrder": sortOrder
        }
        // return this.$http.baseUrlContext.url("/catalog/api/catalog/fetch-commodity-list").post(commoditylistRequest)
        return this.http.post(URLS.GetCommodityListAPI, commoditylistRequest, { headers: this.headers }).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }
            ));
    }

    getCommodity(commodityId): Observable<any> {
        // return this.$http.baseUrlContext.url("/catalog/api/catalog/fetch-commodity/" + commodityId).get()
        let url = URLS.GetCommodityAPI + commodityId;
        return this.http.get<any>(url).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }
            ));
    }

    updateCommodity(commodity): Observable<any> {
        // return this.$http.baseUrlContext.url("/catalog/api/catalog/update-commodity").put(commodity)
        return this.http.put(URLS.UpdateCommodityAPI, commodity, { headers: this.headers, observe: 'response' }).pipe(
            map((res: any) => {
                return res;
                // return this.extractData(res);
            }));
    }

    searchCommodityByName(commdityKey: any): Observable<any> {
        // return this.$http.baseUrlContext.url("/catalog/api/catalog/search/suggest-commodity/" + commdityKey).get()
        let url = URLS.SearchCommoditybyNameAPI + commdityKey;
        return this.http.get<any>(url).pipe(
            map(res => {
                return res;
                // return this.extractData(res);
            }));
    }

    searchOrder(orderNumber: any): Observable<any> {
        // return this.$http.baseUrlContext.url("/order/buy/search/" + orderNumber).get()
        let url = URLS.GetCommodityAPI + orderNumber;
        return this.http.get<any>(url).pipe(
            map(res => {
                return res;
                // return this.extractData(res);
            }));
    }

    getDepartmentsList(): Observable<any> {
        // var listOfDepartments = this.$http.baseUrlContext.url('/admin/api/user/attributes/DEPARTMENT').get()
        return this.http.get<any>(URLS.UserGetDepartmentListAPI).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }));
        // return listOfDepartments;
    }

    getCommoditiesByDepartment(pageNumber?, pageSize?, sortColumn?, sortOrder?, departmentList?): Observable<any> {
        let commoditylistRequest: any;
        commoditylistRequest = {
            "pageNumber": pageNumber,
            "pageSize": pageSize,
            "sortColumn": sortColumn,
            "sortOrder": sortOrder
        }
        // return this.$http.baseUrlContext.url("/catalog/api/catalog/fetch-commodity-list-on-category-selection?categoryCodes=" + departmentList).post(commoditylistRequest)
        let url = URLS.GetCommodityByDepartment + "?&categoryCodes=" + departmentList;
        return this.http.post(url, commoditylistRequest, { headers: this.headers }).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }
            ));
    }

    getCommodityOnKey(commodityName, commodityId): Observable<any> {
        // return this.$http.baseUrlContext.url("/catalog/api/catalog/search/commodity/" + commodityId).get()
        let url = URLS.GetCommodityOnKey + commodityId;
        return this.http.get<any>(url).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }
            ));
    }

    updateSelectedCommoditiesStatus(selectedCommdityList): Observable<any> {
        // return this.$http.baseUrlContext.url("/catalog/api/catalog/update-selected-commodities").put(selectedCommdityList)
        return this.http.put(URLS.UpdateSelectedCommodity, selectedCommdityList, { headers: this.headers }).pipe(
            map((res: any) => {
                return res;
            }));
    }

    getRangeListByApp(pageNumber?, pageSize?, sortColumn?, sortOrder?, applicationType?): Observable<any> {
        let userlistRequest: any;
        userlistRequest = {
            "pageNumber": pageNumber,
            "pageSize": pageSize,
            // "attribute": "DEPARTMENT",
            "sortColumn": sortColumn,
            "sortOrder": sortOrder,
            "applicationCode": applicationType
        }
        // return this.$http.baseUrlContext.url("/admin/api/range/list").post(userlistRequest)
        return this.http.post(URLS.GetRangeListByAPPAPI, userlistRequest, { headers: this.headers }).pipe(
            map((res) => {
                return res;
            }
            ));
    }

    getRange(rangeId): Observable<any> {
        // return this.$http.baseUrlContext.url("/admin/api/user/attribute-details/" + rangeId).get()
        let url = URLS.GetRangeAPI + rangeId;
        return this.http.get<any>(url).pipe(
            map((res) => {
                return res;
            }
            ));
    }

    updateRange(range) {
        // return this.$http.baseUrlContext.url("/admin/api/range/addUpdate").put(range)
        return this.http.put(URLS.UpdateRangeAPI, range, { headers: this.headers, observe: 'response', responseType: 'text' }).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }));
    }

    getOrderRangeDetails(requestObj) {
        let url = URLS.OrderRangeDetailsAPI;
        return this.http.post<any>(url, requestObj, { headers: this.headers }).pipe(
            map((res) => {
                return res;
            }));
    }

    getRangeTypeList(appCode): Observable<any> {
        // return this.$http.baseUrlContext.url("/admin/api/range/types/" + appCode).get()
        let url = URLS.GetRangeTypeListAPI + appCode;
        return this.http.get<any>(url).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }
            ));
    }

    getApplyBy(): Observable<any> {
        // return this.$http.baseUrlContext.url("/admin/api/range/apply-by").get()
        return this.http.get<any>(URLS.GetApplyByAPI).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }
            ));
    }

    getBusinessUnitListOnAppCode(appCode): Observable<any> {
        // return this.$http.baseUrlContext.url("/admin/api/org/business-units/" + appCode).get()
        let url = URLS.GetBusinessUnitListOnAppCodeAPI + appCode;
        return this.http.get<any>(url).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            }
            ));
    }

    isPrefixExist(orgName): Observable<any> {
        // var isExist = this.$http.baseUrlContext.url('/admin/api/range/is-prefix-duplicate/' + orgName).get()
        let url = URLS.isPrefixExistAPI + orgName;
        return this.http.get<any>(url).pipe(
            map((res) => {
                return res;
                // this.extractData(res);
            }));
        // return isExist;
    }

    extractData(response) {
        return response.body;
    }
    /* Order Documents */
    uploadPODoc(data) {
        let headers = new HttpHeaders();
        // Add Content-Type be default
        headers.set('Content-Type', 'application/json');
        let url = URLS.UploadCommodityAPI;
        return this.http.post(url, data, {
            reportProgress: true,
            observe: 'events',
            headers: this.headers,
            responseType: 'blob'
        }).pipe(map((event) => {
            return event
            /*         console.log("eventeventeventevent=", event)
                    switch (event.type) {
                        case HttpEventType.UploadProgress:
                            const progress = Math.round(100 * event.loaded / event.total);
                            return { status: 'progress', message: progress };
                        case HttpEventType.Response: {
                            if(event.headers.get('X-FILE-MESSAGE') == "File uploaded successfully"){
                                this.notify.show(this.transalate.instant('COMPANY.MESSAGE.DCO_UPLOAD_SUCCESS'));
                                return null;
                            }else if (event.headers.get('X-FILE-MESSAGE') == "File processed partially"){
                                return event.body;
                            }else if (event.headers.get('X-FILE-MESSAGE') == "Error processing catalog item upload"){
                                this.notify.show(this.transalate.instant('Error processing catalog item upload'));
                            }
                           
                        }
                        default:
                            return `Unhandled event: ${event.type}`;
                    } */
        })
        );
    }
    getCommodityTemplate() {
        let url = URLS.DownloadCommodityFileAPI;
        let headers = new HttpHeaders();
        // Add Content-Type be default
        headers.set('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        return this.http.get(url, { headers: this.headers, observe: 'response', responseType: 'blob' }).pipe(
            map((res) => {
                return res;
            }));
    }


    addUserCommodity(commodity): Observable<any> {
        return this.http.put(URLS.AddUserCommodityAPI, commodity, { headers: this.headers, observe: 'response' }).pipe(
            map((res: any) => {
                return res;
                // return this.extractData(res);
            }));
    }

}