<side-nav>
  <div class="navBarHeight" sidenavitem>
    <ng-container *ngIf="menuType==''">
      <!-- (RD)PMB-6554 New header and navigation menu bar for Procurant Commerce -->
      <div class="nav-menu-list">
        <a mat-list-item routerLink="/dashboard"         
          routerLinkActive="active" title="{{'NAVBAR.LABEL.DASHBOARD' | translate}}">
          <span class="sidebar-icon icon-dashboard"></span>
          <span class="nav-name"> {{ 'NAVBAR.LABEL.DASHBOARD' | translate }}</span>
        </a>
        <a mat-list-item routerLink="/task-center/task-center-list" routerLinkActive="active"
          title="{{'NAVBAR.LABEL.TASK_CENTER' | translate}}">
          <span class="sidebar-icon icon-task_centre"></span>
          <span class="nav-name">{{ 'NAVBAR.LABEL.TASK_CENTER' | translate }}</span>
        </a>
        <a mat-list-item routerLink="/users-setting" routerLinkActive="active"
          title="{{'NAVBAR.LABEL.MY_PREFERENCES' | translate}}">
          <span class="sidebar-icon icon2-settings"></span>
          <span class="nav-name"> {{ 'NAVBAR.LABEL.MY_PREFERENCES' | translate }}</span>
        </a>
      </div>
      <div class="connect-link">
        <a mat-list-item routerLink="/connect" routerLinkActive="active" title="{{'NAVBAR.LABEL.CONNECT' | translate}}"
          class="connect-link">
          <!-- <span class="sidebar-icon icon-Group"></span> -->
          <img src="../../../../../assets/images/Group.png">
          <span class="nav-name">{{'NAVBAR.LABEL.CONNECT' | translate}}</span>
        </a>
      </div>
    </ng-container>
    <ng-container>
      <!-- (RD)hide scroll from internal admin side nav bar -->
      <div class="internal-nav-new-bar" *ngIf="permissions?.INTL_ADMIN">
        <div *ngIf="navigationScrollButton=='up' || navigationScrollButton=='both'" class="up-btn"
          (click)="handelScrollClick('up')">
          <img src="../../../../../assets/images/up_double.png" alt="">
        </div>
        <div class="nav-menu-list-inetrnal" id="sidebar" (scroll)="scrollFunction()">
          <a mat-list-item routerLink="/internal-admin" *ngIf="permissions?.INTL_ADMIN" routerLinkActive=" active"
            title="{{'COMPANY.LABEL.ACCOUNT_MANAGEMENT' | translate}}">
            <!-- <span class="sidebar-icon icon2-trading-partners"></span> -->
            <div class="sprite-container account-mng">
              <div class="sprite-image"></div>
            </div>
            <span class="nav-name">{{ 'COMPANY.LABEL.ACCOUNT_MANAGEMENT' | translate }}</span>
          </a>
          <a mat-list-item routerLink="/integration-documents" *ngIf="permissions?.INTL_ADMIN"
            routerLinkActive="active" title="{{'NAVBAR.LABEL.DOCUMENT_MONITORING' | translate}}">
            <!-- <span class="sidebar-icon icons-documentmonitor"></span> -->
            <div class="sprite-container monitoring">
              <div class="sprite-image"></div>
            </div>
            <span class="nav-name">{{ 'NAVBAR.LABEL.DOCUMENT_MONITORING' | translate }}</span>
          </a>
          <a mat-list-item routerLink="/user/user-list" routerLinkActive="active" *ngIf="permissions?.INTL_ADMIN"
            title="{{'NAVBAR.LABEL.USERS' | translate}}">
            <!-- <span class="sidebar-icon icon-task_centre"></span> -->
            <div class="sprite-container user">
              <div class="sprite-image"></div>
            </div>
            <span class="nav-name">{{ 'NAVBAR.LABEL.USERS' | translate }}</span>
          </a>
          <a mat-list-item routerLink="/integration-partners/partner-list" *ngIf="permissions?.INTL_ADMIN"
            routerLinkActive=" active" title="{{'NAVBAR.LABEL.INTEGRATION_SETUP' | translate}}">
            <!-- <span class="sidebar-icon icon2-settings"></span> -->
            <div class="sprite-container integration-setup">
              <div class="sprite-image"></div>
            </div>
            <span class="nav-name">{{ 'NAVBAR.LABEL.INTEGRATION_SETUP' | translate }}</span>
          </a>
          <a mat-list-item *ngIf="permissions?.INTL_ADMIN" routerLink="/orders-rollback" routerLinkActive="active"
            title="{{'NAVBAR.LABEL.ORDERS' | translate}}">
            <div class="sprite-container order">
              <div class="sprite-image"></div>
            </div>
            <span class="nav-name">{{ 'NAVBAR.LABEL.ORDERS' | translate }}</span>
          </a>
          <a mat-list-item routerLink="/internal-admin-message" *ngIf="permissions?.INTL_ADMIN"
            routerLinkActive=" active" title="{{'NAVBAR.LABEL.MESSAGE' | translate}}">
            <!-- <span class="sidebar-icon icon-chat_message" style="color: #fff;"></span> -->
            <!-- {{ 'COMPANY.LABEL.MESSAGE' | translate }} -->
            <div class="sprite-container messages">
              <div class="sprite-image"></div>
            </div>
            <span class="nav-name">{{ 'NAVBAR.LABEL.MESSAGE' | translate }}</span>
          </a>
          <a mat-list-item routerLink="/app/setup"
            *ngIf="(permissions?.BUYER?.MODULES?.ADMIN && permissions?.BUYER?.MODULES?.ADMIN?.actions?.view || permissions?.SUPPLIER?.MODULES?.ADMIN && permissions?.SUPPLIER?.MODULES?.ADMIN?.actions?.view) && permissions?.CARRIER?.MODULES?.ADMIN && permissions?.CARRIER?.MODULES?.ADMIN?.actions?.view"
            routerLinkActive="active" title="{{'NAVBAR.LABEL.SETUP' | translate}}">
            <span class="sidebar-icon icon2-settings"></span>
            <span class="nav-name">{{ 'NAVBAR.LABEL.SETUP' | translate }}</span>
          </a>
          <a mat-list-item routerLink="/ift-integration-documents/ift-document-monitoring"
            *ngIf="ift_document_monitoring" routerLinkActive="active"
            title="{{'NAVBAR.LABEL.DOCUMENT_MONITORING' | translate}}">
            <!-- <span class="sidebar-icon icons-documentmonitor"></span> -->
            <div class="sprite-container monitoring">
              <div class="sprite-image"></div>
            </div>
            <span class="nav-name">{{ 'NAVBAR.LABEL.DOCUMENT_MONITORING' | translate }}</span>
          </a>
          <!-- (NM)PMB-6342 Added menu for add internal admin user accounts. -->
          <a mat-list-item routerLink="/internal-admin-user" *ngIf="permissions?.INTL_ADMIN" routerLinkActive=" active"
            title="{{'NAVBAR.LABEL.INTERNAL_ADMINS' | translate }}">
            <div class="sprite-container internal-admin">
              <div class="sprite-image"></div>
            </div>
            <span class="nav-name">{{ 'NAVBAR.LABEL.INTERNAL_ADMINS' | translate }}</span>
          </a>
          <a mat-list-item routerLink="/internal-admin-reports-analytics" *ngIf="permissions?.INTL_ADMIN"
            routerLinkActive="active" title="{{'NAVBAR.LABEL.REPORTS' | translate}}">
            <!-- <span class="sidebar-icon icon-analytics"></span> -->
            <div class="sprite-container report">
              <div class="sprite-image"></div>
            </div>
            <span class="nav-name">{{ 'NAVBAR.LABEL.REPORTS' | translate }}</span>
          </a>
        </div>
        <div *ngIf="navigationScrollButton=='down' || navigationScrollButton=='both'" class="down-btn"
          (click)="handelScrollClick('down')">
          <img src="../../../../../assets/images/down_double.png" alt="">
        </div>
      </div>
      <div class="nav-new-bar" *ngIf="!permissions?.INTL_ADMIN">
        <div *ngIf="navigationScrollButton=='up' || navigationScrollButton=='both'" class="up-btn"
          (click)="handelScrollClick('up')">
          <img src="../../../../../assets/images/up_double.png" alt="">
        </div>
        <div class="nav-menu-list" id="sidebar" (scroll)="scrollFunction()">
          <a mat-list-item routerLink="/dashboard/buyer-dashboard" routerLinkActive="active" *ngIf="(permissions?.BUYER?.MODULES?.ADMIN && permissions?.BUYER?.MODULES?.ADMIN?.actions?.view)"
            title="{{'NAVBAR.LABEL.DASHBOARD' | translate}}">
            <!-- <span class="sidebar-icon icon-account_settings"></span> -->
            <div class="sprite-container dashboard">
              <div class="sprite-image"></div>
            </div>
            <span class="nav-name"> {{ 'NAVBAR.LABEL.DASHBOARD' | translate }}</span>
          </a>
          <a mat-list-item routerLink="/company/account-setting" routerLinkActive="active" *ngIf="(permissions?.BUYER?.MODULES?.ADMIN && permissions?.BUYER?.MODULES?.ADMIN?.actions?.view) ||
           (permissions?.SUPPLIER?.MODULES?.ADMIN && permissions?.SUPPLIER?.MODULES?.ADMIN?.actions?.view) ||
           (permissions?.START?.MODULES?.ADMIN && permissions?.START?.MODULES?.ADMIN?.actions?.view) || 
           (permissions?.CONNECT?.MODULES?.ADMIN && permissions?.CONNECT?.MODULES?.ADMIN?.actions?.view) || 
           (permissions?.CARRIER?.MODULES?.ADMIN && permissions?.CARRIER?.MODULES?.ADMIN?.actions?.view)"
            title="{{'NAVBAR.LABEL.ACCOUNT_PROFILE' | translate}}">
            <!-- <span class="sidebar-icon icon-account_settings"></span> -->
            <div class="sprite-container account">
              <div class="sprite-image"></div>
            </div>
            <span class="nav-name"> {{ 'NAVBAR.LABEL.ACCOUNT_PROFILE' | translate }}</span>
          </a>
          <a mat-list-item routerLink="/user/user-list" routerLinkActive="active" *ngIf="(permissions?.BUYER?.MODULES?.ADMIN && permissions?.BUYER?.MODULES?.ADMIN?.actions?.view) ||
           (permissions?.SUPPLIER?.MODULES?.ADMIN && permissions?.SUPPLIER?.MODULES?.ADMIN?.actions?.view) ||
           (permissions?.START?.MODULES?.ADMIN && permissions?.START?.MODULES?.ADMIN?.actions?.view)  ||
           (permissions?.CONNECT?.MODULES?.ADMIN && permissions?.CONNECT?.MODULES?.ADMIN?.actions?.view) ||
           (permissions?.CARRIER?.MODULES?.ADMIN && permissions?.CARRIER?.MODULES?.ADMIN?.actions?.view)"
            title="{{'NAVBAR.LABEL.USERS' | translate}}">
            <div class="sprite-container user">
              <div class="sprite-image"></div>
            </div>
            <span class="nav-name"> {{ 'NAVBAR.LABEL.USERS' | translate }}</span>
          </a>
          <a mat-list-item routerLink="/business-units" routerLinkActive="active" *ngIf="(permissions?.BUYER?.MODULES?.ADMIN && permissions?.BUYER?.MODULES?.ADMIN?.actions?.view) ||
        (permissions?.SUPPLIER?.MODULES?.ADMIN && permissions?.SUPPLIER?.MODULES?.ADMIN?.actions?.view) ||
        (permissions?.START?.MODULES?.ADMIN && permissions?.START?.MODULES?.ADMIN?.actions?.view)  ||
        (permissions?.CONNECT?.MODULES?.ADMIN && permissions?.CONNECT?.MODULES?.ADMIN?.actions?.view)"
            title="{{'NAVBAR.LABEL.BUSINESS_UNITS' | translate}}">
            <div class="sprite-container business-units">
              <div class="sprite-image"></div>
            </div>
            <span class="nav-name">{{ 'NAVBAR.LABEL.BUSINESS_UNITS' | translate }}</span>
          </a>
          <a mat-list-item routerLink="/catalog" routerLinkActive="active" *ngIf="(permissions?.BUYER?.MODULES?.ADMIN && permissions?.BUYER?.MODULES?.ADMIN?.actions?.view) ||
        (permissions?.SUPPLIER?.MODULES?.ADMIN && permissions?.SUPPLIER?.MODULES?.ADMIN?.actions?.view)"
            title="{{'NAVBAR.LABEL.CATALOG' | translate}}">
            <!-- <span class="sidebar-icon icon-catalog"></span> -->
            <div class="sprite-container catalog">
              <div class="sprite-image"></div>
            </div>
            <span class="nav-name">{{ 'NAVBAR.LABEL.CATALOG' | translate }}</span>
          </a>
          <a mat-list-item routerLink="/trading" routerLinkActive="active" *ngIf="(permissions?.BUYER?.MODULES?.ADMIN && permissions?.BUYER?.MODULES?.ADMIN?.actions?.view) ||
        (permissions?.SUPPLIER?.MODULES?.ADMIN && permissions?.SUPPLIER?.MODULES?.ADMIN?.actions?.view) ||      
        (permissions?.CARRIER?.MODULES?.ADMIN && permissions?.CARRIER?.MODULES?.ADMIN?.actions?.view)"
            title="{{'NAVBAR.LABEL.TRADING_PARTNERS' | translate}}">
            <!-- <span class="sidebar-icon icon2-trading-partners"></span> -->
            <div class="sprite-container trading">
              <div class="sprite-image"></div>
            </div>
            <span class="nav-name">{{ 'NAVBAR.LABEL.TRADING_PARTNERS' | translate }}</span>
          </a>
          <a mat-list-item routerLink="/business-rules" routerLinkActive="active" *ngIf="(permissions?.BUYER?.MODULES?.ADMIN && permissions?.BUYER?.MODULES?.ADMIN?.actions?.view) ||
        (permissions?.SUPPLIER?.MODULES?.ADMIN && permissions?.SUPPLIER?.MODULES?.ADMIN?.actions?.view) ||
        (permissions?.CARRIER?.MODULES?.ADMIN && permissions?.CARRIER?.MODULES?.ADMIN?.actions?.view)"
            title="{{'NAVBAR.LABEL.BUSINESS_RULES' | translate}}">
            <!-- <span class="sidebar-icon icon2-business-rules"></span> -->
            <div class="sprite-container business-rules">
              <div class="sprite-image"></div>
            </div>
            <span class="nav-name">{{ 'NAVBAR.LABEL.BUSINESS_RULES' | translate }}</span>
          </a>
          <a mat-list-item routerLink="/charge/charge-list" routerLinkActive="active" *ngIf="(permissions?.BUYER?.MODULES?.ADMIN && permissions?.BUYER?.MODULES?.ADMIN?.actions?.view) ||
        (permissions?.SUPPLIER?.MODULES?.ADMIN && permissions?.SUPPLIER?.MODULES?.ADMIN?.actions?.view)"
            title="{{'NAVBAR.LABEL.TERMS_AND_CHARGES' | translate}}">
            <!-- <span class="sidebar-icon icon-payments"></span> -->
            <div class="sprite-container charge">
              <div class="sprite-image"></div>
            </div>
            <span class="nav-name">{{ 'NAVBAR.LABEL.TERMS_AND_CHARGES' | translate }}</span>
          </a>
          <a mat-list-item routerLink="/label" *ngIf="permissions?.PTI?.MODULES?.ADMIN?.actions?.view"
            routerLinkActive="active" title="{{'NAVBAR.LABEL.LABEL' | translate}}">
            <!-- <span class="sideBarIco icon-barcode"></span> -->
            <div class="sprite-container label">
              <div class="sprite-image"></div>
            </div>
            <span class="nav-name">{{ 'NAVBAR.LABEL.LABEL' | translate }}</span>
          </a>
          <a mat-list-item routerLink="/crew" *ngIf="permissions?.PTI?.MODULES?.ADMIN?.actions?.view"
            routerLinkActive="active" title="{{'NAVBAR.LABEL.CREW' | translate}}">
            <!-- <span class="sideBarIco icon-users"></span> -->
            <div class="sprite-container crew">
              <div class="sprite-image"></div>
            </div>
            <span class="nav-name">{{ 'NAVBAR.LABEL.CREW' | translate }}</span>
          </a>
          <a mat-list-item routerLink="/lot" *ngIf="permissions?.PTI?.MODULES?.ADMIN?.actions?.view"
            routerLinkActive="active" title="{{'NAVBAR.LABEL.LOT' | translate}}">
            <!-- <span class="sideBarIco icon-codepen"></span> -->
            <div class="sprite-container lot">
              <div class="sprite-image"></div>
            </div>
            <span class="nav-name">{{ 'NAVBAR.LABEL.LOT' | translate }}</span>
          </a>
          <a mat-list-item routerLink="/origin"
            *ngIf="permissions?.PTI?.MODULES?.ADMIN?.actions?.view || (permissions?.SUPPLIER?.MODULES?.ADMIN && permissions?.SUPPLIER?.MODULES?.ADMIN?.actions?.view)"
            routerLinkActive="active" title="{{'NAVBAR.LABEL.ORIGIN' | translate}}">
            <!-- <span class="sideBarIco icon-earth"></span> -->
            <div class="sprite-container origin">
              <div class="sprite-image"></div>
            </div>
            <span class="nav-name">{{ 'NAVBAR.LABEL.ORIGIN' | translate }}</span>
          </a>
          <a mat-list-item routerLink="/service-provider" routerLinkActive="active" *ngIf="(permissions?.BUYER?.MODULES?.ADMIN && permissions?.BUYER?.MODULES?.ADMIN?.actions?.view) ||
        (permissions?.SUPPLIER?.MODULES?.ADMIN && permissions?.SUPPLIER?.MODULES?.ADMIN?.actions?.view) ||
        (permissions?.PTI?.MODULES?.ADMIN && permissions?.PTI?.MODULES?.ADMIN?.actions?.view)"
            title="{{'NAVBAR.LABEL.SERVICE_PROVIDER' | translate}}">
            <!-- <span class="sideBarIco icon-service"></span> -->
            <div class="sprite-container service-provider">
              <div class="sprite-image"></div>
            </div>
            <span class="nav-name">{{ 'NAVBAR.LABEL.SERVICE_PROVIDER' | translate }}</span>
          </a>
          <a mat-list-item routerLink="/vmi-user" *ngIf="permissions?.BUYER" routerLinkActive="active"
            title="{{'NAVBAR.LABEL.MANAGE_VMI' | translate}}">
            <!-- <span class="sideBarIco icon-service"></span> -->
            <div class="sprite-container vmi-user">
              <div class="sprite-image"></div>
            </div>
            <span class="nav-name">{{ 'NAVBAR.LABEL.MANAGE_VMI' | translate }}</span>
          </a>
          <a mat-list-item routerLink="/my-preferences"
            *ngIf="permissions?.INSPECT?.MODULES?.DC_INSPECTION?.actions?.view" routerLinkActive="active"
            title="{{'NAVBAR.LABEL.MY_PREFERENCES' | translate}}">
            <!-- <span class="sidebar-icon icon2-settings"></span> -->
            <div class="sprite-container preferences">
              <div class="sprite-image"></div>
            </div>
            <span class="nav-name">{{ 'NAVBAR.LABEL.MY_PREFERENCES' | translate }}</span>
          </a>
          <a mat-list-item routerLink="/messages"
            *ngIf="(permissions?.BUYER?.MODULES?.ADMIN && permissions?.BUYER?.MODULES?.ADMIN?.actions?.view )"
            routerLinkActive="active" title="{{'NAVBAR.LABEL.MESSAGE' | translate}}">
            <!-- <span class="sidebar-icon icon-chat_message" style="color: #fff;"></span> -->
            <div class="sprite-container messages">
              <div class="sprite-image"></div>
            </div>
            <span class="nav-name">{{ 'NAVBAR.LABEL.MESSAGE' | translate }}</span>
          </a>
          <a mat-list-item routerLink="/reports-analytics" *ngIf="(permissions?.BUYER?.MODULES?.ADMIN && permissions?.BUYER?.MODULES?.ADMIN?.actions?.view ) || 
              (permissions?.SUPPLIER?.MODULES?.ADMIN && permissions?.SUPPLIER?.MODULES?.ADMIN?.actions?.view)"
            routerLinkActive="active" title="{{'NAVBAR.LABEL.REPORTS' | translate}}">
            <!-- <span class="sidebar-icon icon-analytics"></span> -->
            <div class="sprite-container report">
              <div class="sprite-image"></div>
            </div>
            <span class="nav-name">{{ 'NAVBAR.LABEL.REPORTS' | translate }}</span>
          </a>
        </div>
        <div *ngIf="navigationScrollButton=='down' || navigationScrollButton=='both'" class="down-btn"
          (click)="handelScrollClick('down')">
          <img src="../../../../../assets/images/down_double.png" alt="">
        </div>
      </div>
      <div class="connect-link" *ngIf="!permissions?.INTL_ADMIN">
        <a mat-list-item routerLink="/connect" target="_blank" routerLinkActive="active" title="{{'NAVBAR.LABEL.CONNECT' | translate}}">
          <!-- <span class="sidebar-icon icon-Group"></span> -->
          <img src="../../../../../assets/images/Group.png">
          <span class="nav-name">{{'NAVBAR.LABEL.CONNECT' | translate}}</span>
        </a>
      </div>
    </ng-container>
  </div>
</side-nav>