<div fxLayout="row">
  <div fxLayout="column">
    <h2 mat-dialog-title>{{data.title | translate}}</h2>
  </div>
  <span style="flex: 1 1 auto;"> </span>
  <div fxLayout="column">
    <a href="javascript:void(0);" style="min-width: unset;" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </a>
  </div>
</div>
<div mat-dialog-content class="main-content">
  <form [formGroup]="partnerProfileForm">
    <div class="tableContainer">
      <div class="responsiveTable">
        <table class="table customTable claim-tale" formArrayName="documents">
          <thead>
            <tr>
              <th class="textCenter" width="25px">
              </th>
              <th>{{'TABLE_DISPLAY_COLUMNS.DOCUMENT_TYPE' | translate}}</th>
              <th width="10%">{{'TABLE_DISPLAY_COLUMNS.DIRECTION' | translate}}</th>
              <th>{{'TABLE_DISPLAY_COLUMNS.TRIGGER_EVENTS' | translate}}</th>
              <th>{{'TABLE_DISPLAY_COLUMNS.BUSINESS_RULES' | translate}}</th>
              <th>{{'TABLE_DISPLAY_COLUMNS.DATA_COLLECTION_RULES' | translate}}</th>

            </tr>
          </thead>
          <ng-container *ngIf="documents.controls.length>0">
            <tbody *ngFor="let row of documents.controls; let i = index">
              <ng-container [formGroupName]="i">
                <tr>
                  <td class="textCenter">
                    <mat-checkbox formControlName="selected" (change)="$event ? isSelected(row, $event.checked) : null"
                      [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                  </td>
                  <td>
                    {{row.controls['docTypeName'].value}}
                  </td>
                  <td class="editableTd">
                    <mat-form-field class="placeHolderSelect">
                      <mat-select formControlName="direction" #val1
                        [placeholder]="(val.value && val.value == '') ? '':''"
                        (selectionChange)="changeTriggerEvent(row)">
                        <mat-option *ngFor="let item of directions" [value]="item.code">
                          {{item.name}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="row.controls['direction'].hasError('required')">
                        {{'INTEGRATIONSETUP.MESSAGE.DIRECTION_REQUIRED'|translate}}
                      </mat-error>
                    </mat-form-field>
                  </td>
                  <td class="editableTd">
                    <mat-form-field class="placeHolderSelect">
                      <mat-select #val [placeholder]="(val.value && val.value == '') ? '':''"
                        formControlName="triggerEvents" multiple>
                        <mat-option *ngFor="let item of row.controls['triggerEventOptions'].value" [value]="item.code">
                          {{item.name}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="row.controls['triggerEvents'].hasError('required')">
                        {{'INTEGRATIONSETUP.MESSAGE.TRIGGEREVENTS_REQUIRED'|translate}}
                      </mat-error>
                    </mat-form-field>
                  </td>
                  <td class="editableTd">
                    <mat-form-field class="placeHolderSelect" *ngIf="row.controls['direction'].value">
                      <mat-select #val [placeholder]="(val.value && val.value == '') ? '':''"
                        formControlName="bizRules" multiple>
                        <mat-option *ngFor="let item of row.controls['businessRulesOptions'].value"
                          [value]="item.docTypeBizRuleId" [disabled]="item.mandatory==true">
                          {{item.bizRuleName}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="row.controls['bizRules'].hasError('required')">
                        {{'INTEGRATIONSETUP.MESSAGE.BIZRULES_REQUIRED'|translate}}
                      </mat-error>
                    </mat-form-field>
                  </td>
                  <td class="editableTd">
                    <mat-form-field class="placeHolderSelect" *ngIf="row.controls['direction'].value">
                      <mat-select #val formControlName="dcRules" (selectionChange)="updateOnChange($event,row)" multiple >
                        <mat-option *ngFor="let item of row.controls['dcRuleOptions'].value"
                          [value]="item.docTypeBizRuleId" [data-bizId]="item.bizRuleId" [disabled]="checkUserSelection(item,row)">
                          {{item.bizRuleName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </ng-container>
          <ng-container *ngIf="documents.controls.length==0">
            <tbody>
              <tr>
                <td colspan="14" style="text-align: center;padding: 0px !important;">
                  <mat-card class="mat-card " style="padding:50px;">
                    <h3 style="text-align:center"> {{noDataMessage | translate}} </h3>
                  </mat-card>
                </td>
              </tr>
            </tbody>
          </ng-container>
        </table>
      </div>
    </div>
    <div mat-dialog-actions class="action-btns" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button type="submit" class="btn btnBasic" (click)="closeDialog()">{{ 'BUTTON.CANCEL' | translate }}</button>
        <button mat-raised-button type="submit" class="btn btnPrimary" (click)="submitData()"> {{'BUTTON.SAVE' | translate}} </button>
      </div>
  </form>
</div>