<!-- THIS LOGIN PAGE IS ONLY FOR DEVELOPEMENT TESTING -->

<div class="content-container">
  <!-- <div class="language-selector"></div> -->
  <!-- <div class="video-container" #bgvideo></div> -->
  <div class="login-container" [ngClass]="(!showLogin)?'blur-out':'blur-in'" *ngIf="showLogin; else showProccessing">
    <mat-card class="floating-login-card" #logincard>
      <div class="circle-placeholder">
        <div class="logo-circle">
          <img src="assets/images/procurant_logo.png" alt="Procurant" />
        </div>
      </div>
      <div class="login-header">
        <h1>{{ 'LOGIN.SIGN_IN_TO_PROCURANT' | translate }}</h1>
        <p>{{ 'LOGIN.PROCURANT_DETAILS' | translate }}</p>
      </div>
      <form [formGroup]="loginForm" (ngSubmit)="login()">
        <mat-form-field appearance="outline">
          <input matInput placeholder="{{ 'LOGIN.USER_NAME' | translate }}" formControlName="username">
          <mat-error *ngIf="loginForm.controls['username'].touched || loginForm.controls['username'].hasError('required')">
            {{ 'LOGIN.MESSAGE.USER_NAME_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <input matInput placeholder="{{ 'LOGIN.PASSWORD' | translate }}" type="password" formControlName="password">
          <mat-error *ngIf="loginForm.controls['password'].touched || loginForm.controls['password'].hasError('required')">
            {{ 'LOGIN.MESSAGE.PASSWORD_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <div class="login-error">
          <mat-error *ngIf="loginError">
            {{ 'LOGIN.MESSAGE.INVALID_USER_PASSWORD' | translate }}
          </mat-error>
        </div>
        <div class="login-btn">
          <button mat-raised-button class="btnPrimary signupbtn" [disabled]="!loginForm.valid">{{ 'LOGIN.SIGN_IN' | translate }}
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
        </div>
      </form>
      <div class="sublinks">

        <div class="signup">
          <a (click)="openRegistrationDialog()">{{'LOGIN.JOIN_US' | translate }}</a>
        </div>

        <div class="forgot-password">
          <a [routerLink]="'/forgot-password'">{{'LOGIN.FORGET_PASSWORD' | translate }}</a>
        </div>
        <!-- <div>
          <button mat-raised-button class="btn btnBasic" (click)="openRegistrationDialog()">{{
            'LOGIN.JOIN_US' | translate }}</button>
        </div> -->
        <!-- <div> -->
        <!--Removed singup-->
        <!-- </div> -->
      </div>
    </mat-card>
  </div>
  <ng-template #showProccessing>
    <div class="waitlogo">
      <div class="circle-placeholder" [ngClass]="(showWaitLogo)?'blur-in':'blur-out'">
        <div class="logo-circle">
          <img src="assets/images/procurant_logo.png" alt="Procurant" />
        </div>

      </div>
      <div class="company" [ngClass]="(showWaitLogo)?'text-trackin blur-in':'text-trackout blur-out'">
        <h1>Procurant</h1>
      </div>
    </div>
  </ng-template>
</div>