import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { TranslateService } from 'common-lib';
import { OneAuthService } from 'one-auth';
import { filter } from 'rxjs/operators';
import { promise } from 'protractor';


@Component({
  selector: 'side-toolbar',
  templateUrl: './side-toolbar.component.html',
  styleUrls: ['./side-toolbar.component.scss']
})
export class SideToolbarComponent implements OnInit {
  menuType: string
  sideNav = [];
  ift_document_monitoring: boolean = false;
  navigationScrollButton = "none";
  // buyerUserMenus: any = [
  //   { title: this.translate.instant("NAVBAR.LABEL.DASHBOARD"), route: '/dashboard', img: 'icon-dashboard' },
  //   { title: this.translate.instant("NAVBAR.LABEL.TASK_CENTER"), route: '/task-center/task-center-list', img: 'icon-task_centre' }
  // ];   
  constructor(private route: ActivatedRoute, private cdr: ChangeDetectorRef, private auth: OneAuthService, private router: Router, private translateService: TranslateService) {

  }
  public permissions: any;

  ngOnInit() {
    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe(() => {
      this.permissions = this.auth.user.permissions;
      this.setPermission();
      this.cdr.detectChanges();
    })
    this.permissions = this.auth.user.permissions;
    if (this.permissions && Object.keys(this.permissions).length !== 0)
      this.setPermission()
  }

  //PMB-6554(RD) check scroll exits or not
  ngAfterViewInit() {
    this.cdr.detectChanges();
    this.setNavigationScrollButton();
  }


  clickNav(url) {
    this.router.navigateByUrl('activate-user', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(url);
      console.log(url);
    })
  }

  setPermission() {
    // this.layoutService.permissions = res;  
    let isInternalAdmin: boolean = false;
    // let carrierCount = [];
    // let count = [];
    // for (let key in this.permissions) {
    //   count.push({ key: key, value: this.permissions[key] });
    //   if (key === "CARRIER") {
    //     carrierCount.push({ key: key, value: this.permissions[key] });
    //   } else if (key === "INTL_ADMIN") {
    //     isInternalAdmin = true;
    //   }
    // }
    // if (isInternalAdmin) {
    //   this.menuType = 'INTERNAL_ADMIN';
    //   // this.sideNav = this.internalAdminMenus;
    // } else {
    //   this.menuType = 'OTHER';
    // let resCount = count.length === 1 ? carrierCount : [];
    // if (resCount.length > 0) {
    //   let carrier = this.permissions.CARRIER;
    //   let flag = false;
    //   if (carrier) {
    //     if (carrier.MODULES.ADMIN.actions.view)
    //       flag = true;
    //   }

    //   if (flag) {
    //     this.menuType = 'CARRIER_ADMIN';
    //     // this.sideNav = this.adminSideBar();
    //   }
    //   else {
    //     this.menuType = 'DEFAULT_ADMIN';
    //     // this.sideNav = this.adminMenus;
    //   }


    // } else {
    //   let PTICount = [];
    //   let PTIcount = [];
    //   let PTIFlag = false;
    //   for (let key in this.permissions) {
    //     if (key != "START") {
    //       PTIcount.push({ key: key, value: this.permissions[key] });
    //       if (key === "PTI") {
    //         if (this.permissions.PTI.MODULES.ADMIN.actions.view)
    //           PTICount.push({ key: key, value: this.permissions[key] });
    //       }
    //     }
    //   }
    //   let resPTICount = PTIcount.length === 1 ? PTICount : [];
    //   if (resPTICount.length > 0) {
    //     PTIFlag = true;
    //   }
    //   let buyer = this.permissions.BUYER;
    //   let suplier = this.permissions.SUPPLIER;
    //   let carrier = this.permissions.CARRIER;
    //   let flag = false;
    //   let carrierFlag = false;
    //   if (buyer) {
    //     if (buyer.MODULES.ADMIN && buyer.MODULES.ADMIN.actions.view)
    //       flag = true;
    //   }
    //   if (suplier) {
    //     if (suplier.MODULES.ADMIN && suplier.MODULES.ADMIN.actions.view)
    //       flag = true;
    //   }
    //   if (carrier) {
    //     if (carrier.MODULES.ADMIN && carrier.MODULES.ADMIN.actions.view)
    //       carrierFlag = true;
    //   }

    //   if (flag && carrierFlag) {
    //     // this.sideNav = this.adminMenus;
    //     this.menuType = 'DEFAULT_ADMIN';
    //     if (carrierCount.length > 0) {
    //       this.sideNav.push({ title: this.translateService.instant("NAVBAR.LABEL.SETUP"), route: '/app/setup', img: 'icon2-settings' });
    //     }
    //   } else if (!flag && carrierFlag) {
    //     // this.sideNav = this.adminSideBar();
    //     this.menuType = 'CARRIER_ADMIN';
    //   }
    //   else if (flag && !carrierFlag) {
    //     // this.sideNav = this.adminMenus;
    //     this.menuType = 'DEFAULT_ADMIN';
    //   }
    //   else if (PTIFlag && !flag && !isInternalAdmin) {
    //     this.menuType = 'PTI_ADMIN';
    //   } else {
    //     // this.sideNav = this.adminUserMenus;
    //     this.menuType = 'ADMIN_USER_MENUS';
    //   }

    // }
    if (this.permissions.SUPPLIER) {
      let userInfo = this.auth.user.info;
      if (userInfo.orgName.includes('Tanimura') || userInfo.orgName.includes('Antela')) {
        this.ift_document_monitoring = true;
      }
    }
    //}

  }
  //PMB-6554(RD) add on scroll button flag
  scrollFunction() {
    var sidebar = document.getElementById('sidebar');
    this.navigationScrollButton = (sidebar.scrollTop > 20) ? ((sidebar.scrollHeight - sidebar.scrollTop - sidebar.clientHeight - 60 >= 1) ? "both" : "up") : "down";
    // this.scrollPostion=(sidebar.scrollHeight - sidebar.scrollTop - sidebar.clientHeight-60 >= 1) && (sidebar.scrollTop > 20);
  }

  //PMB-6554(RD) check scroll exits or not
  setNavigationScrollButton() {
    var sidebar = document.getElementById('sidebar');
    this.navigationScrollButton = (sidebar.scrollHeight > sidebar.clientHeight) ? "down" : "none";

  }

  //PMB-6554(RD) set flag for up and down buttons
  handelScrollClick(direction) {
    var sidebar = document.getElementById('sidebar');
    sidebar.scrollTop = direction == "up" ? 0 : sidebar.clientHeight;
  }
}
