import { Component, ViewChild, ElementRef, OnInit, Input, EventEmitter, Output, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { SPACE } from '@angular/cdk/keycodes';
import { of } from 'rxjs';

@Component({
  selector: 'procurant-select',
  templateUrl: './procurant-select.component.html',
  styleUrls: ['./procurant-select.component.scss'],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'customClass' },
    },
  ],
})
export class ProcurantSelectComponent implements OnInit, OnChanges {
  @ViewChild('search') searchTextBox: ElementRef;
  @ViewChild('select', { static: true }) select: any;

  @Input() parentForm: FormGroup;
  searchTextboxControl = new FormControl();
  selectedValues: any = [];
  @Input() dataList: any;
  @Input() isMultiple: boolean = false;
  @Input() showSearch: boolean = false;
  @Input() showSelectAll: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() isDisabledOption: boolean = false;
  @Input() textField: string = 'name';
  @Input() valueField: string = 'id';
  @Input() disabledField: string = 'disabled';
  @Input() field: string;
  @Input() placeholder: string = "Select";
  @Input() searchText: string = "Search";
  @Input() selectAllText: string = "Select All";
  @Input() clearSelectionText: string = "Clear Selection";
  @Input() noDataMessage: string = "No Data Found";
  @Input() idText: string = '';
  @Input() toolTipText: string = '';
  @Output() onValueChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSelectAll: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClearSelection: EventEmitter<any> = new EventEmitter<any>();

  private isServerSide = false;
  // @Input()
  // get dataList(): any { return this._list; }
  // set dataList(dataList: any) {
  //   this._list = dataList;
  // }
  // private _list!: any;
  //data: string[] = ['A1', 'A2', 'A3', 'B1', 'B2', 'B3', 'C1', 'C2', 'C3'];

  filteredOptions!: any;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {


    this.select._handleKeydown = (event: KeyboardEvent) => {
      if (event.code == "Space") {
        const active = this.select.panelOpen ? this.select.options.filter(x => x.active)[0] || null : null
        return active ? active.value : null;
        //this.spacekeydown.emit(active ? active.value : null);
      }
      if (!this.select.disabled) {
        this.select.panelOpen
          ? this.select._handleOpenKeydown(event)
          : this.select._handleClosedKeydown(event);
      }
    };
    /**
     * Set filter event based on value changes
     */
    //this.selectedValues = [];
    this.filteredOptions = this.dataList;
    this.searchTextboxControl.valueChanges.subscribe(val => {
      this.filter(val)
    }


    );

  }

  keyDownInputEvent(event) {
    //event.key == ' '
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }


  /**
   * Used to filter data based on search input
   */
  private filter(event) {

    if (event) {
      const filterValue = event.toLowerCase();
      // Set selected values to retain the selected checkbox state
      //this.setSelectedValues();
      //this.parentForm[this.field]?.patchValue(this.selectedValues);
      this.filteredOptions = this.dataList.filter(
        (option) => option[this.textField].toLowerCase().indexOf(filterValue) === 0
      );
    } else {
      this.filteredOptions = this.dataList;
    }
    //return filteredList;
  }

  /**
   * Remove from selected values based on uncheck
   */
  selectionChange(event) {
    if (event.isUserInput && event.source.selected == false) {
      // let index = this.selectedValues.indexOf(event.source.value);
      // this.selectedValues.splice(index, 1);
      // let fIndex = this.parentForm.get(this.field).value.indexOf(event.source.value);
      // this.parentForm.get(this.field).value.splice(fIndex, 1);
      this.onValueChanged.emit(event);
    }
    else {
      // if (this.isMultiple == false) {
      //   this.selectedValues = [];
      //   this.selectedValues.push(event.source.value)
      //   this.parentForm.get(this.field).updateValueAndValidity();
      //   this.parentForm.updateValueAndValidity();
      //   this.onValueChanged.emit();
      // }
      // else {
      //   // this.selectedValues.push(event.source.value);
      //   this.parentForm.get(this.field).updateValueAndValidity();
      //this.parentForm.get(this.field).patchValue(event.source.value)
      this.onValueChanged.emit(event);

      //}
    }


    // else {
    //   this.onListChanged.emit(this.searchTextBox);
    // }
  }

  openedChange(e) {
    // Set search textbox value as empty while opening selectbox
    if (this.showSearch) {
      this.searchTextboxControl.patchValue('');
      // Focus to search textbox while clicking on selectbox
      if (e == true) {
        this.searchTextBox.nativeElement.focus();
      }
    }
  }

  /**
   * Clearing search textbox value
   */
  clearSearch(event) {
    event.stopPropagation();
    this.searchTextboxControl.patchValue('');
  }

  /**
   * Set selected values to retain the state
   */
  // setSelectedValues() {

  //   if (this.parentForm.get(this.field)?.value && this.parentForm.get(this.field)?.value.length > 0 && this.parentForm.get(this.field)?.value instanceof Array) {
  //     this.parentForm.get(this.field).value.forEach((e) => {
  //       if (this.selectedValues.indexOf(e) == -1) {
  //         this.selectedValues.push(e);
  //       }
  //     });
  //   }
  // }

  selectAll() {
    console.log(this.filteredOptions);
    this.parentForm.get(this.field)?.patchValue('');
    this.parentForm.get(this.field)?.patchValue(this.dataList.map(t => t[this.valueField]));
    console.log(this.parentForm);
    this.onSelectAll.emit();
  }

  clearSelection() {
    console.log('clearSelection');
    this.parentForm.get(this.field)?.patchValue('');
    this.parentForm.get(this.field).updateValueAndValidity();
    this.onClearSelection.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //if (this.dataList instanceof Array) {
      this.filteredOptions = this.dataList;
      // this.dataList = of(this.dataList);
      // this.isServerSide = false;
    //}
  }
}
