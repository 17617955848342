/***********************************************************************
* DESCRIPTION :

*  Ability to rollback PO from cancelled and shipped status to last state by internal admin/support

* NOTES :

* AUTHOR :    Hitesh Ahire        CREATE DATE :    09 FEB 2023

* CHANGES :

* REF NO         VERSION   DATE         WHO     DETAIL

* PMB-6555       2.6.0     09/02/2023    HA      Ability to rollback PO from cancelled and shipped status to last state by internal admin/support

************************************************************************/
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLS } from '@procurant/env/config.url';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderRollbackService {
  private headers = new HttpHeaders();
  constructor(private http: HttpClient) {
    this.headers.set('Content-Type', 'application/json');
  }

  public searchSuggestByOrgName(searchKey: any): Observable<any> {
    return this.http.get<any>(URLS.SearchSuggestForOrgsAPI + searchKey);
  }

  public getOrderDetails(orgId, orgType, poNumbers): Observable<any> {
    return this.http.get<any>(URLS.GetOrderDetails, {
      params: { orgId: orgId, orgType: orgType, poNumbers: poNumbers }
    });
  }

  public revertOrder(poId : number): Observable<any> {
    return this.http.put<any>(URLS.RevertOrder + poId, null);
  }

  public saveComment(commentJson): Observable<any> {
    return this.http.post(URLS.SaveComment, commentJson); 
  }

  getHeaderDetails(id): Observable<any> {
    let url = URLS.GetPOOrderHeaderDetails + id;
    return this.http.get<any>(url);
  }

  getLineDetailsPaging(req): Observable<any> {
    let url = URLS.GetPOOrderLineDetailsPaging;
    return this.http.post<any>(URLS.GetPOOrderLineDetailsPaging, req, { headers: this.headers });
  }

  getInvoiceDetails(id): Observable<any> {
    let url = URLS.GetInvoiceFormPoId + id;
    return this.http.get<any>(url);
  }
  
  paymenttermDetailsById(id): Observable<any> {
    return this.http.get<any>(URLS.PaymenttermDetailsById + id);
  }
  getLoadData(id): Observable<any> {
    return this.http.get<any>( URLS.GetLoadData + id, { headers: this.headers, observe: 'response' });
  }

  getLineSummary(id): Observable<any> {
    return this.http.get<any>(URLS.GetPOLineSummary + id);
  }

}
