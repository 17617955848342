import { NgModule } from '@angular/core';
import { CommonLibModule, StorageService, ValidatorsService } from 'common-lib';
import { ComponentService } from './services/component.service';
import { DataModalComponent } from './components/data-modal/data-modal.component';
import { NgxUploaderModule } from 'ngx-uploader';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AccountSettingComponent } from '../company-settings/components/account-setting/account-setting.component';
import { ProfilePreviewComponent, DocumentDisplayDialog } from '../company-settings/components/profile-preview/profile-preview.component';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { CommonDialogComponent } from './components/common-dialog/common-dialog.component';
import { CommonSnackbarComponent } from './components/common-snackbar/common-snackbar.component';
import { CommonNotificationComponent } from './components/common-notification/common-notification.component';
import { ProcurantLoadingComponent } from './components/procurant-loading/procurant-loading.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomDatePickerComponent } from './components/custom-date-picker/custom-date-picker.component';
import { ProcurantOverlayDesignComponent } from './components/procurant-overlay-design/procurant-overlay-design.component';
import { ProcurantSelectComponent } from './components/procurant-select/procurant-select.component';


@NgModule({
    imports: [
        CommonLibModule,
        NgxUploaderModule,
        InfiniteScrollModule,
        NotificationModule,
        NgxMatSelectSearchModule,
        FlexLayoutModule
    ],
    declarations: [DataModalComponent,
        ProfilePreviewComponent,
        AccountSettingComponent,
        CommonDialogComponent,
        CommonSnackbarComponent,
        CommonNotificationComponent,
        ProcurantLoadingComponent,
        DocumentDisplayDialog,
        CustomDatePickerComponent,
        ProcurantOverlayDesignComponent,
        ProcurantSelectComponent,
    ],
    providers: [
        StorageService,
        ComponentService,
        ValidatorsService
    ],
    exports: [
        CommonLibModule,
        DataModalComponent,
        NgxUploaderModule,
        InfiniteScrollModule,
        CommonDialogComponent,
        ProcurantLoadingComponent,
        FlexLayoutModule,
        CustomDatePickerComponent,
        ProcurantOverlayDesignComponent,
        ProcurantSelectComponent,
    ]
})
export class SharedModule { }
