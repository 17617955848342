<div class="overlay-header">
    <div mat-dialog-title>
        <ng-content select="[overlay-header]">
        </ng-content>
    </div>
    <div mat-dialog-title>
        <ng-content select="[overlay-close]">
        </ng-content>
    </div>
</div>
<div class="overlay-body">
    <div class="dialog-content">
        <div mat-dialog-content>
            <ng-content select="[overlay-body]">
            </ng-content>
        </div>

    </div>

</div>
<div mat-dialog-actions *ngIf="showFooter" class="overlay-footer">
    <div class="left-section">
        <ng-content select="[overlay-leftFooter]">
        </ng-content>
    </div>
    <div class="right-section">
        <ng-content select="[overlay-rightFooter]">
        </ng-content>
    </div>
</div>