/***********************************************************************
* DESCRIPTION :

*  Ability to rollback PO from cancelled and shipped status to last state by internal admin/support

* NOTES :

* AUTHOR :    Hitesh Ahire        CREATE DATE :    09 FEB 2023

* CHANGES :

* REF NO         VERSION   DATE         WHO     DETAIL

* PMB-6555       2.6.0     09/02/2023    HA      Ability to rollback PO from cancelled and shipped status to last state by internal admin/support

************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DocTypes, TranslateService } from 'common-lib';
import { OrderRollbackService } from '../../service/order-rollback.service';
import { OrderDetailsComponent } from '../order-details/order-details.component';
import { OrderRollbackOverlayComponent } from '../order-rollback-overlay/order-rollback-overlay.component';

@Component({
  selector: 'order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {
  public headList = [
    this.translate.instant('ORDER.LABEL.ORDER_NO'),
    this.translate.instant('LABEL.SALES_ORDER'),
    this.translate.instant('LABEL.STATUS'),
    this.translate.instant('LABEL.BUYER'),
    this.translate.instant('LABEL.SUPPLIER'),
    this.translate.instant('DETAIL_PAGE.LABEL.INVOICE_NUMBER'),
    this.translate.instant('DETAIL_PAGE.LABEL.INVOICE_STATUS'),
    this.translate.instant('ORDER.LABEL.DELIVER_TO'),
    this.translate.instant('PRICING.LABEL.SHIP_FROM'),
    this.translate.instant('LABEL.DELIVERY_DATE'),
    this.translate.instant('LABEL.SHIP_DATE'),
    this.translate.instant('LABEL.ROUTING'),
    this.translate.instant('ORDER.LABEL.ACTION')
  ];
  @Input("orderList") orderList;
  docType_PO = DocTypes.INTERNAL_ADMIN;
  constructor(private dialog: MatDialog, private orderRollbackService: OrderRollbackService, private translate: TranslateService) { }
  private commentJson = {
    id: "",
    trxDocId: 0,
    trxDocTypeCode: DocTypes.INTERNAL_ADMIN,
    comment: "",
    locale: "en",
  };
  ngOnInit(): void {
  }

  public revertOrder(order) {
    this.dialog.open(OrderRollbackOverlayComponent, {
      width: '550px',
      height: 'auto',
      disableClose: true,
      data: { poNumber: order.poNumber, isOkButton: false }
    }).afterClosed().subscribe(res => {
      if (res.action == 'yes') {
        this.callReverOrderApi(order);
        this.saveCommentApi(order.poId, res.posNoComment);
        order.isCommentsAvailable = true;
      }
    });
  }

  public openComment() {
    this.dialog.open(OrderRollbackOverlayComponent, {
      width: '550px',
      height: 'auto',
      disableClose: true,

    }).afterClosed()
  }
  private saveCommentApi(poId, comment) {
    this.commentJson.comment = comment;
    this.commentJson.trxDocId = poId;
    this.orderRollbackService.saveComment(this.commentJson).subscribe();
  }
  private callReverOrderApi(order) {
    return new Promise((resolve) => {
      this.orderRollbackService.revertOrder(order.poId).subscribe(res => {
        resolve(res);
      });
    }).then(res => {
      order.status = res['status'];
      order.invoiceStatus = res['invoiceStatus'];
      this.dialog.open(OrderRollbackOverlayComponent, {
        width: '550px',
        height: 'auto',
        disableClose: true,
        data: { poNumber: res['poNumber'], isOkButton: true }
      }).afterClosed();
    });
  }
  public openOrderDetails(poId) {
    this.dialog.open(OrderDetailsComponent, {
      width: '1300px',
      height: '600px',
      disableClose: true,
      data: { poId: poId }
    }).afterClosed();
  }
}
