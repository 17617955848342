/***********************************************************************
* DESCRIPTION :

*  Ability to rollback PO from cancelled and shipped status to last state by internal admin/support

* NOTES :

* AUTHOR :    Hitesh Ahire        CREATE DATE :    09 FEB 2023

* CHANGES :

* REF NO         VERSION   DATE         WHO     DETAIL

* PMB-6555       2.6.0     09/02/2023    HA      Ability to rollback PO from cancelled and shipped status to last state by internal admin/support

************************************************************************/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "@procurant/sharedcomponent/shared-components.module";
import { AppToolbarsModule } from "../app-toolbars/app-toolbars.module";

import { OrderRollbackRoutingModule } from './order-rollback-routing.module';
import { OrderRollbackContainerComponent } from './components/order-rollback-container/order-rollback-container.component';
import { OrderListComponent } from './components/order-list/order-list.component';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { OrderRollbackOverlayComponent } from './components/order-rollback-overlay/order-rollback-overlay.component';
import { OrderDetailsComponent } from './components/order-details/order-details.component';

@NgModule({
  declarations: [
    OrderRollbackContainerComponent,
    OrderListComponent,
    OrderRollbackOverlayComponent,
    OrderDetailsComponent
  ],
  imports: [
    CommonModule,
    OrderRollbackRoutingModule,
    SharedModule,
    AppToolbarsModule,
    LayoutModule,
  ]
})
export class OrderRollbackModule { }
