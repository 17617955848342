<content-top-toolbar [hidden]="hidden">
  <div breadcrumb>
    <div class="pageInfo">
      <div fxLayout="row" fxLayoutAlign="start end" class="baselineAlignment">
        <h2 class="pageTitle">{{this.pageTitle}}</h2>
      </div>
      <!-- <div style="width: 100%;padding: 0px;">{{ pageTitle }}</div> -->
      <ol id="breadcrumbs-one" class="breadcrumb">
        <li>
          <a routerLink="/company">Home</a>
        </li>
        <li *ngFor="let breadcrumb of breadcrumbs; let i = index;">
          <!-- <a [hidden]="i == (breadcrumbs.length - 1)">{{breadcrumb.name}}</a> -->
          <a routerLink="{{breadcrumb.url}}">
            <b>{{breadcrumb.name | translate}}</b>
          </a>
        </li>
      </ol>
    </div>
  </div>
</content-top-toolbar>