import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { ValidatorsService, SharedUtilService, TranslateService } from 'common-lib';
import { CoreService } from '../../services/core.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  user: any;
  userData: any;
  showEmailConformPage = false;

  constructor(private _Activatedroute: ActivatedRoute, private fb: FormBuilder, private coreService: CoreService, private router: Router, private sharedUtilService: SharedUtilService, private translate: TranslateService) {
    this.user = fb.group({
      userName: ['', Validators.compose([
        Validators.required,
        Validators.pattern(ValidatorsService.emailPattern)
      ])],
    });
  }

  ngOnInit() {
  }

  create() {
    let id = this.user.get('userName').value
    let reqData =
    {
      "user": {
        "fname": null,
        "email": id,
        "password": null
      },
      "token": null,
      "tokenStatus": null
    }
    this.coreService.forgotPassword(reqData).subscribe(
      (data) => {
        if (data) {
          this.showEmailConformPage = true;
        } else {
        }
      },
      error => {
        if (error.status === 400) {
          // this.sharedUtilService.showSnackBarMessage(error.headers.get('Error'));
          this.user.get('userName').setErrors({ 'invalidUser': true });
        }
      });
  }

  done() {
    this.router.navigate(['login']);
  }

}
