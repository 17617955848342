import { MaterialUIModule } from './../material-ui/material-ui.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevLoginComponent } from './components/dev-login/dev-login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@procurant/sharedcomponent/shared-components.module';


@NgModule({
    declarations: [DevLoginComponent],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialUIModule,
        SharedModule,
    ],
    providers: [
    //DevLoginService
    ],
    exports: []
})
export class DevLoginModule { }
