import { Injectable } from '@angular/core';
import { OneAuthService } from "one-auth";

@Injectable({
  providedIn: 'root'
})
export class InitLoaderService {
  constructor(private auth: OneAuthService){

  }
  load(){
      this.auth.LoadInitInfo();
  }
}
