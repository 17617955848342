import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { ValidatorsService, SharedUtilService, TranslateService } from 'common-lib';
import { CoreService } from '../../services/core.service';
import { OneNotifyService } from 'one-auth';


@Component({
  selector: 'app-activate-user',
  templateUrl: './activate-user.component.html',
  styleUrls: ['./activate-user.component.scss']
})
export class ActivateUserComponent implements OnInit {
  user: any;
  isPasswordMatch: boolean;
  token: any;
  userData: any;
  showResetPasswordPage = false;

  
  constructor(public notify: OneNotifyService,private _Activatedroute: ActivatedRoute, private fb: FormBuilder, private coreService: CoreService, private router: Router, private sharedUtilService: SharedUtilService, private translate: TranslateService) {
    let route = this.router.routerState.snapshot.url.split('/');
    let page = route[route.length - 1];
    if (page.includes("reset-password")) {
      this.showResetPasswordPage = true;
    }
    this.user = fb.group({
      fname: [''],
      lname: [''],
      countryCode: [''],
      phone: [''],
      email: [''],
      password: ['', Validators.compose([
        Validators.minLength(8),
        Validators.required,
        Validators.pattern(ValidatorsService.passwordPattern)
      ])],
      confirmPassword: ['', Validators.compose([Validators.required])],
      termAndCondition: [''],
      token: [''],
    });
  }

  ngOnInit() {
    this.userData = { "fname": "", "email": "" }
    this._Activatedroute.queryParams.subscribe(params => {
      this.token = params["token"];
      this.getUserByToken();
    });
  }

  getUserByToken() {
    this.coreService.getUserByToken(this.token).subscribe(
      (res) => {
        this.userData = res;
        if (!this.userData) {
          //this.sharedUtilService.showSnackBarMessage(this.translate.instant("LOGIN.MESSAGE.PASSWORD_LINK_ERROR"));
          this.notify.show(this.translate.instant("LOGIN.MESSAGE.PASSWORD_LINK_ERROR"),'error');
          this.redirectToLogin();
        } else {
          this.user.controls['email'].setValue(this.userData.email);
          this.user.controls['token'].setValue(this.token);
        }
      },
      error => {
        if (error.status === 400) {
          //this.sharedUtilService.showSnackBarMessage(error.headers.get('Error'));
          this.notify.show(error.headers.get('Error'),'error');
          this.redirectToLogin();
        }
      });
  }

  redirectToLogin() {
    this.router.navigate(['login']);
  }

  areMatch() {
    let confirmPass = this.user.get('confirmPassword').value;
    let pass = this.user.get('password').value;
    if (confirmPass && pass) {
      if (confirmPass != pass) {
        this.isPasswordMatch = true;
      } else {
        this.isPasswordMatch = false;
      }
    } else {
      this.isPasswordMatch = false;
    }
  }

  create() {
    this.userData.password = btoa(this.user.get('password').value);
    let resData = { user: this.userData, token: this.token }
    this.coreService.updateUserPassword(resData).subscribe(
      (data) => {
        if (data) {
          //this.sharedUtilService.showSnackBarMessage(this.translate.instant("LOGIN.MESSAGE.PASSWORD_SUCCESS"));
          this.notify.show(this.translate.instant("LOGIN.MESSAGE.PASSWORD_SUCCESS"));
          this.redirectToLogin();
        } else {
          //this.sharedUtilService.showSnackBarMessage(this.translate.instant("LOGIN.MESSAGE.PASSWORD_LINK_ERROR"));
          this.notify.show(this.translate.instant("LOGIN.MESSAGE.PASSWORD_LINK_ERROR"),'error');
          this.redirectToLogin();
        }
      }, err => {
        //this.sharedUtilService.showSnackBarMessage(err.error.message)
        this.notify.show(err.error.message,'error');
      })
  }

  resetUser() {
    this.user.get('password').reset();
    this.user.get('confirmPassword').reset();
  }

}
