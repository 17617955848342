import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'common-lib';
import { Observable } from 'rxjs';
import { URLS } from '@procurant/env/config.url';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class IntegrationPartnerProfileService {


  private headers = new HttpHeaders();

  constructor(private http: HttpClient, private $http: HttpService) {
      this.headers.set('Content-Type', 'application/json');
  }

  getDocumentType(param): Observable<any> {
      let url = URLS.GetDocumentType+'/'+param;
      return this.http.get<any>(url)
  }
  getPendingDocumentType(organizationId,roleCode): Observable<any> {
    let url = URLS.GetDocumentTypeByOrgId+'/'+organizationId+'/'+roleCode;
    return this.http.get<any>(url)
}
  getTriggerEvents(docTypeCode, direction): Observable<any> {
      let url = URLS.GetTriggerEvents + docTypeCode + '/' + direction;
      return this.http.get<any>(url)
  }
  getOrg(...param): Observable<any> {
      let url = URLS.GetOrganizationsByAppCode + param[0];
      return this.http.get<any>(url)
  }
  getBusinessRules(...param): Observable<any> {
      let url = URLS.GetBussinessRules + param[0] + '/' + param[1] + '/' + param[2];
      return this.http.get<any>(url)
  }
  setupTradingPartner(...param): Observable<any> {
      let url = URLS.SetupTradingPartner;
      return this.http.post(url, param[0], { headers: this.headers, responseType: 'text' })
  }
  addNewDocumentForTradingPartner(...param): Observable<any> {
    let url = URLS.AddNewDocumentForTradingPartner;
    return this.http.post(url, param[0], { headers: this.headers, responseType: 'text' })
}
  
  getPartnersList(pageNumber?, pageSize?, sortColumn?, sortOrder?, roleCode?, organizationId?): Observable<any> {
      let url = URLS.FetchPartnerProfileList;
      let listRequest: any;
      listRequest = {
          "roleCode": roleCode,
          "pageNumber": pageNumber,
          "pageSize": pageSize,
          "sortColumn": sortColumn,
          "sortOrder": sortOrder,
          "organizationId": organizationId
      }
      return this.http.post(url, listRequest, { headers: this.headers })
  }

  getPartnerProfile(id): Observable<any> {
    let url = URLS.FetchPartnerProfile + id;
    return this.http.get<any>(url);
}

getPartnerProfileData(id, roleCode): Observable<any> {
    let url = URLS.FetchPartnerProfileData + id +'/'+ roleCode;
    return this.http.get<any>(url);
}

updateTradingPartner(...param): Observable<any> {
    let url = URLS.UpdateTradingPartner;
    //return this.http.put(url, param[0], { headers: this.headers, responseType: 'text' })
    return this.http.post(url, param[0], { headers: this.headers, responseType: 'text' })
}

  extractData(response) {
      return response.body;
  }

  getTradingPartnerList(orgId,  partnerApplicationCode): Observable<any> {
    let url = URLS.AvailableTradingPartnerAPI + orgId + "/" + partnerApplicationCode;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }));
  }
}
