<div class="form-field-select" [formGroup]="parentForm">
    <mat-form-field appearance="outline" class="searchBy">
        <mat-select #select="matSelect" id="{{idText==''?field :field +'-'+ idText}}" [disabled]="isDisabled"
        kendoTooltip title="{{toolTipText}}" placeholder="{{placeholder}}" (openedChange)="openedChange($event)"
            formControlName="{{field}}" [multiple]="isMultiple" (selectionChange)="selectionChange($event)"
            disableOptionCentering>
            <!-- <mat-select-trigger>
                {{parentForm.get(field)?.value ? parentForm.get(field)?.value[0] : ''}}
                <span *ngIf="parentForm.get(field)?.value?.length > 1" class="additional-selection">
                    (+{{parentForm.get(field)?.value.length - 1}}
                    {{parentForm.get(field)?.value?.length === 2 ? 'other' : 'others'}})
                </span>
            </mat-select-trigger> -->
            <div class="select-container">
                <mat-optgroup *ngIf="showSearch">
                    <mat-form-field style="width: 100%">
                        <input #search autocomplete="off" placeholder="{{searchText}}" aria-label="Search" matInput
                            [formControl]="searchTextboxControl" />
                        <button [disableRipple]="true" *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear"
                            (click)="clearSearch($event)">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </mat-optgroup>
                <mat-optgroup class="link-number" *ngIf="isMultiple && showSelectAll">
                    <a *ngIf="filteredOptions?.length != parentForm.get(field)?.value?.length"
                        (click)="selectAll()">{{selectAllText}}</a>
                    <a class="link-number" (click)="clearSelection()">{{clearSelectionText}}</a>
                </mat-optgroup>
                <mat-optgroup *ngIf="filteredOptions?.length == 0">
                    <div>{{noDataMessage}}</div>
                </mat-optgroup>
                <mat-option *ngFor="let option of filteredOptions"
                    [disabled]="(option[disabledField] && option[disabledField]==true)? true : false"
                    [value]="option[valueField]">
                    {{option[textField]}}
                </mat-option>
            </div>
        </mat-select>
    </mat-form-field>
</div>