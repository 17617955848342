import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, ActivatedRouteSnapshot, UrlSegment, NavigationEnd, PRIMARY_OUTLET, RoutesRecognized } from '@angular/router';
import { OneAuthService } from 'one-auth';
import { TranslateService } from 'common-lib';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'content-infobar',
  templateUrl: './content-infobar.component.html',
  styleUrls: ['./content-infobar.component.scss']
})
export class ContentInfobarComponent implements OnInit {

  @Input()
  pageTitle: string;
  @Input()
  hidden: boolean = false;
  public breadcrumbs: { name: string; url: string }[] = [];
  constructor(
    public router: Router, public translate: TranslateService,
    public activatedRoute: ActivatedRoute) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.breadcrumbs = [];
        this.parseRoute(this.router.routerState.snapshot.root);
      }
    })
  }
  parseRoute(node: ActivatedRouteSnapshot) {
    if (node.data['breadcrumb']) {
      if (node.url.length) {
        let urlSegments: UrlSegment[] = [];
        node.pathFromRoot.forEach(routerState => {
          urlSegments = urlSegments.concat(routerState.url);
        });
        const url = urlSegments.map(urlSegment => {
          return urlSegment.path;
        }).join('/');
        this.breadcrumbs.push({
          name: node.data['breadcrumb'],
          url: '/' + url
        })
        this.pageTitle = '';
        this.pageTitle = this.translate.instant(node.data['breadcrumb']);
      }
    }
    if (node.firstChild) {
      this.parseRoute(node.firstChild);
    }
  }
  setBreadCrum(node, url) {
    this.breadcrumbs.push({
      name: node.data['breadcrumb'],
      url: '/' + url
    })
  }

  ngOnInit() {
  }
}
