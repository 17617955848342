import { Injectable } from '@angular/core';

import { environment } from '@procurant/env/environment.blue';
import { Globals } from 'common-lib';
// import { environment } from 'src/environments/environment';

@Injectable()
export class IGlobals implements Globals {
    // BASE_API_URL = environment.BASE_API_URL; //Global.BASE_URL;
    BASE_API_URL = environment.BASE_API_URL;
}