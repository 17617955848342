import { RedirectGuard } from './services/redirect-guard';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { CommonLibModule, HttpService, TRANSLATION_PROVIDERS } from 'common-lib';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router } from '@angular/router';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { SharedModule } from '@procurant/sharedcomponent/shared-components.module';
import { IGlobals } from '@procurant/sharedcomponent/services/globals.service';
import { BusinessUnitComponent } from './modules/business-unit/components/create-business-unit/create-business-unit.component';
import { BusinessUnitListComponent } from './modules/business-unit/components/business-unit-list/business-unit-list.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BusinessRulesComponent } from './modules/business-rules/components/business-rules/business-rules.component';
//import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { FileSaverModule } from 'ngx-filesaver';
import { environment } from '@procurant/env/environment';
import { ProductEditComponent } from './modules/product-catalog/components/product-edit-overlay/product-edit.component';
//import { RegistrationComponent } from './modules/core/components/registration/registration.component';
import { CoreModule } from './modules/core/core.module';
import { HttpRequestInterceptor } from './services/http.interceptor';
import { OneAuthService, OneAuthModule, OneNotifyService } from 'one-auth';
import { AppRoutingModule } from './app-routing.module';
import { DevLoginModule } from './modules/dev-login/dev-login.module';
import { AppToolbarsModule } from './modules/app-toolbars/app-toolbars.module';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { AuthGuard } from './services/auth.guard';
import { InitLoaderService } from './services/init-loader.service';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { GridModule } from '@progress/kendo-angular-grid';
import { AddNewDocumentsOverlayComponent } from './modules/integration-partner-profile/components/add-new-documents-overlay/add-new-documents-overlay.component';
import { EditorModule } from '@progress/kendo-angular-editor';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TooltipsModule } from "@progress/kendo-angular-tooltip";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";


export function httpServiceFactory(http: HttpClient): HttpService {
  return new HttpService(http, new IGlobals());
}

// export let HttpServiceProvider =
// {
//   provide: HttpService,
//   useFactory: httpServiceFactory,
//   deps: [HttpClient]
// };

@NgModule({
    declarations: [
        AppComponent,
        ProductEditComponent,
        AddNewDocumentsOverlayComponent
    ],
    imports: [
        BrowserModule,
        CommonLibModule.forRoot(environment),
        BrowserAnimationsModule,
        HttpClientModule,
        SharedModule,
        CoreModule,
        InfiniteScrollModule,
        //Ng4LoadingSpinnerModule.forRoot(),
        FileSaverModule,
        FileSaverModule,
        AppRoutingModule,
        DevLoginModule,
        AppToolbarsModule,
        OneAuthModule.forRoot(environment),
        NotificationModule,
        GridModule,
        EditorModule,
        FlexLayoutModule,
        TooltipsModule,
        ButtonsModule,
        DateInputsModule
    ],
    exports: [],
    providers: [CurrencyPipe, DecimalPipe, TRANSLATION_PROVIDERS,
        // AuthGuard,
        OneAuthService,
        OneNotifyService,
        InitLoaderService,
        HttpRequestInterceptor,
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true
            }
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true
        },
        RedirectGuard,
        AuthGuard,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }