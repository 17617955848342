<div class="head">
  <div class="title">Order Details</div>
  <div><mat-icon (click)="close()">close</mat-icon></div>
</div>
<div class="main-content" id="PrintDataId" *ngIf="printData">
  <div class="pageInfo header">
    <div fxLayout="row" fxLayoutAlign="start top" class="infoBlocks mb-10">
      <div fxFlex="30" class="readOnlyField rBorder">
        <div class="fieldGroup horizontalGroup" style="align-items: center;display: flex;">
          <span class="fieldLabel">{{'DETAIL_PAGE.LABEL.ORDER_NO' | translate}} :</span>
          <span id="poNumber" class="fieldValuePrint mr-10">{{printData?.orderDetailForm?.poNumber}}</span>
          <span id="status">
            <mat-icon *ngIf="printData?.orderDetailForm?.crossDockLocName"
              matTooltip="{{ 'ORDER.LABEL.CROSS_DOCK_ORDER' | translate }}" class="vAlignTop green">local_shipping
            </mat-icon>
          </span>
        </div>
        <div *ngIf="printData?.orderDetailForm?.status != 'Draft'" class="fieldGroup horizontalGroup  info-field mt-10">
          <span class="fieldLabel">{{'DETAIL_PAGE.LABEL.SALE_ORDER_NO' | translate}} : </span>
          <span id="saleOrderNumber" *ngIf="!isMultipleSoNumber"
            class="fieldValuePrint">{{printData?.orderDetailForm?.poLines[0]?.soNumber}}</span>
          <span id="saleOrderNumber" *ngIf="isMultipleSoNumber" class="fieldValuePrint">{{'LABEL.MULTIPLE'
            | translate}}</span>
        </div>
        <div class="fieldGroup horizontalGroup info-field mt-10">
          <span class="fieldLabel">{{'CATALOG.COLUMN.STATUS' | translate}} : </span>
          <span class="fieldValuePrint">
            {{printData?.orderDetailForm?.status}}
            <ng-container
              *ngIf="printData?.orderDetailForm?.crossDockLocName&&printData?.crossDockStatus==0&&(printData?.orderDetailForm?.status==statusShipped || printData?.orderDetailForm?.status==statusShippedModified)">
              &nbsp;{{'ORDER.LABEL.SHIPPED_TO_CD' | translate}}
            </ng-container>
          </span>
        </div>

      </div>
      <div fxFlex="25" class="readOnlyField rBorder">
        <div class="fieldGroup horizontalGroup">
          <span class="fieldLabel">{{'DETAIL_PAGE.LABEL.SUPPLIER' | translate}} : </span>
          <span id="sellerCompanyName"
            class="fieldValuePrint mr-15">{{printData?.orderDetailForm?.sellerCompanyName}}</span>
          <span class="offline-vendor" *ngIf="printData?.orderDetailForm?.offline === true"
            matTooltip="{{'DETAIL_PAGE.LABEL.OFFLINE_SUPPLIER' | translate}}">OFL</span>
        </div>

        <div class="fieldGroup horizontalGroup mt-10" *ngIf="printData?.orderDetailForm?.supplierCode">
          <span class="fieldLabel">{{'DETAIL_PAGE.LABEL.SUPPLIER_CODE' | translate}} : </span>
          <span id="supplierCode" class="fieldValuePrint">{{printData?.orderDetailForm?.supplierCode}}</span>
        </div>
        <div class="fieldGroup horizontalGroup info-field mt-10">
          <span class="fieldLabel">{{'DETAIL_PAGE.LABEL.ROUTING' | translate}} : </span>
          <span id="poNumber" class="fieldValuePrint mr-10">
            {{printData?.orderDetailForm?.freightTerm}}
          </span>
        </div>
      </div>
      <div fxFlex="20" class="readOnlyField rBorder">
        <div class="fieldGroup horizontalGroup info-field">
          <span class="fieldLabel">{{'DETAIL_PAGE.LABEL.SHIP_FROM' | translate}} : </span>
          <span class="fieldValuePrint"
            *ngIf="printData?.shipFromLocId != 0">{{printData?.orderDetailForm?.poLines[0]?.shipFromLocName}}</span>
          <span *ngIf="printData?.shipFromLocId == 0" class="fieldValuePrint" >{{'LABEL.MULTIPLE' | translate}}</span>
        </div>
        <div class="fieldGroup">
          <div class="dateRange horizontalGroup info-field mt-10">
            <span class="fieldLabel">{{'DETAIL_PAGE.LABEL.SHIP_DATE' | translate}} : </span>
            <span class="fieldValuePrint"
              *ngIf="printData?.orderDetailForm?.shipDate ">{{printData?.orderDetailForm?.shipDate
              |pipes:'date'}}</span>
            <span class="fieldValuePrint"
              *ngIf="!printData?.isMultipleShipDate">{{printData?.multipleShipDateRange}}</span>
          </div>
        </div>

        <div class="fieldGroup" *ngIf="printData?.orderDetailForm?.crossDockLocName">
          <div class="dateRange horizontalGroup info-field mt-10">
            <div class="cross-dock-label-break">
              <span class="fieldLabel">{{'TABLE_DISPLAY_COLUMNS.CROSS_DOCK' |translate}}&nbsp;&nbsp;</span>
              <span class="fieldLabel">{{'TABLE_DISPLAY_COLUMNS.FACILITY' |translate}} : </span>
            </div>
            <span id="crossDockLocName"
              class="fieldValuePrint mr-15">{{printData?.orderDetailForm?.crossDockLocName}}</span>
          </div>
        </div>

      </div>
      <div fxFlex="20" class="readOnlyField pd-0 mr-0">
        <div class="fieldGroup horizontalGroup info-field">
          <span class="fieldLabel">{{'DETAIL_PAGE.LABEL.DELIVER_TO' | translate}} : </span>

          <span class="fieldValuePrint">{{printData?.orderDetailForm?.deliverToLocName}}</span>
        </div>
        <div class="fieldGroup">
          <div class="dateRange horizontalGroup info-field mt-10">
            <span class="fieldLabel">{{'DETAIL_PAGE.LABEL.DELIVERY_DATE'|translate}} : </span>
            <span class="fieldValuePrint">{{printData?.orderDetailForm?.deliveryDate|pipes:'date' }}</span>
          </div>
        </div>

      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" class="infoBlocks mb-10">
      <div fxFlex="35" class="readOnlyField rBorder">
        <div class="fieldGroup horizontalGroup">
          <div fxLayout="row" fxLayoutAlign="start center" class="imgLabel">
            <span class="greenColor icon-payments2"></span>
            <span>{{ 'DETAIL_PAGE.LABEL.PAYMENTS' | translate }}</span>
          </div>
        </div>
        <div class="fieldGroup horizontalGroup">
          <span class="fieldLabel">{{ 'DETAIL_PAGE.LABEL.INVOICE' | translate }} :</span>
          <span id="invoiceNumber" class="fieldValuePrint">
            <span class="fieldValuePrint"
              *ngIf="printData?.orderDetailForm?.invoiceNumber">{{printData?.orderDetailForm?.invoiceNumber}}</span>
            <span class="fieldValuePrint" *ngIf="!printData?.orderDetailForm?.invoiceNumber">NA</span>
          </span>
        </div>
        <div class="fieldGroup horizontalGroup mt-10">
          <span class="fieldLabel">{{ 'DETAIL_PAGE.LABEL.TERMS' | translate }} :</span>
          <span id="paymentTermName"
            class="fieldValuePrint">{{printData?.orderDetailForm?.paymentTermName?printData?.orderDetailForm?.paymentTermName:'NA'}}</span>
        </div>
      </div>
      <div fxFlex="25" class="readOnlyField rBorder">
        <div class="fieldGroup horizontalGroup">
          <div fxLayout="row" fxLayoutAlign="start center" class="imgLabel">
            <span class="greenColor icon-transportation"></span>
            <span>{{ 'DETAIL_PAGE.LABEL.TRANSPORTATION' | translate }}</span>
          </div>
        </div>
        <div class="fieldGroup horizontalGroup">
          <span class="fieldLabel">{{ 'DETAIL_PAGE.LABEL.LOAD' | translate }} # :</span>
          <span class="fieldValuePrint">
            {{printData?.orderDetailForm?.loadNumber}}
          </span>
        </div>
        <div class="fieldGroup horizontalGroup mt-10">
          <span class="fieldLabel">{{ 'DETAIL_PAGE.LABEL.CARRIER' | translate }} :</span>
          <span id="carrierName" class="fieldValuePrint">{{printData?.orderDetailForm?.carrierName}}</span>
        </div>
      </div>
      <div fxFlex="20" class="readOnlyField rBorder">
        <div class="fieldGroup horizontalGroup">
          <div fxLayout="row" fxLayoutAlign="start center" class="imgLabel">
            <span class=" greenColor icon-demand"></span>
            <span>{{ 'DETAIL_PAGE.LABEL.DEMAND' | translate }}</span>
          </div>
        </div>
        <div class="fieldGroup horizontalGroup">
          <span class="fieldLabel">{{ 'DETAIL_PAGE.LABEL.DEMAND_NO' | translate }} :</span>
          <span class="fieldValue"
            *ngIf="printData?.orderDetailForm?.demandNo">{{printData?.orderDetailForm?.demandNo}}</span>
          <span id="carrierName" class="fieldValue" *ngIf="!printData?.orderDetailForm?.demandNo">NA</span>
        </div>
        <div class="fieldGroup">&nbsp;</div>
      </div>
      <!-- <div fxFlex="20" class="readOnlyField" *ngIf="!printData?.appModuleAccess?.doNotAggregateDemand">
        <div class="fieldGroup">&nbsp;</div>
        <div class="fieldGroup">&nbsp;</div>
        <div class="fieldGroup">&nbsp;</div>
      </div> -->
      <div fxFlex="20" class="readOnlyField">
        <div class="fieldGroup">&nbsp;</div>
        <div class="fieldGroup">&nbsp;</div>
        <div class="fieldGroup">&nbsp;</div>
      </div>
      <div *ngIf=false class="readOnlyField">
        <div class="fieldGroup horizontalGroup">
          <div fxLayout="row" fxLayoutAlign="start center" class="imgLabel">
            <span class="icon-other"></span>
            <span>{{ 'DETAIL_PAGE.LABEL.OTHER' | translate }}</span>
          </div>
          <span class="fieldLabel">{{ 'DETAIL_PAGE.LABEL.CUSTOMER_ORDER' | translate }} :</span>
          <span class="fieldValuePrint"> </span>
        </div>
        <div class="fieldGroup horizontalGroup">
          <span class="fieldLabel">{{ 'DETAIL_PAGE.LABEL.DATE_CODE' | translate }} :</span>
          <span class="fieldValuePrint"></span>
        </div>
      </div>
    </div>
  </div>

  <div class="pageInfo tableContainer">
    <div class="responsiveTable">
      <table class="table customTable">
        <tr>
          <th class="textCenter" width="25px">{{ 'DETAIL_PAGE.LABEL.SR_NO' | translate }}</th>
          <th width="150px">{{'DETAIL_PAGE.LABEL.PRODUCT_CODE' | translate}}</th>
          <th width="200px">{{'DETAIL_PAGE.LABEL.DESCRIPTION' | translate}}</th>
          <th class="textRight"> {{'DETAIL_PAGE.LABEL.QUANTITY' | translate}}</th>
          <th class="textRight" width="80px" [hidden]="printData?.isRecievedQtyHidden">
            {{'DETAIL_PAGE.LABEL.RECEIVED_QTY' | translate}}</th>
          <th class="textRight"> {{'TABLE_DISPLAY_COLUMNS.COST' | translate}}
          </th>
          <th class="textRight">{{'DETAIL_PAGE.LABEL.TOTAL_COST' | translate}}</th>
        </tr>
        <tbody *ngFor="let row of printData?.orderDetailForm?.poLines; let i = index">
          <tr [ngClass]="{'disabled-lineTr': row?.statusCheck==statusCancelled}">
            <td class="textCenter">{{i+1}}</td>
            <td>{{row?.sku}}
              <div class="cancelLine" *ngIf="row?.statusCheck==statusCancelled">
                {{'LABEL.CANCELLED' | translate}}</div>
            </td>
            <td class="textLeft">
              <div class="prod-desc">
                <div class="desc">{{row?.productDesc}}</div>
                <div class="bal">
                  <mat-icon class="vAlignTop balance-logo"><span class="icon-bold greenColor fs-20"></span>
                  </mat-icon>
                </div>
              </div>
            </td>
            <td class="textRight multiple-input">
              <div class="modified-value">
                <!-- <div class="color-gray">{{row.quantityModFlag>PO_MODFLAGS.NOMODIFICATION?row.previousQty:''}}</div> -->
                <div>
                  {{row?.qty}}
                </div>
              </div>
              <div *ngIf="printData?.orderDetailForm?.status != 'Draft'" class="color-gray">
                (Org. {{row?.originalQty}})
              </div>
            </td>
            <td class="textRight" [hidden]="printData?.isRecievedQtyHidden">
              {{row?.receivedQty}}
            </td>
            <td class="textRight multiple-input">
              <div class="modified-value">
                <div>
                  {{ 'TABLE_DISPLAY_COLUMNS.SYMBOL' | pipes:'currancySymbol' }}{{ row?.unitPrice | pipes:'twoDecimal'}}
                </div>
              </div>
              <div *ngIf="printData?.orderDetailForm?.status != 'Draft'" class="color-gray">
                (Org. ${{row?.originalPrice | pipes:'twoDecimal'}})
              </div>
            </td>

            <td class="textRight">
              <span>{{ 'TABLE_DISPLAY_COLUMNS.SYMBOL' | pipes:'currancySymbol' }}</span>{{ row?.totalAmount |
              pipes:'twoDecimal'}}
            </td>
          </tr>
          <tr>
            <td [attr.colspan]="printData?.isRecievedQtyHidden ? '6' : '7'"
              [ngClass]="{'disabled-Tr': row.statusCheck==statusCancelled}">
              <div class="price-flex">
                <div class="price-label"> {{ 'DETAIL_PAGE.LABEL.PRICES' | translate }}
                </div>
                <div class="price-values">
                  <div class="price-item" *ngIf="printData?.appModuleAccess?.finalCost">
                    <div class="fieldGroup finalUnitCost">
                      <span class="fieldLabel">{{ 'DETAIL_PAGE.LABEL.FINAL_COST' | translate }} : </span>
                      <span class="fieldValuePrint">{{
                        row.finalUnitCost|currency:auth?.user?.info?.currancy:'symbol':'2.2-4' }}</span>
                    </div>
                  </div>
                  <div class="price-item" *ngIf="printData?.appModuleAccess?.variance1">
                    <div class="fieldGroup">
                      <span class="fieldLabel">{{ 'DETAIL_PAGE.LABEL.VARIANCE' | translate }} : </span>
                      <span class="fieldValuePrint" [style.color]="row.buyerVariance<0?'red':'black'">
                        {{'TABLE_DISPLAY_COLUMNS.SYMBOL' | pipes:'currancySymbol'}}{{(row.finalUnitCost > 0)
                        ?(row.buyerVariance| pipes:'twoDecimal') :0}}
                      </span>
                    </div>
                  </div>
                  <div class="price-item" *ngIf="row.totalLineCharges && row.totalLineCharges!==0">
                    <div class="fieldGroup">
                      <span class="fieldLabel">{{ 'DETAIL_PAGE.LABEL.TOTAL_LINE_CHARGE' | translate }} : </span>
                      <span class="fieldValuePrint">
                        <span>{{ 'TABLE_DISPLAY_COLUMNS.SYMBOL' | pipes:'currancySymbol' }}</span>
                        {{row.totalLineCharges | pipes:'twoDecimal'}}
                      </span>
                    </div>
                  </div>
                  <div class="price-item">
                    <div class="fieldGroup">
                      <span class="fieldLabel">{{ 'DETAIL_PAGE.LABEL.LANDED_COST' | translate }} : </span>
                      <span class="fieldValuePrint">
                        <span>{{ 'TABLE_DISPLAY_COLUMNS.SYMBOL' | pipes:'currancySymbol' }}</span>{{ row.buyerLCPerUnit|
                        pipes:'twoDecimal'}}</span>
                    </div>
                  </div>
                  <div class="price-item">
                    <div class="fieldGroup">
                      <span class="fieldLabel">{{ 'DETAIL_PAGE.LABEL.TOTAL_LC' | translate }} : </span>
                      <span id="buyerLC{{i}}" class="fieldValuePrint"><span>{{ 'TABLE_DISPLAY_COLUMNS.SYMBOL' |
                          pipes:'currancySymbol' }}</span>{{row.buyerLC| pipes:'twoDecimal'}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td [attr.colspan]="printData?.isRecievedQtyHidden ? '6' : '7'">
              <div class="price-flex">
                <div class="price-label"> {{ 'DETAIL_PAGE.LABEL.DEMAND' | translate }}
                </div>
                <div class="price-values">
                  <div class="price-item">
                    <div class="fieldGroup finalUnitCost">
                      <span class="fieldLabel">{{ 'DETAIL_PAGE.LABEL.DEMAND_LINE_NUMBER' | translate }} : </span>
                      <span class="fieldValuePrint">{{(!row.demandLineNo || row.demandLineNo==null ||
                        row.demandLineNo=='')?'NA':row.demandLineNo}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td [attr.colspan]="printData?.isRecievedQtyHidden ? '6' : '7'">
              <div class="trace-flex">
                <!-- [ngClass]="{'disabled-Tr': row.statusCheck==statusCancelled}" -->
                <div class="trace-label"> {{ 'DETAIL_PAGE.LABEL.TRACE' | translate }}</div>
                <div class="trace-values">
                  <div class="trace-item">
                    <div class="fieldGroup">
                      <span class="fieldLabel">{{ 'DETAIL_PAGE.LABEL.ORIGIN' | translate }} : </span>
                      <span id="poLineProductCoo{{i}}" class="fieldValuePrint" style="white-space: normal;"
                        *ngIf="!row.poLineProduct.cooMultiple">{{ (row.poLineProduct.coo==null ||
                        row.poLineProduct.coo=='')?'NA':row.poLineProduct.coo}}</span>
                      <span class="fieldValuePrint" *ngIf="row.poLineProduct.cooMultiple">
                        <span class="fieldValuePrint">{{'LABEL.MULTIPLE' | translate}}</span>
                      </span>
                    </div>
                  </div>
                  <div class="trace-item">
                    <div class="fieldGroup">
                      <span class="fieldLabel">{{ 'DETAIL_PAGE.LABEL.SELLER' | translate }}
                        {{ 'DETAIL_PAGE.LABEL.GTIN' | translate }} : </span>
                      <span id="gtin{{i}}" class="fieldValuePrint">{{(row.gtin==null ||
                        row.gtin=='')?'NA':row.gtin}}</span>
                    </div>
                  </div>
                  <div class="trace-item">
                    <div class="fieldGroup">
                      <span class="fieldLabel">{{ 'DETAIL_PAGE.LABEL.LOTNO' | translate }} : </span>
                      <span id="lotNumber{{i}}" class="fieldValuePrint" *ngIf="!row.poLineProduct.lotNumberMultiple">{{
                        (row.poLineProduct.lotNumber==null ||
                        row.poLineProduct.lotNumber=='')?'NA':row.poLineProduct.lotNumber}}</span>
                      <span class="fieldValuePrint" *ngIf="row.poLineProduct.lotNumberMultiple">
                        <span class="fieldValuePrint">{{'LABEL.MULTIPLE' | translate}}</span>
                      </span>
                    </div>
                  </div>
                  <div class="trace-item">
                    <div class="fieldGroup">
                      <span class="fieldLabel">{{ 'DETAIL_PAGE.LABEL.HARVEST_DATE' | translate }} : </span>
                      <span id="harvestDate{{i}}" class="fieldValuePrint visible-whole-text"
                        *ngIf="!row.poLineProduct.isMultileHarvestDate">{{ (row.poLineProduct.harvestDate==null ||
                        row.poLineProduct.harvestDate=='')?'NA':row.poLineProduct.harvestDate|pipes :'date'}}</span>
                      <span class="fieldValuePrint" *ngIf="row.poLineProduct.isMultileHarvestDate">
                        <span class="fieldValuePrint">{{'LABEL.MULTIPLE' | translate}}</span>
                      </span>
                    </div>
                  </div>
                  <div class="trace-item">
                    <div class="fieldGroup select-option">
                      <span class="fieldLabel">{{ 'DETAIL_PAGE.LABEL.SHIP_FROM' | translate }} : </span>
                      <span class="fieldValuePrint">{{row?.shipFromLocName}}</span>
                    </div>
                  </div>
                  <div class="trace-item">
                    <div class="fieldGroup so-input">
                      <span class="fieldLabel">{{ 'DETAIL_PAGE.LABEL.SALE_ORDER_NO' | translate }} : </span>
                      <span class="fieldValuePrint">{{row?.soNumber}}</span>
                    </div>
                  </div>
                  <div class="trace-item">
                    <div class="fieldGroup">
                      <span class="fieldLabel">{{ 'DETAIL_PAGE.LABEL.SHIP_DATE' | translate }} : </span>
                      <span class="fieldValuePrint">
                        {{row.shipDate| pipes:'date'}}
                      </span>
                    </div>

                  </div>

                  <div class="trace-item">
                    <div class="fieldGroup">
                      <span class="fieldLabel">{{ 'DETAIL_PAGE.LABEL.WEIGHT' | translate }} : </span>
                      <span id="weightUom{{i}}" class="fieldValuePrint">{{ (row.poLineProduct.netWeight==null ||
                        row.poLineProduct.netWeight=='')?'NA':row.poLineProduct.netWeight}}
                        {{(row.poLineProduct.weightUom==null || row.poLineProduct.weightUom=='')?'':
                        row.poLineProduct.weightUom}}</span>
                    </div>
                  </div>

                  <div class="trace-item">
                    <div class="fieldGroup">
                      <span class="fieldLabel">{{ 'DETAIL_PAGE.LABEL.SELLER' | translate }} : </span>
                      <span id="supplier{{i}}"
                        class="fieldValuePrint">{{(printData?.orderDetailForm.sellerCompanyName==null ||
                        printData?.orderDetailForm.sellerCompanyName=='')?'NA':printData?.orderDetailForm.sellerCompanyName}}
                      </span>
                    </div>
                  </div>
                  <div class="trace-item">
                    <div class="fieldGroup">
                      <span class="fieldLabel">{{ 'DETAIL_PAGE.LABEL.USE_BY_DATE' | translate }} : </span>
                      <span id="expiryDate{{i}}" class="fieldValuePrint visible-whole-text">{{
                        (row.poLineProduct.expiryDate==null ||
                        row.poLineProduct.expiryDate=='')?'NA':row.poLineProduct.expiryDate|pipes :'date'}}</span>
                    </div>
                  </div>
                  <div class="trace-item" style="flex-basis: 50%;">
                    <div class="fieldGroup">
                      <span class="fieldLabel">{{ 'DETAIL_PAGE.LABEL.SELLER_PRODUCT' | translate }} : </span>
                      <span id="seller_product{{i}}" class="fieldValuePrint no-truncate">{{(row.tpProductCode==null ||
                        row.tpProductCode=='')?'NA':row.tpProductCode+' | '}}
                        {{(row.tpProductDesc==null || row.tpProductDesc=='')?'':row.tpProductDesc}}</span>
                    </div>
                  </div>

                </div>
              </div>

            </td>
          </tr>
        </tbody>
        <!-- <tfoot> -->
        <tr>
          <td colspan="3">
            <div class="trace-flex">
              <!-- [ngClass]="{'disabled-Tr': row.statusCheck==statusCancelled}" -->
              <div class="trace-label">{{'DETAIL_PAGE.LABEL.TOTAL' | translate}}</div>
            </div>
          </td>
          <td class="textRight">{{totalQuantity}}</td>
          <td class="textRight">
            <span id="totalReceivedQty"> {{totalReceivedQty}}</span>
          </td>
          <td class="textRight" *ngIf="printData?.draftPO">
            <span id="totalFinalPrice">{{
              printData?.totalColumnPrice |
              pipes:'currancy':'twoDecimal'}}
            </span>
          </td>
          <td class="textRight" [attr.colspan]="(printData?.draftPO)?'':(printData?.isRecievedQtyHidden ? '2' : '2')">
            <span id="totalFinalPrice"><span>{{ 'TABLE_DISPLAY_COLUMNS.SYMBOL' | pipes:'currancySymbol' }}</span>{{
              printData?.totalColumnFinalPrice | pipes:'twoDecimal' }}
            </span>
          </td>
          <!--  <td></td> -->
        </tr>
        <tr>
          <td [attr.colspan]="printData?.isRecievedQtyHidden ? '6' : '7'">
            <div class="other-trace-flex">
              <!-- [ngClass]="{'disabled-Tr': row.statusCheck==statusCancelled}" -->
              <div class="other-trace-values">
                <div class="other-trace-item">
                  <div class="fieldGroup">
                    <span class="fieldLabel">{{ 'DETAIL_PAGE.LABEL.PALLETS' | translate }} : </span>
                    <span class="fieldValuePrint"
                      style="white-space: normal;">{{orderDetailForm?.pallets|number:'1.0-4'}}</span>
                  </div>
                </div>

                <div class="other-trace-item">
                  <div class="fieldGroup">
                    <span class="fieldLabel">{{ 'DETAIL_PAGE.LABEL.NETWT' | translate }} : </span>
                    <span class="fieldValuePrint"
                      style="white-space: normal;">{{printData?.orderDetailForm?.netWeightInLbs|
                      number:'1.0-4'}}
                      {{ 'CATALOG.LABEL.LB' | translate }} <span class="fieldValuePrint" *ngIf="printData?.orderDetailForm?.status != 'Draft'">/
                        {{printData?.orderDetailForm?.netWeightInKgs}}
                        {{ 'CATALOG.LABEL.KG' | translate }}</span></span>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td [attr.colspan]="printData?.isRecievedQtyHidden ? '6' : '7'">
            <div class="other-trace-flex">
              <!-- [ngClass]="{'disabled-Tr': row.statusCheck==statusCancelled}" -->
              <div class="other-trace-values">
                <div class="other-trace-item">
                  <div class="fieldGroup">
                    <span class="fieldLabel">{{ 'DETAIL_PAGE.LABEL.VOLUME' | translate }} : </span>
                    <span class="fieldValuePrint"
                      style="white-space: normal;">{{printData?.orderDetailForm?.volume|number:'1.0-4'}}</span>
                    <span class="fieldValuePrintWt no-truncate">&nbsp;{{ 'DETAIL_PAGE.LABEL.CUFT' | translate }}</span>
                  </div>
                </div>
                <div class="other-trace-item">
                  <div class="fieldGroup">
                    <span class="fieldLabel">{{ 'DETAIL_PAGE.LABEL.GROSS_WT' | translate }} : </span>
                    <span class="fieldValuePrint"
                      style="white-space: normal;">{{printData?.orderDetailForm?.grossWeightInLbs}}
                      {{ 'CATALOG.LABEL.LB' | translate }} <span class="fieldValuePrint" *ngIf="printData?.orderDetailForm?.status != 'Draft'">/
                        {{printData?.orderDetailForm?.grossWeightInKgs}}
                        {{ 'CATALOG.LABEL.KG' | translate }}</span></span>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </table>

      <div matColumnDef="grandTotal" class="grandTotal tdHighlight">
        <div fxLayout="row" fxLayoutAlign="space-between " class="border_B">
          <span class="fs-16 regularFont">{{ 'DETAIL_PAGE.LABEL.PRICE_BREAKDOWN' | translate }}</span>
          <div fxLayout="column" fxLayoutAlign="start">
            <div fxLayout="row" fxLayoutAlign="space-between start">
              <span class="fs-16 mBold">{{ 'DETAIL_PAGE.LABEL.PO_CHARGES' | translate }}&nbsp;</span>
              <span id="totalpoCharges" class="fs-16 regularFont textRight">
                <ng-container *ngIf="printData?.poCharges==0">{{ 'TABLE_DISPLAY_COLUMNS.SYMBOL' | pipes:'currancySymbol'
                  }}</ng-container>{{ printData?.poCharges | pipes:'currancy'}}
              </span>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
              <span class="fs-16 mBold mr-20">{{'DETAIL_PAGE.LABEL.LOAD_FREIGHT_CHARGE' | translate}}&nbsp;</span>
              <span id="totLoadFreight" class="fs-16 regularFont textRight">
                <ng-container *ngIf="printData?.totLoadFreight==0">{{ 'TABLE_DISPLAY_COLUMNS.SYMBOL' |
                  pipes:'currancySymbol' }}</ng-container>{{ printData?.totLoadFreight | pipes:'currancy'}}
              </span>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start" *ngIf="printData?.appModuleAccess?.variance1">
              <span class="fs-16 mBold mr-20">{{'TABLE_DISPLAY_COLUMNS.TOTAL' | translate}}
                {{'DETAIL_PAGE.LABEL.VARIANCE' | translate}}&nbsp;</span>
              <span id="varianceTotal" class="fs-16 regularFont textRight"
                [style.color]="printData?.varianceTotal<0 ?'red':'black'">
                {{ 'TABLE_DISPLAY_COLUMNS.SYMBOL' | pipes:'currancySymbol'}}{{(printData?.finalCostTotal > 0) ?
                (printData?.varianceTotal | pipes:'twoDecimal') : 0}}
              </span>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="total">
          <span>{{'DETAIL_PAGE.LABEL.GRAND_TOTAL' | translate}}</span>
          <span id="grandTotal">
            <ng-container *ngIf="grandTotal==0">{{ 'TABLE_DISPLAY_COLUMNS.SYMBOL' | pipes:'currancySymbol' }}
            </ng-container>{{ grandTotal | pipes:'currancy'}}
            <!-- </ng-container>{{ printData?.grandTotal | pipes:'currancy'}} -->
          </span>
        </div>

      </div>
    </div>
  </div>
  <div class="close-button">
    <button mat-stroked-button (click)="close()">Close</button>
  </div>
</div>