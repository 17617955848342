import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ComponentService } from '@procurant/sharedcomponent/services/component.service';
import { DEFAULT_CLIENT_PREFERENCES, SharedUtilService, TranslateService, ValidatorsService } from 'common-lib';
import { OneAuthService, OneNotifyService } from 'one-auth';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AddressModel, ClientPreferencesModel, CompanyModel, OrganizationModel, OrgUserModel } from '../../company.models';
import { CompanyService } from '../../services/company-settings.service';
import { ProfilePreviewComponent } from '../profile-preview/profile-preview.component';

@Component({
  selector: 'app-account-setting',
  templateUrl: './account-setting.component.html',
  styleUrls: ['./account-setting.component.scss']
})
export class AccountSettingComponent implements OnInit {
  orgBussinessTypes: any[];
  languageList: any[];
  currencyList: any[];
  countryList: any[];
  statesList: any[];
  timeZoneList: any[];
  dateFormateList: any[];
  clientPreferenceList: any[] = [];
  companyModel = new CompanyModel();
  organizationModel = new OrganizationModel();
  clientPreferencesModel = new ClientPreferencesModel();
  addressModel = new AddressModel();
  orgUserModel = new OrgUserModel();
  selectedCountry: string;
  accountSettingForm: FormGroup;
  defaultAddress: FormGroup;
  user: FormGroup;
  clientPreferences: FormGroup;
  orgData: any;
  clientPreferenceIds = new Map();
  clientPreferenceValues = new Map();
  modalData: any;
  isFromInternalAdmin: boolean;
  serviceTypeList = [];
  timeZoneFiltere = new FormControl('');
  timeZoneFilteredOptions: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected timeZOneOnDestroy = new Subject<void>();
  searchLabel = this.translate.instant('LABEL.SEARCH_HERE');
  select = 0;
  isUpdate: boolean = true;

  constructor(public auth: OneAuthService, public notify: OneNotifyService, private fb: FormBuilder, private companyService: CompanyService, public dialog: MatDialog, private router: Router, private translate: TranslateService, private sharedUtilService: SharedUtilService, private componentService: ComponentService) {
    this.defaultAddress = fb.group({
      address: ['', Validators.compose([
        Validators.required,
        Validators.pattern(ValidatorsService.addressLinePattern)
      ])],
      address2: ['', Validators.pattern(ValidatorsService.addressLinePattern)],
      city: ['', Validators.pattern(ValidatorsService.namesPattern)],
      zipCode: ['', Validators.pattern(ValidatorsService.zipCodePattern)],
      stateCode: ['',],
      countryCode: ['',],
    });

    this.user = fb.group({
      fname: ['', Validators.compose([
        Validators.required,
        Validators.pattern(ValidatorsService.aplhaNumeric)
      ])],
      lname: ['', Validators.compose([
        Validators.required,
        Validators.pattern(ValidatorsService.aplhaNumeric)
      ])],
      countryCode: ['', Validators.compose([Validators.required])],
      phone: ['', Validators.compose([
        Validators.required,
        Validators.pattern(ValidatorsService.phonePattern)

      ])],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern(ValidatorsService.emailPattern)
      ])]
    });

    this.accountSettingForm = fb.group({
      name: ['', Validators.compose([
        Validators.required,
        Validators.pattern(ValidatorsService.namesPattern)
      ])],
      url: ['', Validators.pattern(ValidatorsService.urlPattern)],
      description: [''],
      bizType: ['', Validators.compose([Validators.required])],
      serviceTypeCode: [''],
      email: ['', Validators.pattern(ValidatorsService.emailPattern)],
      duns: ['', Validators.pattern('^(?![a-zA-Z]*$)[a-zA-Z0-9]+$')],
      statusCode: [''],
      createdTs: [''], //(PM)PMB-7319 [ADMIN] On the Company Details report add columns for 1) Create/Registration Date 2)Activation Date
      activeDate: [''], //(PM)PMB-7319 [ADMIN] On the Company Details report add columns for 1) Create/Registration Date 2)Activation Date
      accountNumber: [''],
      defaultAddress: this.defaultAddress,
      clientPreferences: this.clientPreferences
    });

    this.clientPreferences = fb.group({
      Language: ['', Validators.compose([Validators.required])],
      Currency: ['', Validators.compose([Validators.required])],
      TimeZone: ['', Validators.compose([Validators.required])],
      DateFormat: ['', Validators.compose([Validators.required])]
    });

    this.accountSettingForm.get('defaultAddress').get('countryCode').valueChanges.subscribe(value => {
      this.selectedCountry = value;
      if (this.selectedCountry != "") {
        this.loadStatesListByCountryCode(this.selectedCountry);
      }
    });

  }

  ngOnInit() {
    this.modalData ? this.isFromInternalAdmin = true : this.isFromInternalAdmin = false;
    this.loadOrgBussinessType();
    this.loadCountryList();
    this.loadLanguageList();
    this.loadCurrencyList();
    this.loadTimeZoneList();
    this.loadDateFormateList();
    this.loadCompanyDetails();
  }
  loadOrgBussinessType() {
    this.companyService.getOrgBussinessType().subscribe(
      (res) => {
        this.orgBussinessTypes = res;
      })
  }

  getServiceProvider() {
    if (this.accountSettingForm.get('bizType').value == 'SERVICE_PROVIDER') {
      this.accountSettingForm.get('serviceTypeCode').setValidators(Validators.required);
      this.accountSettingForm.get('serviceTypeCode').updateValueAndValidity();
      this.companyService.getServiceType().subscribe((res) => {
        if (res instanceof Array && res.length > 0) {
          this.serviceTypeList = res;
        } else {
          this.serviceTypeList = null;
        }
      });
    } else {
      this.accountSettingForm.get('serviceTypeCode').setValue(null);
      this.accountSettingForm.get('serviceTypeCode').setValidators(null);
      this.accountSettingForm.get('serviceTypeCode').updateValueAndValidity();
    }
  }


  loadCountryList() {
    this.companyService.getCountryList().subscribe(
      (res) => {
        this.countryList = res;
      })
  }
  loadStatesListByCountryCode(countryCode: String) {
    this.companyService.getStatesListByCountryCode(countryCode).subscribe(
      (res) => {
        this.statesList = res;
      })
  }
  loadLanguageList() {
    this.companyService.getLanguages().subscribe(
      (res) => {
        this.languageList = res;
      })
  }
  loadCurrencyList() {
    this.companyService.getCurrency().subscribe(
      (res) => {
        this.currencyList = res;
      })
  }
  loadTimeZoneList() {
    this.companyService.getTimeZones().subscribe(
      (res) => {
        this.timeZoneList = res;
        this.timeZoneFilteredOptions.next(this.timeZoneList.slice());
        this.timeZoneFiltere.valueChanges
          .pipe(takeUntil(this.timeZOneOnDestroy))
          .subscribe(() => {
            this.filterTimeZoneList();
          });
      })
  }
  protected filterTimeZoneList() {
    if (!this.timeZoneList) {
      return;
    }
    let search = this.timeZoneFiltere.value;
    if (!search) {
      this.timeZoneFilteredOptions.next(this.timeZoneList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.timeZoneFilteredOptions.next(
      this.timeZoneList.filter(timeZone => (timeZone.value.toLowerCase().indexOf(search) > -1) || (timeZone.code.toLowerCase().indexOf(search) > -1))
    );
  }
  loadDateFormateList() {
    this.companyService.getDateFormates().subscribe(
      (res) => {
        this.dateFormateList = res;
        this.dateFormateList = this.dateFormateList.sort((a, b) => a > b ? -1 : 1);
      })
  }
  loadCompanyDetails() {
    let orgIdToFetch = this.isFromInternalAdmin ? this.modalData : this.auth.user.info.orgId;
    // let orgIdToFetch = this.isFromInternalAdmin ? this.modalData : this.authService.getUser().orgId;
    this.companyService.getCompanyDetails(orgIdToFetch).subscribe(
      (res) => {
        this.orgData = res;
        this.selectedCountry = this.orgData.organization.defaultAddress.countryCode;
        if (this.selectedCountry != "") {
          this.loadStatesListByCountryCode(this.selectedCountry);
        }
        Object.keys(this.orgData.organization).forEach(name => {
          if (this.accountSettingForm.controls[name]) {
            this.accountSettingForm.controls[name].patchValue(this.orgData.organization[name], '');
          }
        });
        Object.keys(this.orgData.organization).forEach(accountNumber => {
          if (this.accountSettingForm.controls[accountNumber]) {
            this.accountSettingForm.controls[accountNumber].patchValue(this.orgData.organization[accountNumber], '');
          }
        });
        Object.keys(this.orgData.user).forEach(name => {
          if (this.user.controls[name]) {
            this.user.controls[name].patchValue(this.orgData.user[name], '');
          }
        });
        if (this.orgData.organization.clientPreferences.length > 0) {
          Object.keys(this.orgData.organization.clientPreferences).forEach(index => {
            if (this.clientPreferences.controls[this.orgData.organization.clientPreferences[index].attributeCode]) {
              if (this.orgData.organization.clientPreferences[index].attributeCode != 'TimeZone' && this.orgData.organization.clientPreferences[index].attributeCode != 'DateFormat') {
                this.clientPreferences.controls[this.orgData.organization.clientPreferences[index].attributeCode].patchValue(this.orgData.organization.clientPreferences[index].valueCode, '');
              } else if (this.orgData.organization.clientPreferences[index].attributeCode == 'TimeZone') {
                this.orgData.organization.clientPreferences[index].valueCode = this.orgData.organization.clientPreferences[index].valueCode != null ? this.orgData.organization.clientPreferences[index].valueCode : 'US/Eastern';
                this.clientPreferences.controls[this.orgData.organization.clientPreferences[index].attributeCode].patchValue(this.orgData.organization.clientPreferences[index].valueCode, '');
              } else if (this.orgData.organization.clientPreferences[index].attributeCode == 'DateFormat') {
                this.orgData.organization.clientPreferences[index].valueCode = this.orgData.organization.clientPreferences[index].valueCode != null ? this.orgData.organization.clientPreferences[index].valueCode : 'MM/dd/yyyy';
                this.clientPreferences.controls[this.orgData.organization.clientPreferences[index].attributeCode].patchValue(this.orgData.organization.clientPreferences[index].valueCode, '');
              }
              Object.keys(this.clientPreferences.controls).forEach(pref => {
                if (this.orgData.organization.clientPreferences[index].attributeCode == pref) {
                  this.clientPreferenceIds.set(pref, this.orgData.organization.clientPreferences[index].id)
                }
              });
            }
          });
        } else {
          //PMb-1197 Changes Default date and time zone
          this.clientPreferences.controls['TimeZone'].patchValue(DEFAULT_CLIENT_PREFERENCES.DEFAUL_TIME_ZONE, '');
          this.clientPreferences.controls['DateFormat'].patchValue(DEFAULT_CLIENT_PREFERENCES.DEFAULT_DATE_FROMAT, '');
        }
        if (this.isFromInternalAdmin) {
          this.accountSettingForm.disable();
          this.user.disable();
          this.clientPreferences.disable();
        }
        this.getServiceProvider();
      })
  }

  cancel(): void {
    this.refreshPage();
  }

  refreshPage() {
    this.router.navigateByUrl('company', { skipLocationChange: true }).then(() => {
      this.router.navigate(['company', 'account-setting']);
    })
  }
  onClientPreferenceChange(event, pref) {
    let target = event.source.selected._element.nativeElement;
    this.clientPreferenceValues.set(pref, target.innerText.trim())
  }
  updateOrg() {
    this.orgUserModel = this.user.value;
    this.organizationModel = this.accountSettingForm.value;
    Object.keys(this.clientPreferences.controls).forEach(index => {
      this.clientPreferencesModel = new ClientPreferencesModel();
      this.clientPreferencesModel.active = true;
      this.clientPreferencesModel.application = "Admin service";
      this.clientPreferencesModel.attributeName = index;
      this.clientPreferencesModel.attributeCode = index;
      this.clientPreferencesModel.byDefault = true;
      this.clientPreferencesModel.organizationId = this.auth.user.info.orgId;
      this.clientPreferencesModel.valueCode = this.clientPreferences.controls[index].value;
      this.clientPreferencesModel.valueValue = this.clientPreferenceValues.get(index);
      this.clientPreferencesModel.id = this.clientPreferenceIds.get(index);
      this.clientPreferenceList.push(this.clientPreferencesModel);
    });
    this.companyModel.organization = this.organizationModel;
    this.companyModel.organization.id = this.auth.user.info.orgId;
    this.companyModel.user = this.orgUserModel;
    this.companyModel.user.id = this.orgData.user.id;
    this.companyModel.organization.clientPreferences = this.clientPreferenceList;
    this.clientPreferenceList = [];
    if (this.isUpdate) {
      this.isUpdate =false;
       this.companyService.updateCompany(this.companyModel).subscribe(
      (data) => {
        if (data) {
          //Change Language and set language to Organization Language
          this.auth.updateUserInfo().subscribe(r => {
            const lang = r.locale !== 'zh_CN' ? 'en_US' : 'zh_CN';
            this.translate.use(lang, r.currency);
          })
          // this.layoutService.getUserInfo().subscribe(
          //   data1 => {
          //     const lang = data1.locale !== 'zh_CN' ? 'en_US' : 'zh_CN';
          //     this.translate.use(lang, data1.currency);
          //   });
          // this.openCompanyUpdateMessage('Company Updated Successfully.');
          //this.openCompanyUpdateMessage(this.translate.instant("COMPANY.MESSAGE.COMPANY_UPDATE_SUCCESS"));
          this.notify.show(this.translate.instant("COMPANY.MESSAGE.COMPANY_UPDATE_SUCCESS"));
          this.isUpdate =true;
          this.loadCompanyDetails();
        }
      })
    }
    
   
  }
  openCompanyUpdateMessage(message: string) {
    //this.sharedUtilService.showSnackBarMessage(this.translate.instant(message));
  }

  profilePreview() {
    const dialogRef = this.dialog.open(ProfilePreviewComponent,
      { width: '60%', data: this.auth.user.info.orgId });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  checkDunsNumberExist(value) {
    if (value && value != this.orgData.organization.duns) {
      this.companyService.isDunsNumberExist(value).subscribe(
        (res) => {
          if (res)
            this.accountSettingForm['controls'].duns.setErrors({ 'isDunsExist': true });
        });
    }
  }

  checkCompanyNameIsExist(value) {
    if (value && value != this.orgData.organization.name) {
      this.companyService.checkOrgnizationNameExist(value).subscribe(
        (res) => {
          if (res)
            this.accountSettingForm['controls'].name.setErrors({ 'isNameExist': true });
        });
    }
  }

  checkBusinessEmailIsExist(value) {
    if (value && value != this.orgData.organization.email) {
      this.companyService.isBusinessEmailExist(value).subscribe(
        (res) => {
          if (res)
            this.accountSettingForm['controls'].email.setErrors({ 'isBizEmailExist': true });
        });
    }
  }
}
