<content-infobar [pageTitle]="pageTitle"></content-infobar>
<!-- <kendo-tabstrip (tabSelect)="onTabSelect($event)"> -->
<!-- <kendo-tabstrip-tab title="{{'BREADCRUMS.ORDER  '|translate}}" [selected]="true"> -->
<!-- <ng-template kendoTabContent> -->
<!-- <order-list></order-list> -->
<!-- </ng-template> -->
<!-- </kendo-tabstrip-tab> -->
<!-- </kendo-tabstrip> -->
<mat-card>
  <form [formGroup]="orderRollbackForm">
    <div class="filter-container">
      <div class="org-dropdown"> <mat-form-field>
          <mat-select placeholder="{{'LABEL.ORG'|translate}}" formControlName='orgType' (selectionChange)="clearSearch()"
            disableOptionCentering>
            <mat-option value="">{{'LABEL.SELECT_ORG' | translate }}</mat-option>
            <mat-option *ngFor="let org of orgList" [value]="org"> {{org}} </mat-option>
          </mat-select>
          <mat-error *ngIf="orderRollbackForm.controls['orgType'].hasError('required')">
            {{'LABEL.ORG_TYPE_IS_REQ' | translate }}
          </mat-error>
        </mat-form-field></div>
      <div class="customer-dropdown">
        <mat-form-field>
          <input matInput placeholder="{{'LABEL.CUSTOMER'|translate}}" formControlName='regOrgNameForSearch'
            [matAutocomplete]="auto" (input)="searchEnter()">
          <mat-error
            *ngIf="orderRollbackForm.controls['regOrgNameForSearch'].touched && orderRollbackForm.controls['regOrgNameForSearch'].hasError('required')">
            {{ 'ADMIN_MESSAGE.MESSAGE.CUSTOMER_SELECTION_REQUIRED_ERROR' | translate }}
          </mat-error>
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayNameAfterSelect">
            <ng-container *ngIf="regOrgSuggestList.length > 0 ">
              <mat-option *ngFor="let order of regOrgSuggestList" [value]="order">
                {{order.name}}
              </mat-option>
            </ng-container>
          </mat-autocomplete>
          <mat-icon matSuffix> <span class="icon-search" style="cursor: pointer;"></span>
          </mat-icon>
        </mat-form-field>
      </div>
      <div class="search-order">
        <mat-form-field>
          <input matInput placeholder="{{'ORDER.LABEL.ORDER_NO' | translate}}" formControlName='orders' autocomplete="off">
          <mat-error
            *ngIf="orderRollbackForm.controls['orders'].touched && orderRollbackForm.controls['orders'].hasError('required')">
            {{ 'LABEL.ORG_IS_REQ' | translate }}
          </mat-error>
          <mat-icon matSuffix (click)="getOrdersList()"> <span class="icon-search" style="cursor: pointer;"></span>
          </mat-icon>
        </mat-form-field>
      </div>
    </div>
  </form>
  <order-list [orderList]="orderList"></order-list>
  <p *ngIf="orderList.length < 1" class="empty-message">{{'MESSAGE.NO_RECORDS_AVAILABLE'|translate}}</p>
</mat-card>