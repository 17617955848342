import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

//import { AppService } from '@procurant/app/app.service';
import { OneAuthService } from 'one-auth';
import { Observable } from 'rxjs';
// import { AuthService } from 'common-lib';
@Injectable()
export class AuthGuard implements CanActivate {

	constructor(private router: Router, private auth: OneAuthService) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		return new Promise((resolve, reject) => {			
			if (this.auth.user.permissions?.BUYER && this.auth.user.permissions?.BUYER?.MODULES?.ADMIN?.actions?.view) {
				//this.router.navigate(['dashboard']);
				resolve(true);
				//return true;
			}else if(this.auth.user.permissions?.INTL_ADMIN){
				this.router.navigate(['internal-admin']);
				resolve(true);	
			}
			 else {
				this.router.navigate(['company']);
				resolve(true);
				//return true; 
			}
		})
	}

	redirectToLoginPage() {
		this.router.navigate(['/login']);
	}

}
