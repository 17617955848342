<mat-toolbar>
  <span class="logo"><img src="assets/images/procurant_logo.png" width="45"></span>
  <span class="logoText">{{ 'LOGIN.PROCURANT' | translate }}</span>
  <span class="example-spacer"></span>
</mat-toolbar>
<mat-divider></mat-divider>
<form [formGroup]="user">
  <div fxLayout="row" fxLayoutAlign="center center" class="mt-5">
    <mat-card fxFlex="30%" *ngIf="!showEmailConformPage">
      <mat-card-content>
        <div fxLayout="row" fxLayoutAlign="center">
          <h1> {{ 'COMPANY.LABEL.FORGOT_PASSWORD' | translate }}</h1>
        </div>
        <div fxLayout="row" fxLayoutAlign="center" class="mt-10">
          {{ 'COMPANY.LABEL.ENTER_REGISTER_EMAIL' | translate }}
        </div>
        <div fxLayout="row" fxLayoutAlign="center" class="mt-15">
          <mat-form-field appearance="outline" style="text-align: center">
            <input matInput formControlName="userName" placeholder="{{ 'LABEL.EMAIL_USERNAME' | translate }}">
            <mat-error *ngIf="user.get('userName').touched && user.get('userName').hasError('required')">
              {{ 'COMPANY.LABEL.EMAIL_USERNAME_REQUIRED' | translate }}
            </mat-error>
            <mat-error *ngIf="user.get('userName').touched && user.get('userName').hasError('pattern')">
              {{ 'COMPANY.LABEL.EMAIL_USERNAME' | translate }}
            </mat-error>
            <mat-error *ngIf="user.get('userName').touched && user.get('userName').hasError('invalidUser')">
              {{ 'COMPANY.LABEL.INVALID_USER' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="center" class="mt-10">
          <button mat-button class="btn btnPrimary" [disabled]="user.invalid" (click)="create()">
            {{ 'COMPANY.LABEL.GENERATE_PASSWORD' | translate }}</button>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card fxFlex="50%" *ngIf="showEmailConformPage">
      <mat-card-content>
        <div fxLayout="row" fxLayoutAlign="center">
          <span class="icon-cancel1"></span>
        </div>
        <div fxLayout="row" fxLayoutAlign="center">
          <h1> {{ 'COMPANY.LABEL.RESET_PASSWORD_EMAIL_SENT' | translate }}</h1>
        </div>
        <div fxLayout="row" fxLayoutAlign="center">
          <span class="msgText">{{ 'COMPANY.LABEL.EMAIL_MESSAGE' | translate }}</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="center" class="mt-20">
          <button mat-button class="btn btnPrimary" (click)="done()">
            {{ 'COMPANY.LABEL.DONE' | translate }}</button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</form>