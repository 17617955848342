/***********************************************************************
* DESCRIPTION :

*  Ability to rollback PO from cancelled and shipped status to last state by internal admin/support

* NOTES :

* AUTHOR :    Hitesh Ahire        CREATE DATE :    09 FEB 2023

* CHANGES :

* REF NO         VERSION   DATE         WHO     DETAIL

* PMB-6555       2.6.0     09/02/2023    HA      Ability to rollback PO from cancelled and shipped status to last state by internal admin/support

************************************************************************/
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from 'common-lib';
import { OneNotifyService } from 'one-auth';
import { OrderRollbackService } from '../../service/order-rollback.service';

@Component({
  selector: 'order-rollback-container',
  templateUrl: './order-rollback-container.component.html',
  styleUrls: ['./order-rollback-container.component.scss']
})
export class OrderRollbackContainerComponent implements OnInit {
  public pageTitle: string = this.translate.instant("BREADCRUMS.ORDER");
  public regOrgSuggestList = [];
  public orgList = ["Buyer", "Supplier"];
  public orderRollbackForm: FormGroup;
  public orderList = [];
  constructor(private translate: TranslateService, private formBuilder: FormBuilder, private orderRollbackService: OrderRollbackService, private notify: OneNotifyService) {
    this.orderRollbackForm = formBuilder.group({
      orgType: ['', [Validators.required]],
      regOrgNameForSearch: ['', [Validators.required]],
      orders: ['', [Validators.required]]
    });
  }
  ngOnInit(): void {  }
  public getCompanyOnExactSearch() { }

  public searchEnter() {
    this.orderRollbackService.searchSuggestByOrgName(this.orderRollbackForm.get('regOrgNameForSearch').value).subscribe(res => {
      if (res) { this.regOrgSuggestList = res; }
    });

  }

  public displayNameAfterSelect(order: any) {
    if (order) {
      return order.name;
    }
  }
  public clearSearch() {

  }
  public getOrdersList() {
    if (!this.orderRollbackForm.valid) {
      // this.notify.show('All fields are required.', 'error');
      this.notify.show(this.translate.instant("MESSAGE.ALL_FIELDS_ARE_REQ"), 'error');
      return;
    }
    let orders = this.orderRollbackForm.get("orders").value.replace(/(\s*,?\s*)*$/, "").split(",").map(e => e.trim());
    if (orders.length <= 5) {
      this.orderRollbackService.getOrderDetails(this.orderRollbackForm.get('regOrgNameForSearch').value.id, this.orderRollbackForm.get("orgType").value, orders).subscribe(res => {
        if (res) {
          this.orderList = res;
        }
        else {
          this.orderList = [];
          this.notify.show(this.translate.instant("MESSAGE.NO_RECORDS_FOUND"), 'error');
        }
      },
        err => {
          this.notify.show(err.error.message, "error");
        });
    } else {
      this.notify.show(this.translate.instant("MESSAGE.MAX_NO_ORDER_SHOULD_BE_LESS_THAN_OR_EQUAL_TO_5"), 'error');
    }
  }

}