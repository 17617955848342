import { environment } from './environment';

export const URLS = Object();

//Jones: Add all API endpoints here
URLS.UserLogin = environment.BASE_API_URL + "auth/api/user/login/";
URLS.UserLogout = environment.BASE_API_URL + "auth/api/user/logout-internal";
// Business Rules

URLS.GetSubscribedApplicationsAPI = environment.BASE_API_URL + "admin/api/access/fetch-SubscribedApplications";
URLS.GetBusinessRulesAPI = environment.BASE_API_URL + "admin/api/access/fetch-businessRules/";
URLS.UpdatedBusinessRulesAPI = environment.BASE_API_URL + "admin/api/access/update-BusinessRules/";
URLS.GetOnlineSupplierListByDepartmentAPI = environment.BASE_API_URL + "admin/api/trading-partners/get-online-suppliers-by-department";

// Business Unit

URLS.GetBusinessUnitTypeAPI = environment.BASE_API_URL + "admin/api/org/business-unit";
URLS.GetCountryListAPI = environment.BASE_API_URL + "admin/api/address/public/countries";
URLS.GetStateByCountryCodeAPI = environment.BASE_API_URL + "admin/api/address/public/state-list/";
URLS.CreateBusinessUnitAPI = environment.BASE_API_URL + "admin/api/org/create";
URLS.GetBusinessUnityDetailsAPI = environment.BASE_API_URL + "admin/api/org/fetchOrgUnit/";
URLS.UpdateCompanyAPI = environment.BASE_API_URL + "admin/api/org/update-orgunit";
URLS.GetBusinessUnitAPI = environment.BASE_API_URL + "admin/api/org/fetchOrg-unit"
URLS.BusinessEmailExistAPI = environment.BASE_API_URL + "admin/api/org/public/exists-by-email/";
URLS.DUNSNumberExistsAPI = environment.BASE_API_URL + "admin/api/org/duns-exists-with-otherOrg/";
URLS.ClientCodeExistsAPI = environment.BASE_API_URL + "admin/api/org/exists-by-unitcode/"
URLS.GetProfileImage = environment.BASE_API_URL + "admin/api/object-store/view/"


URLS.getMonitoringXML = environment.BASE_API_URL + "edi/api/profile/fetch-document-XML-info/";
URLS.getMonitoringXMLBody = environment.BASE_API_URL + "edi/api/profile/fetch-document-XML-info";

// Catalog

URLS.getProductListAPI = environment.BASE_API_URL + "catalog/api/catalog/fetchCatalogItemList"
URLS.AddUpdateProductAPI = environment.BASE_API_URL + "catalog/api/catalog/addUpdate";
URLS.GetProductAPI = environment.BASE_API_URL + "catalog/api/catalog/fetchCatalogItemPrimary/";
URLS.GetCatalogCategoryAPI = environment.BASE_API_URL + "catalog/api/catalog/topCategories";
URLS.GetCatalogSubCategoryAPI = environment.BASE_API_URL + "catalog/api/catalog/subCategories/";
URLS.GetCatalogCountryCodesAPI = environment.BASE_API_URL + "catalog/api/catalog/origin";
URLS.GetUOMAPI = environment.BASE_API_URL + "catalog/api/catalog/uom/";
URLS.GetGrowingMethodsAPI = environment.BASE_API_URL + "catalog/api/catalog/growingMethod";
URLS.DownloadFileAPI = environment.BASE_API_URL + "catalog/api/catalog/downloadTemplate";
URLS.DownloadCommodityFileAPI = environment.BASE_API_URL + "catalog/api/catalog/download-commodity-template";
URLS.GetPackTypesAPI = environment.BASE_API_URL + "catalog/api/catalog/packType";
URLS.GetProductDetailsAPI = environment.BASE_API_URL + "catalog/api/catalog/";
URLS.SuggestProductCodeAPI = environment.BASE_API_URL + "catalog/api/catalog/search/suggest/";
URLS.GetMyTradingPartnersAPI = environment.BASE_API_URL + "admin/api/trading-partners/buy-sell/my-trading-partners/false";
URLS.GetMyTradingPartnersNewAPI = environment.BASE_API_URL + "admin/api/trading-partners/my-trading-partners-new/buy-sell/";
URLS.GetAllSubCategoriesAPI = environment.BASE_API_URL + "catalog/api/catalog/allSubCategories/";
URLS.GetPartnerCatalogItemsAPI = environment.BASE_API_URL + "catalog/api/catalog/partnerCatalogItems";
URLS.GetPartnerCatalogItemsChipListAPI = environment.BASE_API_URL + "catalog/api/catalog/partnerProducts";
URLS.GetPartnerCatalogItemsByDescAPI = environment.BASE_API_URL + "catalog/api/catalog/getCatalogItemByDesc";
URLS.MapCatalogItemsAPI = environment.BASE_API_URL + "catalog/api/catalog/mapCatalogItem";
URLS.SearchProductAPI = environment.BASE_API_URL + "catalog/api/catalog/search/suggest/";
URLS.GetMappedCatalogItemsAPI = environment.BASE_API_URL + "catalog/api/catalog/mappedCatalogItems";
URLS.SearchOrderAPI = environment.BASE_API_URL + "order/buy/search/";
URLS.CheckProductExistAPI = environment.BASE_API_URL + "catalog/api/catalog/check-product-code-exist/";
URLS.CheckGTINExistsAPI = environment.BASE_API_URL + "catalog/api/catalog/check-if-gtin-exist";
URLS.CheckUPSCExistsAPI = environment.BASE_API_URL + "catalog/api/catalog/check-upc-exist/";
URLS.DownloadPartnerCatalogAPI = environment.BASE_API_URL + "catalog/api/catalog/download-partners-catalog";
URLS.UploadPartnerCatalogAPI = environment.BASE_API_URL + "catalog/api/catalog/upload-partners-catalog";
URLS.SuggestCodeDescAPI = environment.BASE_API_URL + "catalog/api/catalog/search/suggest-code-desc/";
URLS.GetFileObject = environment.BASE_API_URL + "admin/api/object-store/";
URLS.UploadCatalogAPI = environment.BASE_API_URL + "catalog/api/catalog/uploadCatalog";
URLS.UploadCommodityAPI = environment.BASE_API_URL + "catalog/api/catalog/upload-commodity";
URLS.SearchProductCodeAPI = environment.BASE_API_URL + "catalog/api/catalog/search/suggest-code-desc-active/";
URLS.SearchMappingProductCodeAPI = environment.BASE_API_URL + "catalog/api/catalog/search/suggest-products-on-code-desc/";
URLS.IsPrimaryProductCodeAPI = environment.BASE_API_URL + "catalog/api/catalog/update-mapped-catalog-item";
URLS.GetCommoditiesOnDepartmentsAPI = environment.BASE_API_URL + "catalog/api/catalog/commodity-by-depts?deptCodes=";
URLS.GetCommodityExistOrNotAPI = environment.BASE_API_URL + "catalog/api/catalog/check-commodity-exist/";
//(PM)PMB_6599 add partner catalog report urls
URLS.GetAllSubCategoriesAPI = environment.BASE_API_URL + "catalog/api/catalog/allSubCategories/";
URLS.GetPartnerCatalogReportAPI = environment.BASE_API_URL + "catalog/api/catalog/report/partner-catalog-details";
URLS.DownloadPartnerCatalogReport = environment.BASE_API_URL + "catalog/api/catalog/report-download-partner-catalog-details";
//(RP)PMB-6593 for sync mapping through update open orders
URLS.GetSyncProduct = environment.BASE_API_URL + "catalog/api/catalog/sync-map-catalog-item";
//(PM) PMB-9425 Add new fields to item /catalog screen - Safety Stock, Order Restriction Type
URLS.GetOrderRestrictionTypeAPI = environment.BASE_API_URL + "catalog/api/catalog/orderRestrictionType";

// ChargeList
URLS.GetChargeListAPI = environment.BASE_API_URL + "admin/api/charge/fetchChargeList";
URLS.GetChargeDetailsAPI = environment.BASE_API_URL + "admin/api/charge/fetchCharge/";
URLS.GetChargeCategoriesAPI = environment.BASE_API_URL + "admin/api/charge/categories";
URLS.GetChargeTypesAPI = environment.BASE_API_URL + "admin/api/charge/charge-types";
URLS.GetTradingPartnerAPI = environment.BASE_API_URL + "admin/api/trading-partners/applications/";
URLS.CreateChargeAPI = environment.BASE_API_URL + "admin/api/charge/create";
URLS.UpdateChargeAPI = environment.BASE_API_URL + "admin/api/charge/update";
URLS.SuggestChargeAPI = environment.BASE_API_URL + "admin/api/charge/search/suggest/";
URLS.SearchChargeAPI = environment.BASE_API_URL + "admin/api/charge/search/";
URLS.GetPaymentTermsListAPI = environment.BASE_API_URL + "admin/api/paymentterm/list/pageable";
URLS.GetPaymentTermAPI = environment.BASE_API_URL + "admin/api/paymentterm/fetchPaymentterm/";
URLS.CreatePaymentTermAPI = environment.BASE_API_URL + "admin/api/paymentterm/create";
URLS.UpdatePaymentTermAPI = environment.BASE_API_URL + "admin/api/paymentterm/update";
URLS.AddUpdateReasonAPI = environment.BASE_API_URL + "admin/api/claims/save/reason-codes";
URLS.GetUpdatedReasonAPI = environment.BASE_API_URL + "admin/api/claims/get/reason-codes";
URLS.GetCharges = environment.BASE_API_URL + "admin/api/charge/list";

URLS.GetChargeSettings = environment.BASE_API_URL + "order/po/charge/setting/";

// Company
URLS.GetBusinessTypeAPI = environment.BASE_API_URL + 'admin/api/org/public/business-types';
URLS.GetStateByCountryCodeAPI = environment.BASE_API_URL + "admin/api/address/public/state-list/";
URLS.OrgNameExistsAPI = environment.BASE_API_URL + "admin/api/org/public/exists-by-name/";
URLS.OrgNameExistsAtEditAPI = environment.BASE_API_URL + "admin/api/org/exists-by-name/";
URLS.BizUnitNameExistsAPI = environment.BASE_API_URL + "admin/api/org/bizunit-exists-by-name/";
URLS.AddCompanyAPI = environment.BASE_API_URL + "admin/api/org/public/create";
URLS.GetCompanyDetailsAPI = environment.BASE_API_URL + "admin/api/org/fetchOrg";
URLS.GetLanguagesAPI = environment.BASE_API_URL + "admin/api/org/languages";
URLS.GetCurrencyAPI = environment.BASE_API_URL + "admin/api/org/currencies";
URLS.GetTimeZonesAPI = environment.BASE_API_URL + "admin/api/org/available-timezones";
URLS.GetDateFormatAPI = environment.BASE_API_URL + "admin/api/org/available-dateFormats";
URLS.UpdateCompanyDetailsAPI = environment.BASE_API_URL + "admin/api/org/update";
URLS.GetAllModulesAPI = environment.BASE_API_URL + "admin/api/access/fetch-ApplicationModules";
URLS.UpdateModuleAPI = environment.BASE_API_URL + "admin/api/access/update-ApplicationModules";
URLS.GetDepartmentListAPI = environment.BASE_API_URL + "admin/api/user/attribute-list";
URLS.GetDepartmentAPI = environment.BASE_API_URL + "admin/api/user/attribute-details/";
URLS.UpdateDepartmentAPI = environment.BASE_API_URL + "admin/api/user/update-attribute";
URLS.CheckAssociationByProductAPI = environment.BASE_API_URL + "catalog/api/catalog/check-association-by-product/";
URLS.AddDepartmentAPI = environment.BASE_API_URL + "admin/api/user/create-attribute";
URLS.DepartmentExistsAPI = environment.BASE_API_URL + "admin/api/user/exists-by-attribute-name/";

URLS.GetDocumentListAPI = environment.BASE_API_URL + "admin/api/document/all";
URLS.DeteltDocumentAPI = environment.BASE_API_URL + "admin/api/document/delete";
URLS.GetDocumentTypeAPI = environment.BASE_API_URL + "admin/api/document/document-types";
URLS.GetDocThumbnailList = environment.BASE_API_URL + "admin/api/document/all/certificates-licenses";

URLS.GetProfileOverviewAPI = environment.BASE_API_URL + "admin/api/org/overview/";
URLS.GetFileObjectViewAPI = environment.BASE_API_URL + "admin/api/object-store/view/";
URLS.GetFileObjectDocument = environment.BASE_API_URL + "admin/api/object-store/download/";
URLS.GetCommodityListAPI = environment.BASE_API_URL + "catalog/api/catalog/fetch-commodity-list";
URLS.GetCommodityAPI = environment.BASE_API_URL + "catalog/api/catalog/fetch-commodity/";
URLS.UpdateCommodityAPI = environment.BASE_API_URL + "catalog/api/catalog/update-commodity";
URLS.AddUserCommodityAPI = environment.BASE_API_URL + "catalog/api/catalog/add-user-commodity";
URLS.SearchCommoditybyNameAPI = environment.BASE_API_URL + "catalog/api/catalog/search/suggest-commodity/";
URLS.SearchOrderAPI = environment.BASE_API_URL + "order/buy/search/";
URLS.GetUserDepartmentListAPI = environment.BASE_API_URL + "admin/api/user/attributes/DEPARTMENT";
URLS.GetCommodityByDepartment = environment.BASE_API_URL + "catalog/api/catalog/fetch-commodity-list-on-category-selection";
URLS.GetCommodityOnKey = environment.BASE_API_URL + "catalog/api/catalog/search/commodity/";
URLS.UpdateSelectedCommodity = environment.BASE_API_URL + "catalog/api/catalog/update-selected-commodities";
URLS.UploadDocument = environment.BASE_API_URL + "admin/api/document/upload";
URLS.UserGetDepartmentListAPI = environment.BASE_API_URL + "admin/api/user/attributes/DEPARTMENT";


URLS.GetRangeListByAPPAPI = environment.BASE_API_URL + "admin/api/range/list";
URLS.GetRangeAPI = environment.BASE_API_URL + "admin/api/user/attribute-details/";
URLS.UpdateRangeAPI = environment.BASE_API_URL + "admin/api/range/addUpdate";
URLS.GetRangeTypeListAPI = environment.BASE_API_URL + "admin/api/range/types/";
URLS.GetApplyByAPI = environment.BASE_API_URL + "admin/api/range/apply-by";
URLS.GetBusinessUnitListOnAppCodeAPI = environment.BASE_API_URL + "admin/api/org/business-units/";
URLS.isPrefixExistAPI = environment.BASE_API_URL + "admin/api/range/is-prefix-duplicate/";
URLS.OrderRangeDetailsAPI = environment.BASE_API_URL + "order/ponumber/next-available-number/";
URLS.AccountNumberExistsAPI = environment.BASE_API_URL + "admin/api/org/account-number-exists/";

// Core

URLS.ValidateTokenAPI = environment.BASE_API_URL + "admin/api/user/public/validate-token/";
URLS.UpdateUserPasswordAPI = environment.BASE_API_URL + "admin/api/user/public/save-password";

// IOT

URLS.IOTDataListAPI = environment.BASE_API_URL + "iot/api/";
URLS.IOTDataListChildAPI = environment.BASE_API_URL + "iot/api/";
URLS.IOTGetDetailsAPI = environment.BASE_API_URL + "iot/api/";
URLS.IOTCreateAPI = environment.BASE_API_URL + "iot/api/";
URLS.IOTUpdateAPI = environment.BASE_API_URL + "iot/api/";
URLS.IOTSuggestAPI = environment.BASE_API_URL + "iot/api/";
URLS.IOTGetStatusListAPI = environment.BASE_API_URL + "iot/api/";
URLS.IOTGetCategoryListAPI = environment.BASE_API_URL + "iot/api/";
URLS.IOTGetTypeListAPI = environment.BASE_API_URL + "iot/api/";
URLS.IOTGetParameterLIstAPI = environment.BASE_API_URL + "iot/api/node/parameter/";
URLS.IOTGetConfigurationListAPI = environment.BASE_API_URL + "iot/api/configuration/list";


// Trading Partner
URLS.GetMyTradingPartnersListAPI = environment.BASE_API_URL + "admin/api/trading-partners/my-trading-partners";
URLS.GetAvailableTradingPartnersAPI = environment.BASE_API_URL + "admin/api/trading-partners/all";
URLS.AddSelectedTradingPartnerAPI = environment.BASE_API_URL + "admin/api/trading-partners/add";
URLS.RemoveTradingPartnerByIdAPI = environment.BASE_API_URL + "admin/api/trading-partners/remove";
URLS.GetPartnerListByTypeAPI = environment.BASE_API_URL + "admin/api/trading-partners/my-trading-partners";
URLS.GetOnlineOfflineSupplierListAPI = environment.BASE_API_URL + "admin/api/trading-partners/get-online-offline-suppliers";
URLS.GetTradingPartnerCodeListAPI = environment.BASE_API_URL + "admin/api/trading-partners/get/codes";
URLS.SuggestTradingPartnerByCodeAPI = environment.BASE_API_URL + "admin/api/trading-partners/search-suggest/codes/";
URLS.AddUpdatePartnerCodeAPI = environment.BASE_API_URL + "admin/api/trading-partners/add-update/codes";
URLS.DeleteTradingPartnerCodeAPI = environment.BASE_API_URL + "admin/api/trading-partners/delete/codes ";
URLS.GetAllTradingPartnerCodeListAPI = environment.BASE_API_URL + "admin/api/trading-partners/search/codes/";
URLS.SuggestAvailableTradingPartnerAPI = environment.BASE_API_URL + "admin/api/trading-partners/search/suggest-trading-partners";
URLS.AvailableTradingPartnerAPI = environment.BASE_API_URL + "admin/api/trading-partners/buy-sell/";




// Service Provider
URLS.GetAvailablePartnersAPI = environment.BASE_API_URL + "admin/api/service-providers/all-service-providers";
URLS.AddSelectedPartnerAPI = environment.BASE_API_URL + "admin/api/service-providers/add-service-providers";
URLS.GetMyPartnersListAPI = environment.BASE_API_URL + "admin/api/service-providers/my-service-providers";
URLS.SuggestAvailablePartnerAPI = environment.BASE_API_URL + "admin/api/service-providers/search/suggest-service-providers";
URLS.GetAllPartnerCodeListAPI = environment.BASE_API_URL + "admin/api/service-providers/my-service-providers";
URLS.RemovePartnerByIdAPI = environment.BASE_API_URL + "admin/api/service-providers/remove-service-provider";
URLS.GetServiceTypeAPI = environment.BASE_API_URL + "admin/api/org/public/service-types";

// Lot
URLS.AddLotCodeAPI = environment.BASE_API_URL + "catalog/api/catalog/pti/create-lot-code";
URLS.FetchLotCodeAPI = environment.BASE_API_URL + "catalog/api/catalog/pti/fetch-lot-code/";
URLS.FetchLotCodeListAPI = environment.BASE_API_URL + "catalog/api/catalog/pti/fetch-lot-code-list";
URLS.FetchLotCommodityListAPI = environment.BASE_API_URL + "catalog/api/product-template/populate-dropdown-on-product-select-from/COMMODITIES";
URLS.UpdateLotAPI = environment.BASE_API_URL + "catalog/api/catalog/pti/update-lot-code";
URLS.FetchGrowingLotListAPI = environment.BASE_API_URL + "catalog/api/catalog/pti/growing-region-list";
URLS.DownloadLotFileAPI = environment.BASE_API_URL + "catalog/api/catalog/pti/download-lot-setup-template";
URLS.UploadLotAPI = environment.BASE_API_URL + "catalog/api/catalog/pti/upload-lot";

// Growing Region
URLS.AddGrowingRegionAPI = environment.BASE_API_URL + "catalog/api/catalog/pti/create-growing-region";
URLS.FetchGrowingRegionAPI = environment.BASE_API_URL + "catalog/api/catalog/pti/fetch-growing-region/";
URLS.UpdateGrowingRegionAPI = environment.BASE_API_URL + "catalog/api/catalog/pti/update-growing-region";
URLS.FetchGrowingRegionListAPI = environment.BASE_API_URL + "catalog/api/catalog/pti/fetch-growing-region-list";
URLS.FetchPMARegionListAPI = environment.BASE_API_URL + "catalog/api/catalog/pma-growing-regions";

// Crew
URLS.FetchCrewListAPI = environment.BASE_API_URL + "pti/api/pti/fetch-harvest-crew-list";
URLS.FetchCrewAPI = environment.BASE_API_URL + "pti/api/pti/fetch-harvest-crew/";
URLS.AddCrewAPI = environment.BASE_API_URL + "pti/api/pti/create-harvest-crew";
URLS.UpdateCrewAPI = environment.BASE_API_URL + "pti/api/pti/update-harvest-crew";
URLS.FetchHarvestAPI = environment.BASE_API_URL + "admin/api/service-providers/my-trading-partners-list/HARVEST";
URLS.CommodityDropdownOnDeptcodeSelectionAPI = environment.BASE_API_URL + "catalog/api/catalog/commodity-dropdown-on-deptcode-selection?categoryCodes=PRODUCE";
URLS.SaveFilterAPI = environment.BASE_API_URL + "admin/api/user-setting/save/filter";
URLS.GetFilterListAPI = environment.BASE_API_URL + "admin/api/user-setting/get/filters/";
URLS.DownloadCrewFileAPI = environment.BASE_API_URL + "pti/api/pti/download-crew-setup-template";
URLS.UploadCrewAPI = environment.BASE_API_URL + "pti/api/pti/upload-crew";

//label
URLS.FetchTempProductAPI = environment.BASE_API_URL + "pti/api/pti/template-to-product-list";
URLS.UpdateTempProductAPI = environment.BASE_API_URL + "pti/api/pti/assign-template-to-product";
URLS.ActiveCommodityAPI = environment.BASE_API_URL + "catalog/api/catalog/fetch-active-org-commodities";
URLS.TempListAPI = environment.BASE_API_URL + "pti/api/pti/templates";
URLS.FetchTempListAPI = environment.BASE_API_URL + "pti/api/pti/fetch-label-template-list";
URLS.GetTempDetailAPI = environment.BASE_API_URL + "pti/api/pti/fetch-label-template/";
URLS.UpdateTempDetailAPI = environment.BASE_API_URL + "pti/api/pti/update-lable-template";
URLS.FetchPrintHistoryListAPI = environment.BASE_API_URL + "pti/api/pti/printing-label-page-list";
URLS.FetchCrewPrintListAPI = environment.BASE_API_URL + "pti/api/pti/harvest-crew-list";
URLS.GetLabelPreviewAPI = environment.BASE_API_URL + "admin/api/object-store/view/"

// User

URLS.GetUserList = environment.BASE_API_URL + "admin/api/user/fetchUserList";
URLS.GetUserListByOrg = environment.BASE_API_URL + "admin/api/internal-admin/fetch-users-for-org"
URLS.GetUser = environment.BASE_API_URL + "admin/api/user/fetchUser/";
URLS.GetUserProfile = environment.BASE_API_URL + "admin/api/user/me";
URLS.GetUserTypes = environment.BASE_API_URL + "admin/api/user/user-types";
URLS.CreateUser = environment.BASE_API_URL + "admin/api/user/create";
URLS.UpdateUser = environment.BASE_API_URL + "admin/api/user/update";
URLS.UpdateUserProfile = environment.BASE_API_URL + "admin/api/user/updateUserProfile";
URLS.ChangeUserProfilePasswordAPI = environment.BASE_API_URL + "admin/api/user/change-password";
URLS.ForgotPassword = environment.BASE_API_URL + "admin/api/user/public/forgot-password";
URLS.GetModuleAndActions = environment.BASE_API_URL + "admin/api/access/fetch-ModulesAndActions/";
URLS.GetUserPermissions = environment.BASE_API_URL + "admin/api/user/fetch-userPermissions";
URLS.SaveUserPermissions = environment.BASE_API_URL + "admin/api/user/create-edit-permissions";
URLS.UserCodeExistsAPI = environment.BASE_API_URL + "admin/api/user/is-usercode-exists/";
/** VMI User Calls */
URLS.GETORGUSERS = environment.BASE_API_URL + "admin/api/user/org-user-list";
URLS.ASSIGNVMIUSER = environment.BASE_API_URL + "admin/api/user/assign-vmi";

// Services for edi Component
URLS.GetDocumentType = environment.BASE_API_URL + "admin/api/internal-admin/doc-type";
URLS.GetDocumentTypeByOrgId = environment.BASE_API_URL + "edi/api/profile/fetch-pending-doc-types";
URLS.GetTriggerEvents = environment.BASE_API_URL + "edi/api/profile/trigger-events/";
URLS.GetOrganizationsByAppCode = environment.BASE_API_URL + "edi/api/profile/getOrganizationsBy-appCode/";
URLS.GetCompanyByAppCode = environment.BASE_API_URL + "edi/api/profile/getCompanyBy-appCode/";
URLS.GetBussinessRules = environment.BASE_API_URL + "edi/api/profile/bussiness-rules/";
URLS.SetupTradingPartner = environment.BASE_API_URL + "edi/api/profile/create-integration-partner";
URLS.AddNewDocumentForTradingPartner = environment.BASE_API_URL + "edi/api/profile/add-new-document-for-existing-org";
URLS.FetchPartnerProfileList = environment.BASE_API_URL + "edi/api/profile/fetch-partner-profile-list";
URLS.GetCustomer = environment.BASE_API_URL + "edi/api/profile/getOrgCustomerList";
URLS.GetTradingPartnerList = environment.BASE_API_URL + "edi/api/profile/GetTradingPartnerList";
URLS.GetDocumentTypeById = environment.BASE_API_URL + "edi/api/profile/fetch-document-type-by-partner/";
URLS.GetDocumentStatus = environment.BASE_API_URL + "edi/api/profile/fetch-document-status-dm";
URLS.GetDocumentList = environment.BASE_API_URL + "edi/api/profile/fetch-document-header-info-dm";
URLS.GetDocumentListN = environment.BASE_API_URL + "edi/api/profile/fetch-document-header-info-dm";
URLS.GetDocumentStatusN = environment.BASE_API_URL + "edi/api/profile/fetch-document-status-dm";
URLS.GetDocumentdetailsIFT = environment.BASE_API_URL + "edi/api/profile/fetch-document-info";
URLS.GetDocumentdetails = environment.BASE_API_URL + "edi/api/profile/fetch-document-details-info";
URLS.ReprocessDocument = environment.BASE_API_URL + "edi/api/profile/update";
URLS.ReprocessAllDocument = environment.BASE_API_URL + "edi/api/profile/allReprocess";
URLS.FetchPartnerProfileData = environment.BASE_API_URL + "edi/api/profile/fetch-partner-profile-info/";
URLS.UpdateTradingPartner = environment.BASE_API_URL + "edi/api/profile/update-integration-partner";
URLS.DownloadEDIFileAPI = environment.BASE_API_URL + "edi/api/profile/download-document-csvFormat";

// Internal Admin
URLS.GetRegisteredOrgListAPI = environment.BASE_API_URL + "admin/api/internal-admin/fetch-all-registeredorgs";
URLS.ActiveOrInactiveRegOrgAPI = environment.BASE_API_URL + "admin/api/internal-admin/update-orgstatus";
URLS.GetLastUpdatedUserInfoAPI = environment.BASE_API_URL + "admin/api/internal-admin/recent-update-info/";
URLS.SearchSuggestForOrgsAPI = environment.BASE_API_URL + "admin/api/internal-admin/org/search-suggest/";
URLS.GetOrgListOnSearchAPI = environment.BASE_API_URL + "admin/api/internal-admin/org/search-by-name-or-id";
URLS.GetAllSystemAppsAPI = environment.BASE_API_URL + "admin/api/access/apps-list";
URLS.UpdateAppsAPI = environment.BASE_API_URL + "admin/api/internal-admin/update-appsubscription-for-org";
URLS.GetOrgListAPI = environment.BASE_API_URL + "admin/api/internal-admin/get-org-list/";
URLS.DefaultDepartmentListAPI = environment.BASE_API_URL + "admin/api/org/default-departments";
URLS.FetchOfflineOrgAPI = environment.BASE_API_URL + "admin/api/internal-admin/manage-settings-fetch/";
URLS.UpdateOfflineOrgAPI = environment.BASE_API_URL + "admin/api/internal-admin/manage-settings";
//(PM)PMB-8118 [ADMIN] Allow Internal Admin to search users by Contact Email/Login Name
URLS.SearchSuggestForContactEmailAPI = environment.BASE_API_URL + "admin/api/internal-admin/contactemail/search-suggest/";

URLS.GetActiveDepartmentAPI = environment.BASE_API_URL + "admin/api/org/active-org-department";
URLS.GetActiveDepartmentINT_ADMIN = environment.BASE_API_URL + "admin/api/internal-admin/active-departments-for-org?organizationId=";
URLS.UpdateUserIntAdmin = environment.BASE_API_URL + "admin/api/internal-admin/update-user";
URLS.GetAllOrgList = environment.BASE_API_URL + "admin/api/internal-admin/get-all-orgs";
URLS.AddCompanyAPI = environment.BASE_API_URL + "admin/api/org/public/create";  //(NM)PMB-6553 implemented for allow company creation from internal admin
//(NM)PMB-6342 Added urls for add internal admin user accounts.
URLS.CreateAdminUserAPI = environment.BASE_API_URL + "admin/api/internal-admin/create";
URLS.UpdateAdminUserAPI = environment.BASE_API_URL + "admin/api/internal-admin/update";
URLS.GetAdminUserAPI = environment.BASE_API_URL + "admin/api/internal-admin/get-all-internal-admin";
URLS.GetUserByUserId = environment.BASE_API_URL + "admin/api/internal-admin/fetchUser/";
URLS.GetUserType = environment.BASE_API_URL + "admin/api/internal-admin/get-procurant-admin-user-type";

//Auto Charges
URLS.GetSupplierListAPI = environment.BASE_API_URL + "admin/api/trading-partners/get-online-offline-suppliers/BUYER/SUPPLIER";
URLS.ShipsToBusinessUnitAPI = environment.BASE_API_URL + "admin/api/org/businessUnits/DELIVERY";
URLS.GetSupplierCodesOfSupplier = environment.BASE_API_URL + "admin/api/org/buyer/supplier-codes/";
URLS.GetAutoChargeListAPI = environment.BASE_API_URL + "admin/api/charge/fetch-auto-charge-list";
URLS.GetAutoChargeDetailsAPI = environment.BASE_API_URL + "admin/api/charge/fetch-auto-charge/";
URLS.UpdateAutoChargeAPI = environment.BASE_API_URL + "admin/api/charge/auto-charge-update";
URLS.CreateAutoChargeAPI = environment.BASE_API_URL + "admin/api/charge/auto-charge-create";
URLS.GetAutoProrateByAPI = environment.BASE_API_URL + "admin/api/charge/charge-prorate-by";
URLS.GetAutoApplyByAPI = environment.BASE_API_URL + "admin/api/charge/charge-apply-by";

// Reminders API's
URLS.AddReminderAPI = environment.BASE_API_URL + "order/reminder/create";
URLS.ReminderDetailsAPI = environment.BASE_API_URL + "order/reminder/find/";
URLS.UpdateReminderAPI = environment.BASE_API_URL + "order/reminder/update";
URLS.GetReminderListAPI = environment.BASE_API_URL + "order/reminder/list";
URLS.DeleteReminderAPI = environment.BASE_API_URL + "order/reminder/remove";
URLS.EnableReminderAPI = environment.BASE_API_URL + "order/reminder/enable";
URLS.DeleteDismissReminderAPI = environment.BASE_API_URL + "order/reminder/remove";

// map partners charges

URLS.GetMyTradingPartnersForMapChargesAPI = environment.BASE_API_URL + "admin/api/trading-partners/buy-sell/my-trading-partners/true";
URLS.GetPartnerChargesAPI = environment.BASE_API_URL + "admin/api/charge/partner-charges";
URLS.SearchMappingChargeCodeAPI = environment.BASE_API_URL + "admin/api/charge/search/suggest-charge/";
URLS.MapChargeAPI = environment.BASE_API_URL + "admin/api/charge/map-partner-charges";
URLS.GetChargDetailsAPI = environment.BASE_API_URL + "admin/api/charge/get-charge-details-for-mapping/";

URLS.BRRules = environment.BASE_API_URL + "order/po/businessRules";

URLS.GetLoadChargeApplyByAPI = environment.BASE_API_URL + "logistics/load/load-charge-apply-by";

URLS.AppModuleAccessAPI = environment.BASE_API_URL + "admin/api/access/is-business-rule-granted";

//SSO Privider
URLS.FetchSSOProvidersAPI = environment.BASE_API_URL + "admin/api/internal-admin/fetch-sso-providers";

//My preferences
URLS.GetFetchNotifications = environment.BASE_API_URL + "admin/api/user/preferences/organization/notifications/fetch";
URLS.SaveEmailNotification = environment.BASE_API_URL + "admin/api/user/preferences/organization/notifications/save-preferences";

//Admin Messages
URLS.GetAdminMessageList = environment.BASE_API_URL + "notification/api/message/admin-messages";
URLS.CreateAdminMessageAPI = environment.BASE_API_URL + "notification/api/message/save-admin-message";
URLS.GetAdminMessageTypeAPI = environment.BASE_API_URL + "notification/api/message/fetch-message-types";
URLS.GetCustomersByAppCode = environment.BASE_API_URL + "admin/api/org/getCompanyBy-appCode/";
URLS.GetAdminMessageDetails = environment.BASE_API_URL + "notification/api/message/get-admin-message";
URLS.GetAdminMessageStatus = environment.BASE_API_URL + "notification/api/message/fetch-message-status";

//(HA) PMB-6555 : Ability to rollback PO (Support API)
URLS.GetOrderDetails = environment.BASE_API_URL + "order/support/get-order-details";
URLS.RevertOrder = environment.BASE_API_URL + "order/support/revert-order?poId=";
URLS.SaveComment = environment.BASE_API_URL + "order/comment"
URLS.GetPOOrderHeaderDetails = environment.BASE_API_URL + "order/buy/po-header/";
URLS.GetPOOrderLineDetailsPaging = environment.BASE_API_URL + "order/buy/po-line-page/";
URLS.GetInvoiceFormPoId = environment.BASE_API_URL + "payment/invoice/by-poid/";
URLS.PaymenttermDetailsById = environment.BASE_API_URL + "admin/api/paymentterm/";
URLS.GetLoadData = environment.BASE_API_URL + "logistics/load/find-by-po/";
URLS.GetPOLineSummary = environment.BASE_API_URL + "order/po/po-total-summary/";

//(HA) PMB-6592 : Reports - Internal Admin  Report for Total Volume by Supplier
URLS.GetTotalVolumeReport = environment.BASE_API_URL + "admin/api/reports/total-volume-report";
URLS.DownloadTotalVolumeReport = environment.BASE_API_URL + "admin/api/reports/download/total-volume-report";
URLS.GetActiveOnlineSupplierOrg = environment.BASE_API_URL + "admin/api/org/all-online-suppliers";

//(RP)PMB-6602 add admin report urls
//Admin Reports 
URLS.GetBusinessUnitsReportList = environment.BASE_API_URL + "admin/api/reports/biz-units-report";
URLS.DownloadBusinessUnitsReport = environment.BASE_API_URL + "admin/api/reports/download/biz-units-report";
URLS.GetFilterList = environment.BASE_API_URL + "admin/api/user-setting/get/filters/";
URLS.SaveFilter = environment.BASE_API_URL + "admin/api/user-setting/save/filter";

//(NM)PMB-7120 Added URL for user details report.
URLS.GetUserDetailsReport = environment.BASE_API_URL + "admin/api/reports/user-details-report";
URLS.DownloadUserDetailsReport = environment.BASE_API_URL + "admin/api/reports/download/user-details-report";

//(RP)PMB-6590 add internal admin report urls
//Admin Reports 
URLS.GetCompanyDetailsReportList = environment.BASE_API_URL + "admin/api/reports/company-details-report";
URLS.DownloadCompanyDetailsReport = environment.BASE_API_URL + "admin/api/reports/download/company-details-report";

//(OL) PMB-6809 added URL for Dashboard fetch data
URLS.Inspection_ResultPieChart = environment.BASE_API_URL + "inspect/api/dashboard/get-inspection-results";
URLS.Inspection_TopAndBottomFive = environment.BASE_API_URL + "inspect/api/dashboard/top-bottom-commodity-by-score";
URLS.Volume_GetVolumeCount = environment.BASE_API_URL + "analytics/api/dashboard/get-volume";
URLS.Volume_GetTopSuppleirVsVolumeBarchart = environment.BASE_API_URL + "analytics/api/dashboard/top-suppliers-by-total-volume";
URLS.Volume_GetTopCommoditiesBarChart = environment.BASE_API_URL + "analytics/api/dashboard/top-commodities-by-case";
URLS.OrderMovement_GetPurchaseOrderCount = environment.BASE_API_URL + "analytics/api/dashboard/get-purchase-order";
URLS.Volume_GetFillRateLineChart = environment.BASE_API_URL + "analytics/api/dashboard/fill-rate";
URLS.getPreferredBusinessUnitsDeliveryAPI = environment.BASE_API_URL + "admin/api/org/preferred-businessUnits/DELIVERY";
//(PM)PMB_6599 add partner catalog report urls
URLS.GetFilterListAPI = environment.BASE_API_URL + "admin/api/user-setting/get/filters/";
URLS.GetMyTradingPartnersNewAPI = environment.BASE_API_URL + "admin/api/trading-partners/my-trading-partners-new/buy-sell/";

// (JJ) PMB-8851 : Provide visibility to trailing 12-month invoice total for supplier accounts.
URLS.GetInvoiceTotalForSupplierId = environment.BASE_API_URL + "payment/invoice/invoice-totals/";