import { Injectable } from '@angular/core';
import { HttpService } from 'common-lib';
import { map } from 'rxjs/operators';

import { HttpResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from '@procurant/env/config.url';
@Injectable({
    providedIn: 'root'
})
export class CoreService {

    private headers = new HttpHeaders();

    constructor(private http: HttpClient, private $http: HttpService) {
        this.headers.set('Content-Type', 'application/json');
     }

    getUserByToken(token): Observable<any> {
        // var userDetails = this.$http.baseUrlContext.url('/admin/api/user/public/validate-token/' + token).get()
        let url = URLS.ValidateTokenAPI + token;
        return this.http.get<any>(url).pipe(
                map((res: Response) => {
                    return res;
                // return this.extractData(res);
            }));
        // return userDetails;
    }

    updateUserPassword(userData): Observable<any> {
        // return this.$http.baseUrlContext.url("/admin/api/user/public/save-password").put(userData)
        return this.http.put(URLS.UpdateUserPasswordAPI, userData, {headers: this.headers}).pipe(
            map((res: Response) => {
                return res;
                // return this.extractData(res);
            }));
    }

    forgotPassword(userData): Observable<any>  {
        // return this.$http.baseUrlContext.url("/admin/api/user/public/forgot-password").put(userData)
        return this.http.put<any>(URLS.ForgotPassword, userData, {headers: this.headers}).pipe(
        map((res: Response) => {
                return res;
                // return this.extractData(res);
            }));
    }

    extractData(response) {
        return response.body;
    }

}

