import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
// import { CoreRoutingModule } from './core-routing.module';

import { NotFoundComponent } from './components/not-found/not-found.component';
import { LayoutComponent } from './components/layout/layout.component';
//import { LoginComponent } from './components/login/login.component';
//import { RegistrationComponent } from './components/registration/registration.component';
import { ActivateUserComponent } from './components/activate-user/activate-user.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { SharedModule } from '@procurant/sharedcomponent/shared-components.module';


@NgModule({
    imports: [
        CommonModule,
        // CoreRoutingModule,
        SharedModule
    ],
    declarations: [
        ActivateUserComponent,
        LayoutComponent,
        NotFoundComponent, ForgotPasswordComponent
    ],
    exports: [
        RouterModule
    ],
    providers: []
})
export class CoreModule { }
