import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'procurant-overlay-design',
  templateUrl: './procurant-overlay-design.component.html',
  styleUrls: ['./procurant-overlay-design.component.scss']
})
export class ProcurantOverlayDesignComponent implements OnInit {

  constructor() { }
  @Input() showFooter: boolean = true;
  ngOnInit(): void {
  }
}
