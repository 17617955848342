<form [formGroup]="parentForm" class="custom-date">
    <mat-form-field appearance="outline" [formGroup]="parentForm">
      <mat-label>{{placeholder}}</mat-label>
      <input matInput [matDatepicker]="picker" id="{{ID}}" [min]="minDate" [max]="maxDate" placeholder="{{placeholder}} "
        formControlName="{{field}}" (dateChange)="dateChangeEvent($event.target.value)" (click)="picker.open()">
  
      <mat-datepicker-toggle matIconSuffix [for]="picker">
        <!-- <div class="icon-container">
          <div class="icon-image">
          </div>
        </div> -->
      </mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </form>