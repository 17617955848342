/**
 * @name DEFAULT Environment Configuration
 * @version 1.0
 * @description Primary config file for Default environment (currently pointing to qazero) when you run ng serve
 * @since 05/20/2019
 * @author Jonah Ramball -  Please be carefull while making any changes to this file.
 */


// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// By default environment.ts file is pointing to qazero environment.
// So if you run ng serve (without any configuration params), it will point to qazero environment

export const environment = {
  production: window["env"]["production"] || true,
  BASE_API_URL: window["env"]["BASE_API_URL"] || "https://services.procurant.io/",
  SUB_DOMAIN: window["env"]["SUB_DOMAIN"] || "https://",
  DOMAIN: window["env"]["DOMAIN"] || ".procurant.com/",
  debug: window["env"]["debug"] || false
  // production: false,
  // BASE_API_URL: 'http://192.168.1.24:8080/',
  // SUB_DOMAIN: 'https://localhost:4200',
  // DOMAIN: '/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
