import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpService } from 'common-lib';
import { map } from 'rxjs/operators';
import { URLS } from "@procurant/env/config.url";
import { Observable, forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppToolbarService {
  public headers: HttpHeaders;
  public flagReminder: boolean = false;
  constructor(private $http: HttpService, private http: HttpClient) {
    this.headers = new HttpHeaders();
    // Add Content-Type be default
    this.headers.set('Content-Type', 'application/json');
  }

  updateReminder(reminder): Observable<any> {
    // return this.$http.baseUrlContext.url("/order/reminder/update").post(reminder)
    let url = URLS.UpdateReminderAPI;
    return this.http.post<any>(url, reminder, { headers: this.headers }).pipe(
      map(res => {
        return res;
        //return this.extractData(res);
      })
    );
  }

  getRemindersList(fromDate, toDate): Observable<any> {
    let reqData = {
      "fromDate": fromDate,
      "toDate": toDate
    }
    // return this.$http.baseUrlContext.url("/order/reminder/list").post(reqData)
    let url = URLS.GetReminderListAPI;
    return this.http.post<any>(url, reqData, { headers: this.headers }).pipe(
      map(res => {
        return res;
        // return this.extractData(res);
      })
    );
  }
  deleteDismissReminder(reqData) {
    // return this.$http.baseUrlContext.url("/order/reminder/remove").delete(reqData)
    let url = URLS.DeleteDismissReminderAPI;
    return this.http.request('DELETE', url, { headers: this.headers, body: reqData, observe: 'response' }).pipe(
      map(res => {
        return res;
      }));
  }
  enableReminder(reqData) {
    let url = URLS.EnableReminderAPI;
    return this.http.post<any>(url, reqData, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      }));
  }

  getProfileImage(objectId?): Observable<any> {
    // return this.$http.baseUrlContext.url("/admin/api/org/buyer/supplier-codes/"+param).get()
    let url = URLS.GetProfileImage + objectId;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res.content;
      })
    );
  }

  getReturnAdmin(): Observable<any> {
    return this.http.get<any>(URLS.UserLogout, { headers: this.headers, observe: 'response' }).pipe(
      map((res) => {
        return res;
      }));
  }

}
