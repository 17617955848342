import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'product-edit',
    templateUrl: './product-edit.component.html',
    styleUrls: ['./product-edit.component.scss']
})
export class ProductEditComponent implements OnInit {

    productId = 0;
    catalogItem: any;
    CATALOG_PRIMARY_ATTR: any = [];
    CATALOG_PACKING_ATTR: any = [];
    CATALOG_ADDITIONAL_ATTR: any = [];
    poStatus = false;
    formData: any;
    orderDetailForm: FormGroup;
    overlayTitle: String = 'CATALOG.LABEL.PRODUCT_DETAILS';

    constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef, public dialogRef: MatDialogRef<ProductEditComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

    ngOnInit() {
        if (this.data != undefined) {
            this.poStatus = this.data[1];
            this.orderDetailForm = this.fb.group({
                productId: [this.data[0]]
            });

            console.log(this.data[2]);
            partnerRoleCode: "BUYER"

        }
        if (this.data[2] && this.data[2].partnerRoleCode == 'SUPPLIER') {
            this.CATALOG_PRIMARY_ATTR =
                [
                    { "Sr": 1, "Attributes": 'CATALOG.COLUMN.DEPARTMENT', "value": { value: 'Produce', component: 'label', field: 'department', disabled: true } },
                    { "Sr": 2, "Attributes": 'CATALOG.COLUMN.COMMODITY', "value": { value: 'Fruits', component: 'label', field: 'commodity', disabled: true } },
                    { "Sr": 3, "Attributes": 'CATALOG.COLUMN.PACK_TYPE', "value": { value: 'Cases', component: 'dropdwon', field: 'packType', disabled: true } },
                    { "Sr": 4, "Attributes": 'CATALOG.COLUMN.PRICE_PER', "value": { value: 'Pack type', component: 'dropdwon', field: 'priceByUom', disabled: true } },
                    { "Sr": 5, "Attributes": 'CATALOG.COLUMN.NET_WEIGHT', "value": { value: '12', component: 'radio', field: 'netWeight', uomfield: 'netWeightUom', disabled: true } },
                    { "Sr": 6, "Attributes": 'CATALOG.COLUMN.GROSS_WEIGHT', "value": { value: '12', component: 'radio', field: 'grossWeight', uomfield: 'weightUom', disabled: true } },
                    { "Sr": 7, "Attributes": 'CATALOG.COLUMN.GROWING_METHOD', "value": { value: 'CONVENTIONAL', component: 'dropdwon', field: 'growingMethod', disabled: true } },
                    { "Sr": 8, "Attributes": 'CATALOG.COLUMN.GROWING_REGION', "value": { value: '', component: 'input', field: 'growingRegion', disabled: true } },
                    { "Sr": 9, "Attributes": 'CATALOG.COLUMN.COUNTRY_OF_ORIGIN', "value": { value: 'USA', component: 'dropdwon', field: 'coo', disabled: true } },
                    { "Sr": 10, "Attributes": 'CATALOG.COLUMN.DEFAULT_PRICE', "value": { value: '', component: 'label', field: 'basePrice', disabled: true } }
                ]
        } else {
            this.CATALOG_PRIMARY_ATTR =
                [
                    { "Sr": 1, "Attributes": 'CATALOG.COLUMN.DEPARTMENT', "value": { value: 'Produce', component: 'label', field: 'department', disabled: true } },
                    { "Sr": 2, "Attributes": 'CATALOG.COLUMN.COMMODITY', "value": { value: 'Fruits', component: 'label', field: 'commodity', disabled: true } },
                    { "Sr": 3, "Attributes": 'CATALOG.COLUMN.PACK_TYPE', "value": { value: 'Cases', component: 'dropdwon', field: 'packType', disabled: true } },
                    { "Sr": 4, "Attributes": 'CATALOG.COLUMN.PRICE_PER', "value": { value: 'Pack type', component: 'dropdwon', field: 'priceByUom', disabled: true } },
                    { "Sr": 5, "Attributes": 'CATALOG.COLUMN.NET_WEIGHT', "value": { value: '12', component: 'radio', field: 'netWeight', uomfield: 'netWeightUom', disabled: true } },
                    { "Sr": 6, "Attributes": 'CATALOG.COLUMN.GROSS_WEIGHT', "value": { value: '12', component: 'radio', field: 'grossWeight', uomfield: 'weightUom', disabled: true } },
                    { "Sr": 7, "Attributes": 'CATALOG.COLUMN.GROWING_METHOD', "value": { value: 'CONVENTIONAL', component: 'dropdwon', field: 'growingMethod', disabled: true } },
                    { "Sr": 8, "Attributes": 'CATALOG.COLUMN.COUNTRY_OF_ORIGIN', "value": { value: 'USA', component: 'dropdwon', field: 'coo', disabled: true } },
                    { "Sr": 9, "Attributes": 'CATALOG.COLUMN.DEFAULT_PRICE', "value": { value: '', component: 'label', field: 'basePrice', disabled: true } }
                ]
        }
        this.CATALOG_PACKING_ATTR =
            [
                // { "Sr": 1, "Attributes": 'CATALOG.COLUMN.UOM', "value": { value: '', component: 'radio', field: 'dimensionUom', type: '' } },
                { "Sr": 1, "Attributes": 'CATALOG.COLUMN.TIE', "value": { value: '', component: 'input', field: 'ti', type: 'digit' } },
                { "Sr": 2, "Attributes": 'CATALOG.COLUMN.HIGH', "value": { value: '', component: 'input', field: 'hi', type: 'digit' } },
                { "Sr": 3, "Attributes": 'CATALOG.COLUMN.PALLET', "value": { value: '', component: 'input', field: 'pallet', type: 'digit' } },
                { "Sr": 4, "Attributes": 'CATALOG.COLUMN.UOM', "value": { value: '', component: 'radio', field: 'dimensionUom', type: '' } },
                { "Sr": 5, "Attributes": 'CATALOG.COLUMN.LENGTH', "value": { value: '', component: 'input', field: 'length', type: 'decimal' } },
                { "Sr": 6, "Attributes": 'CATALOG.COLUMN.WIDTH', "value": { value: '', component: 'input', field: 'width', type: 'decimal' } },
                { "Sr": 7, "Attributes": 'CATALOG.COLUMN.HEIGHT', "value": { value: '', component: 'input', field: 'height', type: 'decimal' } },
                { "Sr": 8, "Attributes": 'CATALOG.COLUMN.VOLUME', "value": { value: '', component: 'volume', field: 'volume', type: 'decimal' } }
            ];
        this.CATALOG_ADDITIONAL_ATTR =
            [
                { "Sr": 1, "Attributes": 'CATALOG.LABEL.BRAND', "value": { value: '', component: 'input', field: 'brand', type: 'alphaNum' } },
                { "Sr": 2, "Attributes": 'CATALOG.LABEL.VARIETY', "value": { value: '', component: 'input', field: 'variety', type: 'alphaNum' } },
                { "Sr": 3, "Attributes": 'CATALOG.LABEL.PACK_SIZE_UOM', "value": { value: '', component: 'input', field: 'packDesc', type: 'alphaNum' } },
                { "Sr": 4, "Attributes": 'CATALOG.LABEL.GRADE', "value": { value: '', component: 'input', field: 'grade', type: 'alphaNum' } },
                { "Sr": 5, "Attributes": 'CATALOG.LABEL.PLU_CODE', "value": { value: '', component: 'input', field: 'pluCode', type: 'digit' } },
                { "Sr": 6, "Attributes": 'CATALOG.LABEL.PARTNER_SKU', "value": { value: '', component: 'input', field: 'tpSKU', type: 'alphaNumWithLen32' } }
            ];

    }

    ngAfterViewInit() {
        this.cdr.detectChanges();
    }

    productDetailSubmitEvent(event) {
        this.dialogRef.close({ action: true, data: event });
    }

    productDetailCloseEvent(event) {
        this.dialogRef.close({ action: event, data: null });
    }
}