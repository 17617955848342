export const IMAGES = Object();

IMAGES.PROCURANT_LOGO = "assets/images/procurant_logo.png";
IMAGES.ETHEREUM_LOGO = "assets/images/ethereumlogo.png";
IMAGES.LOADING_ICON = "assets/images/procurant_logo.gif";



IMAGES.BUYER_APP_ICON = "assets/images/buyer.png";
IMAGES.SUPPLIER_APP_ICON = "assets/images/carrier.png";
IMAGES.CARRIER_APP_ICON = "assets/images/supplier.png";
IMAGES.TRUST_APP_ICON = "assets/images/trust.png";

// IMAGES.PROCURANT_LOGO = "assets/images/procurant-logo.png";

IMAGES.ADMIN_APP_ICON = "assets/images/apps/admin.png";
IMAGES.LINK_APP_ICON = "assets/images/apps/link.png";
IMAGES.CONNECT_APP_ICON = "assets/images/apps/connect.png";
IMAGES.SHARE_APP_ICON = "assets/images/apps/share.png";
IMAGES.TRACE_APP_ICON = "assets/images/apps/trace.png";
IMAGES.INSPECT_APP_ICON = "assets/images/apps/inspect.png";
IMAGES.TASK_APP_ICON = "assets/images/apps/task.png";
IMAGES.EDGE_APP_ICON = "assets/images/apps/edge.png";
IMAGES.DEFAULT_APP_ICON = "assets/images/apps/default.png";
