<div class="container">
    <div class="title">
        <label class="-title">{{ 'ORDER_LIST.LABEL.ALL_ORDERS' | translate }}</label>
    </div>
    <table class="table customTable">
        <tr class="table-header">
            <th *ngFor="let head of headList">{{head}}</th>
        </tr>
        <tr class="table-row" *ngFor="let order of orderList">
            <td (click)="openOrderDetails(order.poId)"> <span class="poNumber">{{order.poNumber}}</span></td>
            <td>{{order.soNumber}}</td>
            <td>{{order.status}}</td>
            <td>{{order.buyer}}</td>
            <td>{{order.supplier}}</td>
            <td>{{order.invoiceNumber}}</td>
            <td>{{order.invoiceStatus}}</td>
            <td>{{order.deliverTo}}</td>
            <td>{{order.shipFrom}}</td>
            <td>{{order.deliveryDate | date: 'MM/dd/yyyy'}}</td>
            <td *ngIf="order.maxShipDate == order.minShipDate; else dateRange">{{order.maxShipDate | date:
                'MM/dd/yyyy'}}</td>
            <td>{{order.routing}}</td>
            <td>
                <div class="action">
                    <div class="comment-icon"> <data-comment [docTypeCode]="docType_PO" [docId]="order.poId"
                            [hasPreviousCommentsAvailable]="order.isCommentsAvailable"></data-comment>
                        <span *ngIf="order.isActivePoWithSamePoNo" class="icon2-info-danger fs-10 " mat-raised-button
                            matTooltip="{{'MESSAGE.ANOTHER_ORDER_WITH_SAME_ORDER_NO' | translate }}">
                        </span>
                    </div>
                    <div class="buttons">
                        <button
                            *ngIf="(order.status == 'Cancelled' ||  order.status == 'Shipped' || order.status == 'Received') && order.invoiceStatus != 'Approved' && !order.isActivePoWithSamePoNo"
                            mat-stroked-button class="revert-order-button"
                            (click)="revertOrder(order)">{{'LABEL.REVERT_ORD' | translate }}</button>
                    </div>
                </div>
            </td>
            <ng-template #dateRange>
                <td>{{order.minShipDate | date: 'MM/dd/yyyy'}} - {{order.maxShipDate | date: 'MM/dd/yyyy'}}</td>
            </ng-template>
        </tr>
    </table>
</div>