<div fxLayout="column" class="main-container">
  <mat-icon class="close-icon" (click)="cancel()">close</mat-icon>
  <mat-card fxFlex="100%">
    <mat-card-content>
      <div fxLayout="row">
        <div fxLayout="column" fxFlex="20%;">
          <div fxLayout="row" style="margin-right: 2%; margin-bottom: 5px; height: 145px;">
            <mat-card fxFlex="100%" fxLayoutAlign="center">
              <ng-container *ngIf="base64Str != ''">
                <img class="logo" [src]="'data:image/jpg;base64,'+base64Str" />
              </ng-container>
            </mat-card>
          </div>
          <div fxLayout="row" style="margin-right: 2%; height: 90px;">
            <mat-card fxFlex="100%">

            </mat-card>
          </div>
        </div>
        <div fxLayout="column" fxFlex="60%;">
          <div fxLayout="row" style="margin-right: 1%; height: 240px;">
            <mat-card fxFlex="100%" class="pageInfo withLightBg">
              <div fxLayout="row" class="companyOverviewLabel">
                {{ 'COMPANY.LABEL.COMPANY_OVERVIEW' | translate }}
              </div>
              <div fxLayout="row" style="height: 80px; overflow-y: scroll;" class="companyOverviewValue">
                {{orgData.organization.description}}
              </div>
              <div fxLayout="row" style="margin-top: 3%;">
                <span class="fieldLabel">{{ 'COMPANY.LABEL.CONTACT_NAME' | translate }} :</span>
                <span class="fieldValue">{{orgData.user.fname}} {{orgData.user.lname}}</span>
              </div>
              <div fxLayout="row">
                <span class="fieldLabel">{{ 'COMPANY.LABEL.CONTACT_EMAIL_ADDRESS' | translate }} :</span>
                <span class="fieldValue">{{orgData.organization.email}}</span>
              </div>
              <div fxLayout="row">
                <span class="fieldLabel">{{ 'COMPANY.LABEL.ADDRESS' | translate }} :</span>
                <span class="fieldValue">
                  {{orgData.organization.defaultAddress.address}}
                  {{orgData.organization.defaultAddress.address2}}
                  {{orgData.organization.defaultAddress.city}}
                  {{orgData.organization.defaultAddress.stateCode}}
                  {{orgData.organization.defaultAddress.zipCode}}
                  {{orgData.organization.defaultAddress.countryCode}}
                </span>
              </div>
              <div fxLayout="row">
                <span class="fieldLabel">{{ 'COMPANY.LABEL.WEB_SITE' | translate }} :</span>
                <span class="fieldValue">{{orgData.organization.url}}</span>
              </div>
            </mat-card>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25%;">
          <div fxLayout="row" style="margin-right: 2%; height: 240px;">
            <mat-card fxFlex="100%">
              <div fxLayout="row" style="margin-bottom: 4%;">
                <span class="licencesLabel">{{ 'COMPANY.LABEL.CERTIFICATIONS_BUSINESS_LICENCES' | translate }}</span>
              </div>
              <div fxLayout="row">
                <div class="doc-list" infiniteScroll
                  [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScrollDocs()"
                  [scrollWindow]="false">
                  <ng-container *ngFor="let item of documents">
                    <!-- <mat-card fxFlex="100%" style="max-height: 125%;">
                      <img class="certificates" (click)="onThumbnailClick(item)"
                        [src]="'data:image/jpg;base64,'+item.base64Content" />
                      <span fxLayoutAlign="center" class="fieldValue">{{item.nameExt}}</span>
                    </mat-card> -->
                    <div class="doc-list-item">
                      <img class="certificates" (click)="onThumbnailClick(item)"
                        [src]="'data:image/jpg;base64,'+item.base64Content" />
                      <span class="doc-name">{{item.nameExt}}</span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <div class="action-block">
    <button mat-raised-button class="btn btnSecondary" (click)="cancel()">{{'BUTTON.CANCEL' | translate }}</button>
  </div>
</div>