import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute, Router } from '@angular/router';
import { EDI_VALUES, SharedUtilService, TranslateService, constant } from 'common-lib';
import { IntegrationPartnerProfileService } from '../../services/integration-partner-profile.service';

import { OneNotifyService } from 'one-auth';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-new-documents-overlay',
  templateUrl: './add-new-documents-overlay.component.html',
  styleUrls: ['./add-new-documents-overlay.component.scss']
})
export class AddNewDocumentsOverlayComponent implements OnInit {
  CONST_VALUES = EDI_VALUES;
  partnerProfileForm: FormGroup;
  searchForm: FormGroup;
  search: any;
  selection = new SelectionModel<any>(true, []);
  selectedCheckboxes: any[] = [];
  selectedRows: any[] = [];
  directions = [
    {
      code: this.CONST_VALUES.INBOUND,
      name: 'Inbound'
    },
    {
      code: this.CONST_VALUES.OUTBOUND,
      name: 'Outbound'
    }
  ]
  businessRules = [];
  dcRulesList = [];
  orgs = [];
  selectedDcRule: Map<string, number[]> = new Map<string, number[]>();
  cloneForm: FormGroup;
  routeParams: any;
  inProgress: boolean;
  newCustList: any = [];
  id: number = 0;
  constructor(private fb: FormBuilder, public notify: OneNotifyService, private _router: Router, private _activatedRoute: ActivatedRoute,
    private profileService: IntegrationPartnerProfileService, private sharedUtilService: SharedUtilService, private translate: TranslateService,
    public dialogRef: MatDialogRef<AddNewDocumentsOverlayComponent>, @Inject(MAT_DIALOG_DATA) public data) { }
  ngOnInit() {

    this.partnerProfileForm = this.fb.group({
      organizationId: [null, Validators.compose([Validators.required])],
      organizationName: [],
      roleCode: [],
      documents: this.fb.array([])
    })
    this.getDocType();


  }

  get documents(): FormArray { return this.partnerProfileForm.get('documents') as FormArray; }

  private setDocuments(val?) {
    return this.fb.group({
      id: [],
      docTypeCode: [val.docTypeCode],
      docTypeName: [{ value: val.docTypeName, disabled: true }],
      bizRules: [],
      businessRulesOptions: [],
      dcRules: [],
      dcRuleOptions: [],
      triggerEvents: [],
      triggerEventOptions: [],
      direction: [],
      action: [{ value: '', disabled: true }],
      selected: [],
    });
  }

  getDocType() {
    this.profileService.getPendingDocumentType(this.data.data.organizationId, this.data.data.roleCode).subscribe(res => {
      if (res instanceof Array) {
        const control = <FormArray>this.partnerProfileForm.controls['documents'];
        res.forEach(data => {
          let obj = { docType: data.docTypeCode, direction: null, rules: [] }
          this.businessRules.push(obj)
          this.dcRulesList.push(obj)
          control.push(this.setDocuments(data));

        });
      }
    })
  }

  getTriggerEvents(row) {
    row.controls['triggerEvents'].setValue([]);
    row.controls['triggerEventOptions'].setValue([]);
    this.profileService.getTriggerEvents(row.controls['docTypeCode'].value, row.controls['direction'].value).subscribe(triggerEventsRes => {
      row.controls['triggerEventOptions'].setValue(triggerEventsRes instanceof Array ? triggerEventsRes : [])
      row.controls['triggerEventOptions'].updateValueAndValidity();

      let defaultBr = [];
      triggerEventsRes.forEach(element => {
        if (element.byDefault) {
          defaultBr.push(element.code);
        }
      });
      row.controls['triggerEvents'].setValue(defaultBr instanceof Array ? defaultBr : []);
      row.controls['triggerEvents'].updateValueAndValidity()
    })
  }

  fetchBusinessRules(row) {
    row.controls['bizRules'].setValue([]);
    this.businessRules.filter(item => item.docType == row.controls['docTypeCode'].value)[0].rules = [];
    row.controls['businessRulesOptions'].setValue([])
    this.profileService.getBusinessRules(row.controls['docTypeCode'].value, row.controls['direction'].value, 'BIZ').subscribe(res => {
      this.businessRules.filter(item => {
        if (item.docType == row.controls['docTypeCode'].value) {
          item.rules = res;
        }
      })
      row.controls['businessRulesOptions'].setValue(res instanceof Array ? res : [])
      row.controls['businessRulesOptions'].updateValueAndValidity()

      let mandateBr = [];
      res.forEach(element => {
        if (element.mandatory) {
          mandateBr.push(element.docTypeBizRuleId);
        }
        if (element.byDefault) {
          mandateBr.push(element.docTypeBizRuleId);
        }
      });
      row.controls['bizRules'].setValue(mandateBr instanceof Array ? mandateBr : []);
      row.controls['bizRules'].updateValueAndValidity()
    })
  }

  fetchDCRules(row) {
    row.controls['dcRules'].setValue([]);
    this.dcRulesList.filter(item => item.docType == row.controls['docTypeCode'].value)[0].rules = [];
    row.controls['dcRuleOptions'].setValue([])
    this.profileService.getBusinessRules(row.controls['docTypeCode'].value, row.controls['direction'].value, 'DC').subscribe(res => {
      this.dcRulesList.filter(item => {
        if (item.docType == row.controls['docTypeCode'].value) {
          item.rules = res;
        }
      })
      row.controls['dcRuleOptions'].setValue(res instanceof Array ? res : [])
      row.controls['dcRuleOptions'].updateValueAndValidity()

      let defaultBr = [];
      let selectedDCRuleId = []
      res.forEach(element => {
        if (element.byDefault) {
          defaultBr.push(element.docTypeBizRuleId);
          selectedDCRuleId.push(element.bizRuleId)
        }
      });
      row.controls['dcRules'].setValue(defaultBr instanceof Array ? defaultBr : []);
      row.controls['dcRules'].updateValueAndValidity()
      this.selectedDcRule.set(row.controls['docTypeCode'].value, selectedDCRuleId);
    })
  }

  submitData() {
    this.partnerProfileForm.get('organizationId').setValue(this.data.data.organizationId);
    this.partnerProfileForm.get('organizationName').setValue(this.data.data.organizationName);
    this.partnerProfileForm.get('roleCode').setValue(this.data.data.roleCode);
    this.cloneForm = this.sharedUtilService.cloneForm(this.partnerProfileForm);
    this.cloneForm.controls['documents']['controls'].forEach(element => {
      element.removeControl('docTypeName'),
        element.removeControl('businessRulesOptions'),
        element.removeControl('dcRuleOptions'),
        element.removeControl('triggerEventOptions')
    });
    let selectedData = this.cloneForm.controls['documents']['controls'].filter(form => form.controls['selected'].value)
    if (selectedData.length > 0) {
      if (selectedData.filter(controls => controls.status !== 'INVALID').length == selectedData.length) {
        this.cloneForm.setControl('documents', new FormArray(selectedData));
        this.inProgress = true;
        this.profileService.addNewDocumentForTradingPartner(this.cloneForm.value).subscribe(res => {
          let label = this.partnerProfileForm.controls['organizationName'].value;
          let msg = constant.replaceErrorMsgValue(this.translate.instant("INTEGRATIONSETUP.MESSAGE.DOCUMENT_CREATED_SUCCESS"), label ? label : 'NA')
          this.notify.show(msg);

          this.closeDialog(true);
        }, error => {
          this.notify.show(this.translate.instant("INTEGRATIONSETUP.MESSAGE.ERROR"), 'error');
          this.inProgress = false;
        }
        )
      }
    } else {
      this.notify.show(this.translate.instant("INTEGRATIONSETUP.MESSAGE.SELECT_DOCUMENTS"));
    }
  }

  changeValidations(element) {
    if (element.controls['selected'].value == true) {
      this.setUnsetValidation(element, true, 'docTypeCode', [Validators.required])
      this.setUnsetValidation(element, true, 'bizRules', [Validators.required])
      if (element.controls['direction'].value == this.CONST_VALUES.OUTBOUND) {
        this.setUnsetValidation(element, true, 'triggerEvents', [Validators.required])
      } else {
        this.setUnsetValidation(element, false, 'triggerEvents', [])
      }
      this.setUnsetValidation(element, true, 'direction', [Validators.required])
    } else {
      this.setUnsetValidation(element, false, 'docTypeCode', [])
      this.setUnsetValidation(element, false, 'bizRules', [])
      this.setUnsetValidation(element, false, 'dcRules', [])
      this.setUnsetValidation(element, false, 'triggerEvents', [])
      this.setUnsetValidation(element, false, 'direction', [])
    }
  }

  setUnsetValidation(element, set, controlName, ValidationArr) {
    if (set) {
      element.controls[controlName].setValidators(ValidationArr);
      element.controls[controlName].updateValueAndValidity();
    } else {
      element.controls[controlName].clearValidators();
      element.controls[controlName].setValidators(ValidationArr);
      element.controls[controlName].updateValueAndValidity();
    }
  }

  changeTriggerEvent(row) {
    this.getTriggerEvents(row);
    this.changeValidations(row);
    this.fetchBusinessRules(row);
    this.fetchDCRules(row);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    let rows = 0
    const numRows = rows;
    return numSelected == numRows;
  }

  isSelected(row: any, checked: boolean) {
    this.changeValidations(row);
    this.selection.toggle(row);
    if (checked) {
      this.selectedCheckboxes.push(row.get('id').value);
      this.selectedRows.push(row);
    } else {
      this.selectedCheckboxes.splice(this.selectedCheckboxes.indexOf(row.get('id').value), 1);
      this.selectedRows.splice(this.selectedRows.indexOf(row), 1);
    }
  }

  closeDialog(isSubmitted = false) {
    this.dialogRef.close(isSubmitted);
  }

  // (VC)EDI-4426 - Validate if any in rule should be disabled based on current selection
  checkUserSelection(item, docTypeRow) {
    let selectedDCRules = this.selectedDcRule.get(docTypeRow.controls.docTypeCode.value)
    for (let dcrule in selectedDCRules) {
      if (selectedDCRules[dcrule] == 46 && item.bizRuleId == 134) {
        return true
      }
      if (selectedDCRules[dcrule] == 134 && item.bizRuleId == 46) {
        return true
      }
    }
    return false
  }

  // (VC)EDI-4426 - Maintain list of dcRuleId in selectedDcRule
  updateOnChange(event, docTypeRow) {
    let selectedDCRuleId = []
    let index: any
    for (index in event.source.selected) {
      selectedDCRuleId.push(event.source.selected[index]._element.nativeElement['data-bizId'])
    }
    this.selectedDcRule.set(docTypeRow.controls.docTypeCode.value, selectedDCRuleId);
  }



}
