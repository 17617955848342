/**
 * @name BLUE Environment Configuration
 * @version 1.0
 * @description Primary config file for BLUE ENVIRONMENT when you run ng serve --configuration=blue
 * @since 05/20/2019
 * @author Jonah Ramball -  Please be carefull while making any changes to this file.  
 */

export const environment = {
    production: false,
    BASE_API_URL: 'https://blue.procurant.io/',
    SUB_DOMAIN: 'https://blue-',
    DOMAIN: '.procurant.io'
};